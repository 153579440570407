import { Store } from 'redux';
import { GetServerSidePropsContext } from 'next';
import {
  publicConfig,
  QueryParams,
  serverAuthorizationService,
  setAcceptLanguage,
  userRedirectMiddleware,
} from '@lerna-core';
import { discountProgramCourseClientProvider } from '@features/common/discountProgram';
import { setSecureHeadersProps } from '@services';
import { CoursePageModel, CourseRequestParams } from './course.model';
import { courseClientProvider } from './courseClient.provider';

export const courseDataServerRequest = async (
  store: Store,
  context: GetServerSidePropsContext
): Promise<CoursePageModel> => {
  const params = context.query as CourseRequestParams;
  const user = store.getState().user.user;
  const secureHeaderProps = !!user ? setSecureHeadersProps() : {};
  const locale = context.locale || publicConfig.DEFAULT_LOCALE;
  const discountProgramQuery = params[QueryParams.discountProgram] as string;
  const promoCode = params[QueryParams.promoCode] as string;
  const requestHeaders = serverAuthorizationService.getAuthHeaders(
    context.req,
    context.res
  );

  let courseData: CoursePageModel;
  if (discountProgramQuery) {
    courseData = await discountProgramCourseClientProvider(
      {
        discount: discountProgramQuery,
        slug: params.slug,
        ...(promoCode && { promo_code: promoCode }),
      },
      {
        ...setAcceptLanguage(locale),
        ...secureHeaderProps,
        ...requestHeaders,
      }
    );
  } else {
    courseData = await courseClientProvider(
      params,
      {
        ...setAcceptLanguage(locale),
        ...secureHeaderProps,
        ...requestHeaders,
      },
      user
    );
  }

  courseData.referer = context.req.headers.referer || null;

  userRedirectMiddleware(context, courseData.user);

  return courseData;
};
