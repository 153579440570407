import { RightSubscriptionBadgeStatuses } from './rightSubscriptionBadge.model';
import { css } from 'styled-components';

export const getSubscriptionBadgeColor = (
  status: RightSubscriptionBadgeStatuses
): ReturnType<typeof css> => {
  switch (status) {
    case RightSubscriptionBadgeStatuses.success:
      return css`
        background: rgba(19, 174, 92, 0.2);
      `;
    case RightSubscriptionBadgeStatuses.warn:
      return css`
        background: rgba(247, 214, 83, 0.6);
      `;
  }
};
