import { pageUrls } from '@constants';
import { urlService } from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../../../coursesPage';
import { DefaultIconComponent } from '../../tabsIcons';
import { ImageStyled, RowItemStyled, TitleStyled } from '../rowItem';
import { RowItemDefaultContainerProps } from './rowItemDefault.model';

export const RowItemDefaultContainer = ({
  filter,
  handleClick,
}: RowItemDefaultContainerProps): ReactElement => {
  const router = useRouter();
  const { translations, discountProgram } = useCoursesPageContext();

  const discountProgramColor = discountProgram?.colors?.sphereTabs;
  const isActive = !router.query[filter.slug] && !router.query.fastlink;

  const handleClickItem = (): void => {
    const defaultPath = router.query?.fastlink
      ? pageUrls.courses
      : router.pathname;
    const newQuery = router.query;

    delete newQuery[filter.slug];
    newQuery.fastlink && delete newQuery.fastlink;

    handleClick && handleClick();

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  return (
    <RowItemStyled
      isActive={isActive}
      onClick={handleClickItem}
      itemColor={discountProgramColor}
    >
      <TitleStyled>{translations?.all_courses}</TitleStyled>
      <ImageStyled>
        <DefaultIconComponent fillColor={discountProgramColor} />
      </ImageStyled>
    </RowItemStyled>
  );
};
