import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  CommonEventTypes,
  getCurrentPopupFromOpen,
  handleClosePopup,
  logger,
  Nullable,
  PopupsEnum,
  useToggle,
} from '@lerna-core';
import { PageNameContext } from '@contexts';
import { useUserTariff } from '@hooks';
import {
  CompensationFormContent,
  CompensationFormContextModel,
  CompensationFormContextProviderProps,
} from './compensationForm.model';
import {
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderResponse,
  getFormOrderInfo,
  isExistError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  withPromoCodeError,
} from '@features/common/forms';
import {
  getCompensationFormInitialValues,
  getCompensationFormValidationSchema,
  pushFormSubmitStatusEvent,
} from './compensationForm.helper';
import { sendUserOrderRequest } from '../../userOrderForm.helper';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';

const CompensationFormContext =
  React.createContext<Nullable<CompensationFormContextModel>>(null);

export const CompensationFormContextProvider = ({
  props,
  children,
}: CompensationFormContextProviderProps): ReactElement => {
  const router = useRouter();
  const dispatch = useDispatch();
  const pageName = useContext(PageNameContext);
  const { isLernaPrime } = useUserTariff();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const formOrderInfo = getFormOrderInfo(props.course);

  const initialPaymentContent = !formOrderInfo.hasConsultation
    ? UserOrderFormPaymentContentTypes.onlinePay
    : UserOrderFormPaymentContentTypes.consultation;

  const [formContent, setFormContent] = useState<CompensationFormContent>(
    CompensationFormContent.courseWithCompensation
  );
  const [isCommentPopupOpened, toggleCommentPopupOpened] = useToggle(false);
  const [paymentContent, setPaymentContent] =
    useState<UserOrderFormPaymentContentTypes>(initialPaymentContent);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const isFreeCourse = props.course.isFree || props.course.isFreeByDiscount;
  const isCourseWithCompensationContent =
    formContent === CompensationFormContent.courseWithCompensation;
  const isOnlinePaySelected =
    paymentContent === UserOrderFormPaymentContentTypes.onlinePay;

  const validationSchema = useMemo(
    () =>
      getCompensationFormValidationSchema(
        props.translations,
        isOnlinePaySelected,
        isCommentPopupOpened,
        props.course?.benefit?.isCommentNecessary
      ),
    [
      isOnlinePaySelected,
      isCommentPopupOpened,
      props.course?.benefit?.isCommentNecessary,
    ]
  );
  const initialValues = useMemo(() => getCompensationFormInitialValues(), []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      actions.setStatus({});

      const promoCodeValue =
        !isOnlinePaySelected && values.withPromoCode
          ? values.promoCode
          : undefined;
      const commentValue = isCommentPopupOpened ? values.comment : undefined;
      const withPayment = !isCommentPopupOpened && isOnlinePaySelected;

      sendUserOrderRequest(
        props.user,
        props.course,
        router,
        !isCourseWithCompensationContent,
        false,
        pageName,
        promoCodeValue,
        commentValue,
        undefined,
        withPayment
      )
        .then((response) => onSuccess(!isOnlinePaySelected, response))
        .catch((error): void => {
          const orderExistError = isExistError(error);
          const promoCodeError = withPromoCodeError(error);

          if (promoCodeError) {
            setInputError(
              'promoCode',
              `${props.translations?.promocode_does_not_match}`
            );
            orderFormPushDataLayer(
              OrderFormEvents.formEnrollPromoCodeError,
              CommonEventTypes.coursesEvents,
              props.eventPosition,
              props.course
            );

            return;
          }
          if (orderExistError) {
            onExistError(!isOnlinePaySelected);

            return;
          }

          onError(!isOnlinePaySelected);
          logger.error(
            `[ERROR]: error sending request. ${error.response?.data}`
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const showFormStatus =
    (isSuccessDisplayed && !isOnlinePaySelected) ||
    isErrorDisplayed ||
    isExistOrder;
  const submitButtonTitle = isCourseWithCompensationContent
    ? props.translations?.forward
    : isOnlinePaySelected
    ? props.translations?.go_to_payment
    : props.translations?.send_request;

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    setFieldValue('withPromoCode', false);
    setFieldValue('promoCode', '');
    isCommentPopupOpened && toggleCommentPopupOpened();
    setFieldValue('comment', '');
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
    setFormContent(CompensationFormContent.courseWithCompensation);
    setPaymentContent(initialPaymentContent);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<
      B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse
    >
  ): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.success,
        props.course,
        props.eventPosition,
        paymentContent,
        isFreeCourse,
        isCourseWithCompensationContent,
        (response as B2COrderResponse)?.id ||
          (response as B2BOrderResponse)?.order?.uuid ||
          (response as B2CFreeOrderResponse)?.order?.dealId,
        props.user?.career?.companyId
          ? OrderFormRequestTypes.b2b
          : OrderFormRequestTypes.b2c
      );
      if (values.withPromoCode) {
        orderFormPushDataLayer(
          OrderFormEvents.formEnrollPromoCode,
          CommonEventTypes.coursesEvents,
          props.eventPosition,
          props.course
        );
      }
    }
    if (
      isCourseWithCompensationContent &&
      values.comment &&
      props.course?.benefit?.isCommentNecessary
    ) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCourseAddRequiredMotivationLetter,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }
    if (
      isCourseWithCompensationContent &&
      values.comment &&
      !props.course?.benefit?.isCommentNecessary
    ) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCourseAddUnrequiredMotivationLetter,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }
    if (
      isCourseWithCompensationContent &&
      !values.comment &&
      !props.course?.benefit?.isCommentNecessary
    ) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCourseSkipUnrequiredMotivationLetter,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }

    setIsSuccessDisplayed(true);
    isCommentPopupOpened && toggleCommentPopupOpened();

    const redirect = (response as B2BOrderResponse)?.payment?.url;
    if (redirect) {
      router.push(redirect);
    }
  };
  const onError = (withAnalytics = true): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.error,
        props.course,
        props.eventPosition,
        paymentContent,
        isFreeCourse,
        isCourseWithCompensationContent
      );
    }
    isCommentPopupOpened && toggleCommentPopupOpened();
    setIsErrorDisplayed(true);
  };
  const onExistError = (withAnalytics = true): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.exist,
        props.course,
        props.eventPosition,
        paymentContent,
        isFreeCourse,
        isCourseWithCompensationContent
      );
    }
    isCommentPopupOpened && toggleCommentPopupOpened();
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };
  const handlePopupClose = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      props.courseIndex
    );

    dispatch(handleClosePopup(PopupsEnum.userOrderFormPopup));
    clearData();
  };

  const handleChangeFormContent = (
    content: keyof typeof CompensationFormContent
  ): void => {
    setFormContent(content as CompensationFormContent);
  };

  const handleChangePaymentContent = (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ): void => {
    setPaymentContent(content as UserOrderFormPaymentContentTypes);
  };

  const handleFormSubmit = (): void => {
    handleSubmit();
  };

  const handleFormButtonClick = (): void => {
    if (isCourseWithCompensationContent) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCourseClickButtonNext,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );

      toggleCommentPopupOpened();

      return;
    }
    handleFormSubmit();
  };

  useEffect(() => {
    if (isCourseWithCompensationContent && isSubmitting && errors?.comment) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCommentError,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }
  }, [isCourseWithCompensationContent, isSubmitting, errors]);

  useEffect(() => {
    setPaymentContent(initialPaymentContent);
  }, [initialPaymentContent]);

  const context = {
    ...props,
    currentPopup,
    handlePopupClose,
    formContent,
    handleChangeFormContent,
    paymentContent,
    handleChangePaymentContent,
    isCourseWithCompensationContent,
    isOnlinePaySelected,
    submitButtonTitle,
    handleFormButtonClick,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showFormStatus,
    isFreeCourse,
    isLernaPrime,
    isCommentPopupOpened,
    toggleCommentPopupOpened,
    formOrderInfo,
  };

  return (
    <CompensationFormContext.Provider value={context}>
      {children}
    </CompensationFormContext.Provider>
  );
};

export const useCompensationFormContext = (): CompensationFormContextModel => {
  const value = useContext(CompensationFormContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as CompensationFormContextModel;
  }

  return value;
};
