import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { VendorAllocatedFilterContainerProps } from './vendorsUnallocatedFilter.model';
import {
  requestTransferCoursesUnallocated,
  setTransferCoursesUnallocatedFilters,
} from '../../coursesTransfer.actions';
import { getCurrencies } from '../../../../currencies';
import { getCoursesTransferSelector } from '../../coursesTransfer.selectors';
import { getVendors } from '../../../../vendors';
import { useCoursesTransferContext } from '../../coursesTransfer.context';
import { TableFilterContainer } from '../../../../table';

export const VendorsUnallocatedFilterContainer = ({
  translations,
}: VendorAllocatedFilterContainerProps): ReactElement => {
  const currencies = useSelector(getCurrencies);
  const {
    added,
    removed,
    unallocated: { filters },
  } = useSelector(getCoursesTransferSelector);
  const vendorList = useSelector(getVendors);

  const { dispatch, id, coursesProvider, courseExclusionCompanyId } =
    useCoursesTransferContext();

  const handleApplyFilter = (vendors: string[]): void => {
    dispatch(setTransferCoursesUnallocatedFilters({ vendors }));
  };

  const handleRequest = (vendors: string[]): void => {
    dispatch(
      requestTransferCoursesUnallocated(
        coursesProvider,
        {
          ...filters,
          removed,
          added,
          id,
          notRelatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          vendors,
        },
        currencies,
        vendorList
      )
    );
  };

  return (
    <TableFilterContainer
      idKey="slug"
      labelKey="title"
      filterOptions={vendorList}
      filters={filters.vendors}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.vendor}
    />
  );
};
