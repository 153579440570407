import styled from 'styled-components';

export const FormPopupSubmitButtonContainerStyled = styled.div`
  margin-top: 24px;

  > button {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const FormPopupColumn = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;
