import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { CommonEventTypes, CourseModel } from '@lerna-core';
import { GuestOrderFormSubmitStatusTypes } from './guestOrderForm.model';

export const pushFormSubmitStatusEvent = (
  type: GuestOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  orderId?: string,
  requestType?: string
): void => {
  const isFreeCourse = course.isFree || course.isFreeByDiscount;
  let event = `${OrderFormEvents.formEnrollStatus.replace('{{type}}', type)}`;

  if (isFreeCourse) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'free_course')}`;
  }

  orderFormPushDataLayer(
    event,
    CommonEventTypes.orderEvents,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
