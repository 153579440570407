import { ReactElement, ReactNode } from 'react';
import { CurrencyModel, TranslationModel } from '../../../models';
import {
  CoursesTableAccessors,
  CourseTableData,
  CourseTableRow,
  TransferTableModel,
} from './transferTable';
import { handleCheckedProps, LocationType } from '../../transfer';
import { CoursesRequestParams } from '../courses.model';
import { VendorModel } from '../../vendors';

export type UseCoursesTransferReturnType = {
  handleCheckedLeftCallback: (props: handleCheckedProps) => void;
  handleCheckedRightCallback: (props: handleCheckedProps) => void;
};

export type UseCoursesTransferSearchReturnType = {
  changeAllocatedSearch: (term: CoursesSearchTerms) => void;
  changeUnallocatedSearch: (term: CoursesSearchTerms) => void;
  handleUnallocatedSearchCancel: () => void;
  handleAllocatedSearchCancel: () => void;
  isUnallocatedSearchOpen: boolean;
  isAllocatedSearchOpen: boolean;
};

export type CoursesFilters = {
  searchTerm?: CoursesSearchTerms;
} & CoursesRequestParams;

export type TransferCourses = TransferTableModel & {
  isLoading: boolean;
  isPending: boolean;
  isPaginating: boolean;
  isSelectedAll: boolean;
  filters: CoursesFilters;
  initialTotalCourses: number;
};

export type CoursesTransferState = {
  [LocationType.allocated]: TransferCourses;
  [LocationType.unallocated]: TransferCourses;
  added: CourseTableData[];
  removed: CourseTableData[];
  totalCourses: number;
};

export type CourseItemsAfterChange = {
  removed: CourseTableData[];
  added: CourseTableData[];
};

export type CoursesTableRows = Omit<CourseTableData, 'title'> & {
  title: string | ReactElement;
};

export enum CoursesSearchTerms {
  id = 'search_external_id',
  title = 'title',
}

export type SearchStateModel = {
  isOpen: boolean;
  searchTerm: CoursesSearchTerms;
};

export type CoursesTransferRequestParams = CoursesRequestParams & {
  id?: string;
  relatedWithSubscriptionId?: string;
  notRelatedWithSubscriptionId?: string;
  relatedWithCourseExclusionsForCompanyId?: string;
  notRelatedWithCourseExclusionsForCompanyId?: string;
  relatedWithBenefitId?: string;
  notRelatedWithBenefitId?: string;
  locationType?: LocationType;
  added?: CourseTableData[];
  removed?: CourseTableData[];
  searchTerm?: CoursesSearchTerms;
  limit?: number;
};

export type CoursesTransferAllRequestParams = CoursesTransferRequestParams & {
  pages: {
    current: number;
    last: number;
  };
};

export type CoursesTransferProvider = (
  params: CoursesTransferRequestParams,
  currencies: CurrencyModel[],
  vendors: VendorModel[]
) => Promise<TransferTableModel>;

export type CoursesTransferAllProvider = (
  params: CoursesTransferAllRequestParams,
  currencies: CurrencyModel[],
  vendors: VendorModel[]
) => Promise<TransferTableModel[]>;

export type CoursesTransferContextProviderProps = {
  coursesProvider: CoursesTransferProvider;
  coursesAllProvider: CoursesTransferAllProvider;
  accessors: CoursesTableAccessors[];
  dispatch: <T>(action: T) => T;
  baseDomain?: string;
  subscriptionId?: string;
  courseExclusionCompanyId?: string;
  id?: string;
  isRadioButton?: boolean;
  children: ReactNode;
};

export type CoursesTransferContextReturnType = {
  coursesProvider: CoursesTransferProvider;
  coursesAllProvider: CoursesTransferAllProvider;
  accessors: CoursesTableAccessors[];
  dispatch: <T>(action: T) => T;
  id: string;
  courseExclusionCompanyId?: string;
  isRadioButton: boolean;
};

export type CoursesTransferProps = {
  translations: TranslationModel;
  getCustomRows?: (
    courses: CourseTableData[],
    translations: TranslationModel,
    baseDomain?: string
  ) => CourseTableRow[];
} & Omit<CoursesTransferContextProviderProps, 'children'>;

export type ConcatCoursesTransferModel = TransferTableModel & {
  concat?: boolean;
};

export type CoursesTransferStyledProps = {
  isPending: boolean;
};

export type CoursesTransferActions = {
  type: string;
  payload?:
    | string[]
    | number
    | boolean
    | TransferTableModel
    | CourseItemsAfterChange
    | CoursesFilters
    | ConcatCoursesTransferModel;
};
