import {
  FilterOptionModel,
  FiltersModel,
  FiltersSlugEnum,
  mindboxService,
} from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';

export const sendMindboxSphereEvent = (
  query: ParsedUrlQuery,
  filters: FiltersModel[]
): void => {
  const querySphere = query[FiltersSlugEnum.sphere] || query.fastlink;
  if (querySphere) {
    const sphereFilter = filters.find(
      (filter) => filter.slug === FiltersSlugEnum.sphere
    );
    const activeSphereOption = (
      sphereFilter?.options as FilterOptionModel[]
    ).find((option) => option.slug === querySphere);

    if (activeSphereOption) {
      mindboxService.sendEvent(
        mindboxService.EventName.gotToSphere,
        mindboxService.getGoToSpherePayload(activeSphereOption.mindboxId)
      );
    }
  }
};
