import { CareerApiModel, PersonalApiModel } from '../user';
import { mapCareer, mapPersonal } from '../../adapters';
import {
  CustomerApiModel,
  CustomerApiResponseModel,
  CustomerModel,
} from './customer.model';

export const mapCustomer = (customer: CustomerApiModel): CustomerModel => {
  const personalApiData: PersonalApiModel = {
    last_name: customer.last_name,
    first_name: customer.first_name,
    patronymic: customer.patronymic,
    avatar: customer.avatar,
    email: customer.email,
    anonymous_email: customer.anonymous_email,
    uuid: customer.uuid,
    phone: customer.phone,
    is_fulfilled: customer.is_fulfilled,
    is_disabled: customer.is_disabled,
    last_activity_at: customer.last_activity_at,
    registered_at: customer.registered_at,
    education_streams: customer.education_streams,
  };

  const careerApiData: CareerApiModel = {
    company_id: customer.company_id,
    position: customer.position,
    department: customer.department,
    company_title: customer.company_title,
    roles: customer.roles,
    lms_courses_count: customer.lms_courses_count,
    is_verified: customer.is_verified,
    is_listing_hidden: customer.is_listing_hidden,
    is_listing_with_benefits_only: customer.is_listing_with_benefits_only,
    is_invite_link_available: customer.is_invite_link_available,
    is_company_without_manager: customer.is_company_without_manager,
    is_filters_hidden_on_listing: customer.is_filters_hidden_on_listing,
    is_search_hidden_on_listing: customer.is_search_hidden_on_listing,
    is_bonus_points_hidden_on_listing:
      customer.is_bonus_points_hidden_on_listing,
    is_without_promo_cards: customer.is_without_promo_cards,
    is_without_promo_banner: customer.is_without_promo_banner,
    is_without_personal_tests: customer.is_without_personal_tests,
    is_educational_streams_hidden_on_registration:
      customer.is_educational_streams_hidden_on_registration,
    is_prices_hidden_on_listing_cards:
      customer.is_prices_hidden_on_listing_cards,
    without_b2b2c_order_on_subscription:
      customer.without_b2b2c_order_on_subscription,
    without_incorrect_phone_popup: customer.without_incorrect_phone_popup,
    without_personal_data_update: customer.without_personal_data_update,
    is_custom_compensation_available: customer.is_custom_compensation_available,
    tariff: customer.tariff,
  };

  return {
    ...mapPersonal(personalApiData),
    ...mapCareer(careerApiData),
  };
};

export const customerAdapter = (
  response: CustomerApiResponseModel
): CustomerModel => mapCustomer(response.data.data);
