import { ParsedUrlQuery } from 'querystring';
import { personalPath, projectSubDomains } from '../../constants';
import { PersonalModel } from '../../features';
import { urlService } from '../../services';

export const needRedirectUser = (personal?: PersonalModel) =>
  !!personal && !personal.isFulfilled;

export const getUserRedirectLink = (
  query: ParsedUrlQuery,
  redirectUrl?: string
): string => {
  const path = redirectUrl
    ? `${personalPath.register}?redirectUrl=${encodeURIComponent(redirectUrl)}`
    : personalPath.register;
  const registerPageUrl = `${urlService.getFullUrlToSubDomain(
    projectSubDomains.personal,
    path
  )}`;

  return urlService.setQueryParamsToLink(registerPageUrl, query);
};
