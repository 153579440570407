import React, { ReactElement } from 'react';
import { AgreementCheckboxLabelStyled } from './agreementCheckboxLabel.styled';
import { AgreementCheckboxLabelProps } from './agreementCheckboxLabel.model';
import { urlService } from '../../../../services';
import Link from 'next/link';

export const AgreementCheckboxLabelComponent = ({
  text,
  linkText,
  linkSrc,
}: AgreementCheckboxLabelProps): ReactElement => (
  <AgreementCheckboxLabelStyled>
    <span>{text}</span>
    {'\u00A0'}
    <Link {...urlService.setTargetBlankOptions()} href={linkSrc}>
      {linkText}
    </Link>
  </AgreementCheckboxLabelStyled>
);
