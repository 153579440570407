import React, { ReactElement, useMemo } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  getBonusPointsLabel,
  IconInfoWithoutBgComponent,
  mapPriceValue,
  Nullable,
  personalPath,
  projectSubDomains,
  urlService,
  useTheme,
} from '@lerna-core';
import {
  CustomerBannerContainerStyled,
  CustomerBannerLabelStyled,
} from '../advantagesCustomerBanners.styled';
import {
  BannerBonusesHeadStyled,
  BannerBonusesPointsStyled,
  BannerBonusesPointsValueStyled,
  BannerBonusesLegendStyled,
  BannerBonusesButtonsStyled,
} from './bannerBonuses.styled';
import { BannerBonusesContainerProps } from './bannerBonuses.model';
import { BannerContainer } from '../banner';

export const BannerBonusesContainer = ({
  bonuses,
  translations,
  currencies,
  handleClickActionButton,
  handleClickMoreButton,
}: BannerBonusesContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();

  const bonusLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }

    return getBonusPointsLabel(bonuses.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ]);
  }, []);
  const bonusHref = useMemo(
    () =>
      urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        personalPath.bonuses
      ),
    []
  );
  const bonusPriceLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }

    return `${getBonusPointsLabel(bonuses.bonusPriceConversion.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ])} = ${mapPriceValue(bonuses.bonusPriceConversion.money, currencies)}`;
  }, []);

  if (!bonuses) {
    return null;
  }

  return (
    <BannerContainer>
      <CustomerBannerContainerStyled>
        <BannerBonusesHeadStyled>
          <BannerBonusesPointsStyled>
            <CustomerBannerLabelStyled>
              {translations.banner_bonuses_points_label}:
            </CustomerBannerLabelStyled>
            <BannerBonusesPointsValueStyled>
              {bonusLabel}
            </BannerBonusesPointsValueStyled>
          </BannerBonusesPointsStyled>
          <BannerBonusesLegendStyled>
            {bonusPriceLabel}
          </BannerBonusesLegendStyled>
        </BannerBonusesHeadStyled>
        <BannerBonusesButtonsStyled>
          <ButtonContainer
            color={ButtonColor.white}
            title={translations.banner_bonuses_button_points}
            onClick={handleClickActionButton}
          />
          <ButtonContainer
            color={ButtonColor.transparent}
            borderColor={theme.COLOR.WHITE_32}
            textColor={theme.COLOR.WHITE}
            href={bonusHref}
            isNativeLink={true}
            onClick={handleClickMoreButton}
          >
            <>
              <IconInfoWithoutBgComponent fillColor={theme.COLOR.WHITE} />
              {translations.banner_bonuses_button_more}
            </>
          </ButtonContainer>
        </BannerBonusesButtonsStyled>
      </CustomerBannerContainerStyled>
    </BannerContainer>
  );
};
