import { Store } from 'redux';
import { NextPageContext } from 'next';
import {
  publicConfig,
  QueryParams,
  serverAuthorizationService,
  setAcceptLanguage,
  setShowcaseStatus,
  ShowcaseRequestProps,
  userRedirectMiddleware,
} from '@lerna-core';
import { setSecureHeadersProps } from '@services';
import { CoursesPageWithErrorModel } from './courses.model';
import { discountProgramClientProvider } from '@features/common/discountProgram';
import { coursesPageClientProvider } from './courses.clientProvider';

export async function coursesPageServerRequest(
  store: Store,
  context: NextPageContext
): Promise<CoursesPageWithErrorModel> {
  const user = store.getState().user.user;
  const params = { ...context.query } as ShowcaseRequestProps;
  const locale = context.locale || publicConfig.DEFAULT_LOCALE;
  const discountProgramQuery = params[QueryParams.discountProgram] as string;
  params.page = `${params.page ? params.page : 1}`;
  params.lang = locale;
  store.dispatch(setShowcaseStatus({ isLoaded: false, isLoading: true }));
  const requestHeaders = serverAuthorizationService.getAuthHeaders(
    context.req,
    context.res
  );
  const secureHeaderProps = serverAuthorizationService.isAuthed()
    ? setSecureHeadersProps()
    : {};

  let coursesData: CoursesPageWithErrorModel;

  if (discountProgramQuery) {
    coursesData = await discountProgramClientProvider(
      params,
      discountProgramQuery,
      {
        ...setAcceptLanguage(locale),
        ...secureHeaderProps,
        ...requestHeaders,
      }
    );
  } else {
    coursesData = await coursesPageClientProvider(
      params,
      {
        ...setAcceptLanguage(locale),
        ...secureHeaderProps,
        ...requestHeaders,
      },
      user
    );
  }

  userRedirectMiddleware(context, coursesData.user);
  store.dispatch(setShowcaseStatus({ isLoaded: true, isLoading: false }));

  const suggest = params.title as string;
  coursesData.suggest = suggest || '';

  if (context.res && coursesData.statusCode) {
    context.res.statusCode = coursesData.statusCode;
  }

  return coursesData;
}
