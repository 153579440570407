import {
  RightSubscriptionBadgeModel,
  RightSubscriptionBadgeStatuses,
} from '../rightSubscriptionBadge';
import {
  dateService,
  personalPath,
  projectSubDomains,
  stringService,
  TranslationModel,
  urlService,
} from '@lerna-core';

export const getRightSubscriptionSubscribeMeta = (
  status: RightSubscriptionBadgeStatuses,
  translations: TranslationModel,
  coursesClaimed: number,
  subscriptionTitle: string,
  nextClaimPeriodStart: string
): RightSubscriptionBadgeModel => {
  switch (status) {
    case RightSubscriptionBadgeStatuses.success:
      return {
        status: RightSubscriptionBadgeStatuses.success,
        label: translations.subscription_course_not_available,
        link: urlService.getFullUrlToSubDomain(
          projectSubDomains.personal,
          personalPath.courses
        ),
        linkLabel: translations.my_education || 'Моё обучение',
      };
    case RightSubscriptionBadgeStatuses.warn:
      return {
        status: RightSubscriptionBadgeStatuses.warn,
        label: `${
          translations.subscription_not_available_1
        } ${coursesClaimed} ${stringService
          .pluralTranslations(coursesClaimed, [
            translations.course_label_1,
            translations.course_label_2,
            translations.course_label_many,
          ])
          .toLowerCase()} ${translations.by_subscription.toLowerCase()} <b>«${stringService.capitalizeFirstLetter(
          subscriptionTitle
        )}»</b> ${
          translations.subscription_not_available_2
        } <b>${dateService.formatDate(nextClaimPeriodStart)}</b>`,
      };
  }
};
