import styled from 'styled-components';
import { fontSettings } from '../../../../styles';

export const AgreementCheckboxLabelStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  font-weight: 400;
  user-select: none;
  line-height: ${({ theme }): string => theme.FONT.LINE_HEIGHT_20}px;

  > a {
    color: inherit;
    text-decoration: underline;
  }
`;
