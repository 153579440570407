import React, { ReactElement } from 'react';
import { FiltersShowAllContainerProps } from './filtersShowAll.model';
import { ArrowStyled, FiltersShowAllStyled } from './filtersShowAll.styled';
import { IconArrowLongComponent, useTheme } from '@lerna-core';

export const FiltersShowAllContainer = ({
  translations,
  handleClick,
}: FiltersShowAllContainerProps): ReactElement => {
  const theme = useTheme();

  return (
    <FiltersShowAllStyled onClick={handleClick}>
      {translations?.show_all_filters}
      <ArrowStyled>
        <IconArrowLongComponent
          width={16}
          height={16}
          strokeColor={theme.COLOR.PRIMARY[500]}
        />
      </ArrowStyled>
    </FiltersShowAllStyled>
  );
};
