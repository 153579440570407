import styled from 'styled-components';

export const CounterLoaderStyled = styled.div`
  display: flex;
  justify-content: center;
  gap: 1px;
`;

export const CounterLoaderDotsItemStyled = styled.span`
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props): string => props.theme.COLOR.PRIMARY[500]};
  animation: dotElastic 1s infinite linear;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${(props): string => props.theme.COLOR.PRIMARY[500]};
  }

  &::before {
    animation: dotElasticBefore 1s infinite linear;

    @keyframes dotElasticBefore {
      0% {
        transform: scale(1);
      }
      15% {
        transform: scale(1.2);
      }
      30% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  :nth-child(2) {
    &::before {
      animation-delay: 0.1s;
    }
  }

  :nth-child(3) {
    &::before {
      animation-delay: 0.2s;
    }
  }
`;
