export const excludeCompaniesFooterSberInfo = [
  'd6043e2a-9208-48bd-b2b0-0837291b8fd9',
];

export const isNotContainsExcludedCompanies = (companyId?: string) => {
  if (companyId) {
    return !excludeCompaniesFooterSberInfo.includes(companyId);
  }

  return true;
};
