import { useRouter } from 'next/router';
import { DiscountProgramLogoContainer } from '@features/common/discountProgram';
import {
  ContactUsFormEvent,
  ContactUsFormEventType,
} from '@features/common/forms/contactUsForm';
import { HeaderContainer, HeaderEventPositions } from '@features/common/header';
import {
  getCurrentPopupFromOpen,
  getSimpleEvent,
  getUser,
  PopupsEnum,
  pushDataLayer,
} from '@lerna-core';
import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCoursesPageContext } from '../coursesPage';
import { PageHeaderContainerProps } from './coursesHeader.model';
import { getCoursesHeaderLinkType } from './coursesHeader.helper';

export const CoursesHeaderContainer = ({
  showSearch,
  showFilters,
  translations,
  company,
  handleSticky,
  alwaysShowSearch,
}: PageHeaderContainerProps): ReactElement => {
  const { customerCourses, discountProgram } = useCoursesPageContext();
  const user = useSelector(getUser);
  const { query } = useRouter();
  const contactUsFormPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.contactUsFormPopup)
  );

  const eventPosition = useMemo(() => {
    if (contactUsFormPopup) {
      return contactUsFormPopup.target || '';
    }

    return HeaderEventPositions.coursesPageBlockHeader;
  }, [contactUsFormPopup]);
  const headerLinkType = getCoursesHeaderLinkType(user, query);
  const customerCoursesCount = customerCourses?.length;
  const discountProgramLogo = discountProgram?.logo && (
    <DiscountProgramLogoContainer logo={discountProgram.logo} />
  );

  const handleDataLayer = (eventName: ContactUsFormEvent): void => {
    pushDataLayer(
      getSimpleEvent(
        eventName,
        ContactUsFormEventType.overallEvents,
        eventPosition
      )
    );
  };

  const handleOpenContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.buttonAskQuestion);
    handleDataLayer(ContactUsFormEvent.formAskQuestionOpen);
  };

  const handleErrorContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionError);
  };

  const handleSuccessContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionSuccess);
  };

  const handleCloseContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionClose);
  };

  return (
    <HeaderContainer
      showSearch={showSearch}
      showFilters={showFilters}
      hasSticky={true}
      translations={translations}
      company={company}
      handleSticky={handleSticky}
      handleOpenContactUs={handleOpenContactUs}
      handleCloseContactUs={handleCloseContactUs}
      handleErrorContactUs={handleErrorContactUs}
      handleSuccessContactUs={handleSuccessContactUs}
      alwaysShowSearch={alwaysShowSearch}
      headerLinkType={headerLinkType}
      customerCoursesCount={customerCoursesCount}
      discountProgramLogo={discountProgramLogo}
      discountProgramColors={discountProgram?.colors}
      withLogin={!discountProgram?.isHiddenAuthorization}
    />
  );
};
