import React, { ReactElement } from 'react';
import { Nullable } from '../../../models';
import { FormAgreementLabelStyled } from './formAgreementLabel.styled';
import { FormAgreementLabelComponentProps } from './formAgreement.label.model';

export const FormAgreementLabelComponent = ({
  text,
  hasError,
  textColor,
}: FormAgreementLabelComponentProps): Nullable<ReactElement> => {
  if (!text) {
    return null;
  }

  return (
    <FormAgreementLabelStyled
      dangerouslySetInnerHTML={{ __html: text }}
      hasError={hasError}
      textColor={textColor}
    />
  );
};
