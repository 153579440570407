import styled from 'styled-components';
import { fontSettings, FormAgreementLabelStyled } from '@lerna-core';
import { FormPopupFooterStyledProps } from './formPopupFooter.model';

export const FormPopupFooterContainerStyled = styled.div<FormPopupFooterStyledProps>`
  display: flex;
  align-items: flex-start;

  ${({ withPrivacyCheckbox }): string =>
    withPrivacyCheckbox ? 'flex-direction: column;' : ''}
  > button {
    width: 100%;

    ${({ withPrivacyCheckbox }): string =>
      withPrivacyCheckbox ? 'margin-bottom: 12px;' : ''}
  }

  ${FormAgreementLabelStyled} {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_12, theme.FONT)};
  }
`;
