import React, {
  ChangeEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DropdownContainer } from '../dropdown';
import {
  FilterOptionsTypes,
  FilterPopupProps,
  FilterSearchType,
} from './filterPopup.model';
import {
  FilterPopupContainerStyled,
  FilterPopupDropdownStyles,
} from './filterPopup.styled';
import { FilterPopupSearchContainer } from './filterPopupSearch';
import { PopupFooterContainer } from './popupFooter';
import {
  getHasNextPage,
  getOptionsToRender,
  getShowSelectAllOption,
} from './filterPopup.helper';
import { PopupBodyContainer } from './popupBody';
import { Nullable } from '../../models';

export const FilterPopupContainer = forwardRef<HTMLElement, FilterPopupProps>(
  (
    {
      options = [],
      open,
      selected = [],
      idKey,
      labelKey,
      selectAllOptionLabel,
      searchable = true,
      actions = true,
      searchPlaceholder,
      resetButtonTitle,
      applyButtonTitle,
      onApply,
      onClose,
      onReset,
      onSelect,
      searchType = FilterSearchType.local,
      isPaginating,
      handlePagination,
      handleApiSearch,
      hideSelectAllOption,
      meta,
      optionsType = FilterOptionsTypes.checkbox,
      position,
    },
    ref
  ): ReactElement => {
    const [selectedState, setSelectedState] = useState<string[]>(selected);
    const [searchString, setSearchString] = useState<string>('');
    const [isIntersecting, setIntersecting] = useState<boolean>(false);

    const handleApply = (): void => {
      if (!onApply) {
        return;
      }

      onApply(selectedState);
      setSearchString('');

      if (typeof handleApiSearch === 'function') {
        handleApiSearch('');
      }
      onClose();
    };

    const handleReset = (): void => {
      setSelectedState([]);
      setSearchString('');

      if (typeof handleApiSearch === 'function') {
        handleApiSearch('');
      }
      onReset && onReset([]);
      onClose();
    };

    const handleOutsideClick = (): void => {
      setSearchString('');

      if (typeof handleApiSearch === 'function') {
        handleApiSearch('');
      }
      onClose();
    };

    const Footer = (): Nullable<ReactElement> => {
      if (actions) {
        return (
          <PopupFooterContainer
            resetButtonTitle={resetButtonTitle}
            handleReset={handleReset}
            applyButtonTitle={applyButtonTitle}
            handleApply={handleApply}
          />
        );
      }

      return null;
    };

    const setSelected = useCallback((state: string[]) => {
      setSelectedState(state);

      if (typeof onSelect === 'function') {
        onSelect(state);
      }
    }, []);

    const handleSearch = useCallback(
      (e: ChangeEvent) => {
        const search = (e.target as HTMLInputElement).value;
        setSearchString(search);

        switch (searchType) {
          case FilterSearchType.api:
            if (
              typeof handleApiSearch === 'function' &&
              (search.trim().length > 2 || search.length === 0)
            ) {
              handleApiSearch(search);
            }
            break;
          case FilterSearchType.local:
            break;
        }
      },
      [handleApiSearch]
    );

    const header = useMemo(() => {
      if (searchable && searchPlaceholder) {
        return (
          <FilterPopupSearchContainer
            value={searchString}
            placeholder={searchPlaceholder}
            onSearch={handleSearch}
          />
        );
      }

      return null;
    }, [searchString]);

    const showSelectAllOption = useMemo(
      () =>
        getShowSelectAllOption(
          optionsType,
          searchType,
          searchString,
          hideSelectAllOption
        ),
      [searchString]
    );

    const optionsToRender = useMemo(
      () => getOptionsToRender(options, searchType, searchString, labelKey),
      [options, searchString]
    );
    const hasNextPage = useMemo(() => getHasNextPage(meta), [meta]);

    useEffect(() => {
      const node = document.querySelector('#footerAnchor') as Element;
      if (!node) return;

      const options = {
        rootMargin: '0px',
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(true);
        }
      }, options);

      if (open) {
        observer.observe(node);
      }
    }, [open]);

    useEffect(() => {
      const hasNextPage = getHasNextPage(meta);
      const paginate = isIntersecting && !isPaginating && hasNextPage;

      if (paginate) {
        setIntersecting(false);
        if (typeof handlePagination === 'function' && meta) {
          handlePagination(meta.currentPage + 1, searchString);
        }
      }
    }, [isIntersecting]);

    return (
      <FilterPopupContainerStyled>
        {open && (
          <DropdownContainer
            ref={ref}
            handleOutsideClick={handleOutsideClick}
            top={8}
            position={position}
            extraStyles={FilterPopupDropdownStyles}
          >
            <>
              {header}
              <PopupBodyContainer
                idKey={idKey}
                labelKey={labelKey}
                selectAllOption={showSelectAllOption}
                selectAllOptionLabel={selectAllOptionLabel}
                options={options}
                optionsType={optionsType}
                optionsToRender={optionsToRender}
                selected={selectedState}
                setSelected={setSelected}
                hasNextPage={hasNextPage}
                hasSearch={!!header}
              />
              <Footer />
            </>
          </DropdownContainer>
        )}
      </FilterPopupContainerStyled>
    );
  }
);

FilterPopupContainer.displayName = 'FilterPopupContainer';
