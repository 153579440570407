import {
  AggregateResponseApiErrorModel,
  bannerAdapter,
  checkError,
  customerCoursesAdapter,
  discountProgramAdapter,
  filtersAdapter,
  mapUser,
  ShowcaseApiModel,
  showcaseDefaultAdapter,
  ShowcaseErrorModel,
} from '@lerna-core';
import { COURSES_PAGE_AGGREGATE_REQUIRED_KEYS } from './courses.constants';
import { CoursesPageModel } from './courses.model';

export const coursesAdapter = (
  response: ShowcaseApiModel
): CoursesPageModel | ShowcaseErrorModel => {
  const { data } = response;
  const translations = data.statics?.data.translations;
  checkError(
    data as unknown as AggregateResponseApiErrorModel,
    COURSES_PAGE_AGGREGATE_REQUIRED_KEYS
  );

  const filters = filtersAdapter(data.filters.data, translations);

  //todo: вынести showcaseDefaultAdapter отдельно по каталогам (убрать из либы)
  return {
    ...showcaseDefaultAdapter(response, COURSES_PAGE_AGGREGATE_REQUIRED_KEYS),
    filters,
    user:
      !!data?.personal && !!data?.bonuses
        ? mapUser(data?.personal, data.bonuses, data?.career)
        : null,
    customerCourses: data?.me_courses
      ? customerCoursesAdapter(data?.me_courses)
      : [],
    discountProgram: data?.discount_programs
      ? discountProgramAdapter(data.discount_programs)
      : null,
    banners: data?.banners ? data.banners.data.map(bannerAdapter) : null,
  };
};
