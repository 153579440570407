import React, { ReactElement } from 'react';
import { SwitcherItemModel } from './switcherItem.model';
import {
  SwitcherItemLabelStyled,
  SwitcherItemStyled,
  SwitcherItemValueStyled,
} from './switcherItem.styled';
import { handleMenuSwitcherDataLayer } from '../menuSwitcher.analytics';

export const SwitcherItemContainer = ({
  isActive,
  href,
  title,
  count,
  isAdaptive,
  analyticsEvent,
  analyticsEventPosition,
}: SwitcherItemModel): ReactElement => {
  const hasCount = typeof count === 'number';

  //todo: add events for hr and personal
  const handleClick = (): void => {
    handleMenuSwitcherDataLayer(analyticsEvent, analyticsEventPosition);
  };

  return (
    <SwitcherItemStyled
      isActive={isActive}
      isAdaptive={isAdaptive}
      href={href}
      onClick={handleClick}
    >
      <SwitcherItemLabelStyled>{title}</SwitcherItemLabelStyled>
      {hasCount && <SwitcherItemValueStyled>{count}</SwitcherItemValueStyled>}
    </SwitcherItemStyled>
  );
};
