import { ReactElement } from 'react';
import * as S from './rightSubscriptionBadge.styled';
import { RightSubscriptionBadgeModel } from './rightSubscriptionBadge.model';

export const RightSubscriptionBadgeContainer = ({
  status,
  label,
  link,
  linkLabel,
}: RightSubscriptionBadgeModel): ReactElement => {
  return (
    <S.RightSubscriptionBadge status={status}>
      <S.RightSubscriptionBadgeLabel
        dangerouslySetInnerHTML={{ __html: label }}
      />
      {link && linkLabel && (
        <S.RightSubscriptionBadgeLink
          href={link}
          dangerouslySetInnerHTML={{ __html: linkLabel }}
        />
      )}
    </S.RightSubscriptionBadge>
  );
};
