import { FormikProps, useFormik } from 'formik';
import {
  ContactUsFormInitialValues,
  UseContactUsReturnType,
} from './contactUsForm.model';
import { useSelector } from 'react-redux';
import { getUser } from '../../user';
import { useTranslations } from '../../translations';
import { useMemo, useState } from 'react';
import {
  getContactUsFormInitialValues,
  getContactUsFormValidationSchema,
} from './contactUsForm.service';
import { getSimpleEvent, logger, pushDataLayer } from '../../../services';
import { makeContactUsRequest } from './contactUsForm.client';
import { CommonEvents, CommonEventTypes } from '../../../analytics';
import { hasAgreementCheckbox } from '../../form';

export const useContactUs = (
  handleClose: () => void,
  eventPosition: string | undefined
): UseContactUsReturnType => {
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [attemptNumber, setAttemptNumber] = useState<number>(0);

  const onSubmit = async (
    formValues: ContactUsFormInitialValues
  ): Promise<void> => {
    setIsErrorDisplayed(false);

    await makeContactUsRequest(
      formValues.firstName,
      formValues.email,
      formValues.phone,
      formValues.message
    )
      .then((): void => {
        if (eventPosition) {
          pushDataLayer(
            getSimpleEvent(
              CommonEvents.formAskQuestionSubmitSuccess,
              CommonEventTypes.overallEvents,
              eventPosition
            )
          );
        }
        setIsSuccessDisplayed(true);
      })
      .catch((error) => {
        logger.error(`[ERROR]: can not send contact request ${error}`);

        setIsErrorDisplayed(true);
        setAttemptNumber((prev) => prev + 1);
        if (eventPosition) {
          pushDataLayer(
            getSimpleEvent(
              CommonEvents.formAskQuestionSubmitError,
              CommonEventTypes.overallEvents,
              eventPosition
            )
          );
        }
      })
      .finally(() => {
        handleClose();
      });
  };

  const clearStatus = (): void => {
    setIsSuccessDisplayed(false);
    setIsErrorDisplayed(false);
  };

  return {
    onSubmit,
    attemptNumber,
    setAttemptNumber,
    clearStatus,
    isErrorDisplayed,
    isSuccessDisplayed,
  };
};

export const useContactUsForm = (
  onSubmit: (formData: ContactUsFormInitialValues) => void
): FormikProps<ContactUsFormInitialValues> => {
  const user = useSelector(getUser);
  const translations = useTranslations();
  const withAgreementCheckbox = hasAgreementCheckbox();

  const initialValues = useMemo(
    () => getContactUsFormInitialValues(user?.personal, withAgreementCheckbox),
    [user?.personal, withAgreementCheckbox]
  );

  const validationSchema = useMemo(
    () => getContactUsFormValidationSchema(translations),
    []
  );

  return useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });
};
