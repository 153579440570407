import {
  CourseModel,
  DiscountProgramColorsModel,
  Nullable,
  TranslationModel,
  UserAggregateModel,
} from '@lerna-core';

export type UserOrderFormContainerProps = {
  translations: TranslationModel;
  course: CourseModel;
  user: UserAggregateModel;
  eventPosition: string;
  courseIndex?: Nullable<number>;
  discountProgramColors?: DiscountProgramColorsModel;
};

export enum UserOrderFormPaymentContentTypes {
  consultation = 'consultation',
  onlinePay = 'onlinePay',
}

export enum UserOrderFormSubmitStatusTypes {
  error = 'error',
  success = 'success',
  exist = 'repeat',
}
