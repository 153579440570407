import React, { ReactElement } from 'react';
import {
  ContentSwitcherStyled,
  SwitcherStyled,
} from './contentSwitcher.styled';
import { useFormSubscriptionContext } from '../formSubscription.context';
import { FormSubscriptionContentTypes } from '../formSubscription.model';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '../../../form.analytics';
import { CommonEventTypes } from '@lerna-core';

export const ContentSwitcherContainer = (): ReactElement => {
  const {
    translations,
    isSubscriptionFormContentSelected,
    handleChangeFormContent,
    course,
    eventPosition,
  } = useFormSubscriptionContext();

  const switcherText = isSubscriptionFormContentSelected
    ? translations?.subscription_form_switcher_text_1
    : translations?.subscription_form_switcher_text_2;

  const handleClickSwitcher = (): void => {
    if (isSubscriptionFormContentSelected) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollSelectDefault,
        CommonEventTypes.coursesEvents,
        eventPosition,
        course
      );

      handleChangeFormContent(FormSubscriptionContentTypes.fullAccess);
    } else {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollSelectSubscription,
        CommonEventTypes.coursesEvents,
        eventPosition,
        course
      );

      handleChangeFormContent(FormSubscriptionContentTypes.subscription);
    }
  };

  return (
    <ContentSwitcherStyled>
      <SwitcherStyled onClick={handleClickSwitcher}>
        <span>{switcherText}</span>
      </SwitcherStyled>
    </ContentSwitcherStyled>
  );
};
