import React, { ReactElement } from 'react';
import { Nullable } from '../../../models';
import {
  IconAnalyticsComponent,
  IconBadgeComponent,
  IconBoxComponent,
  IconCaseComponent,
  IconCoinComponent,
  IconCVComponent,
  IconDocumentWithArrowCheckComponent,
  IconGridComponent,
  IconHeartComponent,
  IconHelpComponent,
  IconHomeComponent,
  IconLaptopComponent,
  IconListComponent,
  IconLoginLComponent,
  IconLogoutLComponent,
  IconMarketingComponent,
  IconPaperWithPenComponent,
  IconPercentComponent,
  IconPercentWithPaperComponent,
  IconPortfolioComponent,
  IconReferralUsersComponent,
  IconSettingsComponent,
  IconUserComponent,
  IconUsersComponent,
} from '../../icons';
import { IconNames } from './menuIcon.constants';
import { IconProps } from './menuIcon.model';

export const mapIcons = (
  iconName: Nullable<string>,
  { color }: IconProps
): Nullable<ReactElement> => {
  switch (iconName) {
    case IconNames.user:
      return <IconUserComponent strokeColor={color} />;
    case IconNames.catalog:
      return <IconListComponent strokeColor={color} />;
    case IconNames.home:
      return <IconHomeComponent strokeColor={color} />;
    case IconNames.case:
      return <IconCaseComponent fillColor={color} strokeColor={color} />;
    case IconNames.analytics:
      return <IconAnalyticsComponent fillColor={color} strokeColor={color} />;
    case IconNames.proftests:
    case IconNames.badge:
      return <IconBadgeComponent fillColor={color} strokeColor={color} />;
    case IconNames.coin:
      return <IconCoinComponent strokeColor={color} fillColor={color} />;
    case IconNames.grid:
      return <IconGridComponent strokeColor={color} />;
    case IconNames.heart:
      return <IconHeartComponent strokeColor={color} />;
    case IconNames.list:
      return <IconHeartComponent strokeColor={color} />;
    case IconNames.cv:
      return <IconCVComponent strokeColor={color} />;
    case IconNames.cog:
    case IconNames.settings:
      return <IconSettingsComponent strokeColor={color} />;
    case IconNames.users:
      return <IconUsersComponent strokeColor={color} />;
    case IconNames.help:
      return <IconHelpComponent fillColor={color} strokeColor={color} />;
    case IconNames.percent:
      return <IconPercentComponent strokeColor={color} />;
    case IconNames.percentWithPen:
      return <IconPercentWithPaperComponent strokeColor={color} />;
    case IconNames.portfolio:
      return <IconPortfolioComponent strokeColor={color} fillColor={color} />;
    case IconNames.paper_with_pen:
      return (
        <IconPaperWithPenComponent strokeColor={color} fillColor={color} />
      );
    case IconNames.box:
      return <IconBoxComponent fillColor={color} />;
    case IconNames.referralUsers:
      return <IconReferralUsersComponent fillColor={color} />;
    case IconNames.logout:
      return <IconLogoutLComponent strokeColor={color} />;
    case IconNames.login:
      return <IconLoginLComponent strokeColor={color} />;
    case IconNames.tests:
      return <IconDocumentWithArrowCheckComponent strokeColor={color} />;
    case IconNames.marketing:
      return <IconMarketingComponent strokeColor={color} />;
    case IconNames.laptop:
      return <IconLaptopComponent strokeColor={color} />;
    default:
      return null;
  }
};
