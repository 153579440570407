import React, { ReactElement } from 'react';
import {
  CheckboxControlComponent,
  FormAgreementLabelComponent,
} from '@lerna-core';
import { FormPopupPrivacyContainerProps } from './formPopupPrivacy.model';

export const FormPopupPrivacyContainer = ({
  checked,
  onChange,
  translations,
  disabled,
  hasError,
}: FormPopupPrivacyContainerProps): ReactElement => {
  const checkboxLabel = (
    <FormAgreementLabelComponent
      hasError={hasError}
      text={translations?.form_agreement_text}
    />
  );

  return (
    <CheckboxControlComponent
      name="privacy"
      checked={checked}
      onChange={onChange}
      children={checkboxLabel}
      disabled={disabled}
      margin="0"
      hasError={hasError}
    />
  );
};
