import { useDiscountProgram } from '@features/common/discountProgram';
import {
  AppService,
  AuthorizationContainer,
  BackgroundContainer,
  customerActivityService,
  DeviceInfoContext,
  HeadSeoMetaTagsContainer,
  logger,
  mindboxService,
  OnboardingArea,
  onboardingService,
  PresetsContext,
  QueryParams,
  redirectMiddleware,
  requestUser,
  requestUserMenu,
  serverAuthorizationService,
  staticSrc,
  useUser,
} from '@lerna-core';
import { AppPropsModel } from '@models';
import { getInitialProps, shouldRedirectToPersonal } from '@services';
import { wrapper } from '@store/store-v2';
import { getBackgroundColors } from '@styles';
import { AppContext } from 'next/app';
import Head from 'next/head';
import React, { ReactElement, useEffect } from 'react';
import { Provider } from 'react-redux';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

const App = ({
  Component,
  pageProps,
  theme,
  deviceInfo,
  mediaPresets,
  suggests,
}: AppPropsModel): ReactElement => {
  const { user } = useUser();
  const { isDiscountProgramPage } = useDiscountProgram();

  onboardingService.sync(suggests, Boolean(user?.personal?.uuid));

  useEffect(() => {
    if (!user?.personal?.uuid) return;

    customerActivityService.init();
    mindboxService.init({ email: user.personal.email });
  }, [user]);

  return (
    <AuthorizationContainer shouldLogin={false}>
      <>
        <Head>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, maximum-scale=1.0"
          />
          <link rel="shortcut icon" href={staticSrc.favicon} />
        </Head>
        <HeadSeoMetaTagsContainer />
        <StyleSheetManager disableVendorPrefixes>
          <ThemeProvider theme={theme}>
            <BackgroundContainer
              {...getBackgroundColors(user, isDiscountProgramPage)}
            />
            <PresetsContext.Provider value={{ mediaPresets }}>
              <DeviceInfoContext.Provider value={deviceInfo}>
                <Normalize />
                <Component {...pageProps} />
              </DeviceInfoContext.Provider>
            </PresetsContext.Provider>
          </ThemeProvider>
        </StyleSheetManager>
      </>
    </AuthorizationContainer>
  );
};

function MyApp({ Component, ...rest }: AppPropsModel): ReactElement {
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <App Component={Component} {...props} />
    </Provider>
  );
}

MyApp.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (context: AppContext) => {
    const isPromoCatalog = context.router.asPath.startsWith('/promo/');
    const isPromoCourse = context.router.query.hasOwnProperty(
      QueryParams.discountProgram
    );

    if (!isPromoCatalog && !isPromoCourse) {
      await serverAuthorizationService
        .initServer(context)
        .then(async () => {
          if (!AppService.isClientSide) {
            const requestHeaders = serverAuthorizationService.getAuthHeaders(
              context.ctx.req,
              context.ctx.res
            );
            await Promise.all([
              requestUser(store.dispatch, requestHeaders),
              requestUserMenu(store.dispatch, requestHeaders),
            ]);
            const user = store.getState().user?.user || null;
            shouldRedirectToPersonal(context, user);
          }
        })
        .catch((error) => {
          if (serverAuthorizationService.isAuthed()) {
            logger.error(
              `[ERROR]: can not auth user on server side. # Error: ${JSON.stringify(
                error || ''
              )} # Headers: ${JSON.stringify(context.ctx.req?.headers || '')}`,
              error
            );
          }
        });
    }

    const user = store.getState().user?.user || null;

    await Promise.all([
      redirectMiddleware(context.ctx),
      onboardingService.init(OnboardingArea.catalog, context, !!user),
    ]);

    const suggests = onboardingService.suggests;

    const initialProps = await getInitialProps(context);

    return {
      ...initialProps,
      suggests,
    };
  }
);

export default MyApp;
