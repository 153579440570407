import { ReactElement, ReactNode } from 'react';
import { Nullable } from '../../../models';

export type PopupStylePaddingProps = {
  top: string;
  right: string;
  bottom: string;
  left: string;
};

export type PopupStyleProps = {
  paddingSticky: PopupStylePaddingProps;
  paddingContent: PopupStylePaddingProps;
};

export type PopupStyleModel = {
  left: number;
  top: number;
  width?: number;
} | null;

export enum CloseTypes {
  close = 'close',
  back = 'back',
}

export type ContentStyles = {
  paddingTop?: number;
  paddingLeft?: number;
  paddingBottom?: number;
  paddingRight?: number;
};

export type PopupContainerPropsType = {
  children: ReactNode;
  footer?: Nullable<ReactElement>;
  title?: string;
  isOpened?: boolean;
  handleClose?: () => void;
  handleDestroy?: () => void;
  style?: PopupStyleModel;
  hasClose?: boolean;
  closeType?: string;
  customStyle?: boolean;
  hasHeader?: boolean;
  isMobile?: boolean;
  withoutContentOverflowY?: boolean;
  width?: string;
  closeTimeout?: number;
  redirectUrl?: string;
  footerBorder?: string;
  darkMode?: boolean;
  parent?: Nullable<HTMLDivElement>;
  isNoCloseWhenMove?: boolean;
  isTouching?: boolean;
  contentStyles?: ContentStyles;
  styleSettings?: PopupStyleProps;
  closeOutside?: boolean;
  fullHeightMobile?: boolean;
  cleanDOMOnClose?: boolean;
  fullSizeMobile?: boolean;
  className?: string;
};
