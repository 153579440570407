import React, { ReactElement, useContext } from 'react';
import {
  CourseItemProgressBarStyled,
  CourseItemProgressBarTextStyled,
  CourseItemProgressBarWrapperStyled,
  CourseItemProgressItemWrapperStyled,
  CourseItemProgressStyled,
} from './courseItemProgress.styled';
import { DeviceInfoContext } from '../../../device';
import { browserService } from '../../../../services';
import { CourseItemProgressProps } from './courseItemProgress.model';
import { useTranslations } from '../../../translations';
import { CourseItemProgressItemComponent } from './courseItemProgressItem';
import { getProgressItems } from './courseItemProgress.helper';

export const CourseItemProgressComponent = ({
  vendorSlug,
  progress,
}: CourseItemProgressProps): ReactElement | null => {
  const translations = useTranslations();
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  if (!progress) {
    return null;
  }

  const progressItems = getProgressItems(vendorSlug, progress, translations);

  return (
    <CourseItemProgressStyled>
      <CourseItemProgressItemWrapperStyled isMobile={isMobile}>
        {progressItems.map((item) => (
          <CourseItemProgressItemComponent
            progressItem={item}
            isMobile={isMobile}
            key={item.title}
          />
        ))}
      </CourseItemProgressItemWrapperStyled>

      <CourseItemProgressBarWrapperStyled>
        <CourseItemProgressBarStyled progress={progress.progressPercent} />
        <CourseItemProgressBarTextStyled isMobile={isMobile}>
          {progress.progressPercent}%
        </CourseItemProgressBarTextStyled>
      </CourseItemProgressBarWrapperStyled>
    </CourseItemProgressStyled>
  );
};
