import { Dispatch } from 'react';
import { AxiosError } from 'axios';
import { logger } from '../../services';
import { vendorsClientProvider } from './vendors.clientProvider';
import * as constants from './vendors.constants';
import { VendorModel, VendorsActions } from './vendors.model';

export const requestVendors = async (
  dispatch: Dispatch<VendorsActions>,
  host?: string,
  privateHost?: string
): Promise<void> => {
  await vendorsClientProvider(host, privateHost)
    .then((response) => {
      dispatch(requestVendorsSuccess(response));
    })
    .catch((error) => {
      logger.error(`[ERROR]: can not get vendors ${error}`);
      dispatch(requestVendorsError(error));
    });
};

export const requestVendorsSuccess = (
  payload: VendorModel[]
): VendorsActions => ({
  type: constants.REQUEST_VENDORS_SUCCESS,
  payload,
});

export const setVendorsBySlug = (
  payload: VendorModel[],
  slugs: string[]
): VendorsActions => ({
  type: constants.REQUEST_VENDORS_SUCCESS,
  payload: payload.filter((vendor) => slugs.includes(vendor.slug)),
});

export const requestVendorsError = (payload: AxiosError): VendorsActions => ({
  type: constants.REQUEST_VENDORS_ERROR,
  payload,
});
