import { GetServerSidePropsContext, NextPageContext } from 'next';
import { PersonalModel, UserAggregateModel } from '../../features';
import { urlService } from '../../services';
import { getUserRedirectLink, needRedirectUser } from './userRedirect.helper';

const redirectToRegister = (
  context: NextPageContext | GetServerSidePropsContext,
  personal?: PersonalModel
): void => {
  const needRedirectToRegister = needRedirectUser(personal);

  if (needRedirectToRegister) {
    context?.res?.writeHead(301, {
      Location: getUserRedirectLink(
        context.query,
        urlService.getFullUrlFromToPage(context.req?.url || '', true, true)
      ),
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
    context?.res?.end();
  }
};

export const userRedirectMiddleware = (
  context: NextPageContext | GetServerSidePropsContext,
  user: UserAggregateModel | null
): void => {
  redirectToRegister(context, user?.personal);
};
