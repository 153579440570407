import styled from 'styled-components';
import { fontSettings } from '../../../styles';
import { TextAreaControlComponent } from '../../form';

export const ContactUsFormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ContactUsFormStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactUsFormHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const HeaderTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  text-align: center;
  font-weight: 700;
  white-space: break-spaces;
`;

export const HeaderSubtitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 400;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  text-align: center;
`;

export const TextAreaStyled = styled(TextAreaControlComponent)`
  textarea {
    height: 120px;
  }
`;
