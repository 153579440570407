import { createSelector } from 'reselect';
import { RootState } from '../../models';
import { VendorsState } from './vendors.model';

export const getVendors = createSelector(
  (state: RootState<VendorsState>) => state.vendors,
  (reducer: VendorsState) => reducer.vendors
);

export const getVendorsBySlugs = (slugs: string[]) =>
  createSelector(
    (state: RootState<VendorsState>) => state.vendors,
    (reducer: VendorsState) =>
      reducer.vendors.filter((vendor) => slugs.includes(vendor.slug))
  );

export const getVendorsStatus = createSelector(
  (state: RootState<VendorsState>) => state.vendors,
  (reducer: VendorsState) => reducer.status
);
