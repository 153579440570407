import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { FilterTabStyled } from '../filterTab.styled';
import { FilterOptionSelectAllProps } from './filterTabSelectAll.model';
import { mainPath, urlService } from '@lerna-core';

export const FilterTabSelectAll = ({
  filter,
  defaultPathname,
  translations,
}: FilterOptionSelectAllProps): ReactElement => {
  const router = useRouter();

  const isActive = !router.query[filter.slug] && !router.query.fastlink;

  const handleClick = (): void => {
    const defaultPath = router.query?.fastlink
      ? mainPath.courses
      : defaultPathname;
    const newQuery = router.query;

    delete newQuery[filter.slug];

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  return (
    <FilterTabStyled isActive={isActive} onClick={handleClick}>
      {translations.all}
    </FilterTabStyled>
  );
};
