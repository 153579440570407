import {
  ButtonAdditionalColors,
  IButton,
  IColor,
  IFont,
  ITheme,
} from '../../../index';
import { ButtonColor, ButtonSize } from '../../features';
import { fontSettings } from './font.mixins';
import { rgba } from 'polished';

const defaultBorderRadius = 8;
const defaultBorderWidth = 2;

// button size
export const buttonSmallSizeStyled = (
  fontTheme: IFont,
  borderRadius?: number
): string => `
  ${fontSettings(fontTheme.SIZE_15, fontTheme)};
  padding: 10px ${borderRadius === 100 ? 18 : 14}px;
  
  svg {
      flex: 0 0 16px;
  }
`;

export const buttonNormalSizeStyled = (
  fontTheme: IFont,
  borderRadius?: number
): string => `
  ${fontSettings(fontTheme.SIZE_17, fontTheme)};
  padding: 14px ${borderRadius === 100 ? 22 : 18}px;
  
  svg {
    flex: 0 0 24px;
  }  
`;

//button color
export const buttonPurpleColorStyled = (colorTheme: IColor): string => `
  background-color: ${colorTheme.PURPLE};
  color: ${colorTheme.WHITE}; 
  
  ${
    colorTheme.PURPLE
      ? `:hover {
    background-color: ${rgba(colorTheme.PURPLE, 0.92)};
  }`
      : ''
  }
`;

export const buttonMediumPurpleColorStyled = (colorTheme: IColor): string => `
  background-color: ${colorTheme.MEDIUM_PURPLE};
  color: ${colorTheme.WHITE}; 
`;

export const buttonBrightPurpleColorStyled = (colorTheme: IColor): string => `
  background-color: ${colorTheme.PRIMARY['100']};
  color: ${colorTheme.PRIMARY['500']}; 
  
  &:hover {
    opacity: 0.8;
  }
`;

export const buttonWhiteColorStyled = (
  colorTheme: IColor,
  bordered?: boolean,
  textColor?: string
): string => `
  background-color: ${colorTheme.WHITE};
  color: ${textColor || colorTheme.TEXT_DARK_COLOR};
  border-color: ${bordered ? `${colorTheme.BLACK_08}` : 'transparent'};
  box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.04);
  
  &:hover {
    opacity: 0.8;
  }
`;

export const buttonTransparentColorStyled = (
  colorTheme: IColor,
  borderColor?: string,
  textColor?: string
): string => `
  background-color: transparent;
  color: ${textColor || colorTheme.TEXT_DARK_COLOR};
  border-color: ${borderColor || colorTheme.BLACK_08};  
  
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border-color: ${borderColor || colorTheme.BLACK_12}
  }
`;

export const buttonTransparentFullColorStyled = (
  colorTheme: IColor,
  textColor?: string
): string => `
  background-color: transparent;
  color: ${textColor || colorTheme.TEXT_DARK_COLOR};
`;

export const buttonNormalColorStyled = (colorTheme: IColor): string => `
  background-color: ${rgba(0, 0, 0, 0.04)};
  color: ${colorTheme.TEXT_DARK_COLOR}; 
  
  &:hover {
    background-color: ${colorTheme.BLACK_08}
  }
`;

export const buttonAccentColorStyled = (colorTheme: IColor): string => `
  background-color: ${colorTheme.ACCENT};
  color: ${colorTheme.TEXT_DARK_COLOR}; 
  
  &:hover {
   background-color: #D8FE65; 
  }
`;

export const buttonRedColorStyled = (colorTheme: IColor): string => `
  background-color: ${colorTheme.ERROR['100']};
  color: ${colorTheme.ERROR['900']}; 
  
  &:hover {
    opacity: 0.8;
  }
`;

export const buttonGradientColorStyled = (
  colorTheme: IColor,
  borderRadius?: number
): string => `
  color: ${colorTheme.WHITE};
  position: relative;
  border-color: ${colorTheme.WHITE_32};
  background-color: transparent;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: calc(${
      borderRadius ? borderRadius : defaultBorderRadius
    }px - ${defaultBorderWidth}px);
    background: linear-gradient(
      93deg, ${colorTheme.WHITE_24}, rgba(255, 255, 255, 0.0768) 
    ); 
  }
`;

export const buttonColoredGradientColorStyled = (
  colorTheme: IColor,
  borderRadius?: number
): string => `
  color: ${colorTheme.WHITE};
  position: relative;
  border-color: transparent;
  background-color: transparent;
  z-index: 0;
  
  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: -${defaultBorderWidth}px;
    right: -${defaultBorderWidth}px;
    bottom: -${defaultBorderWidth}px;
    left: -${defaultBorderWidth}px;
    border-radius: calc(${
      borderRadius ? borderRadius : defaultBorderRadius
    }px - ${defaultBorderWidth}px);
    background: linear-gradient(73.3deg, #ee2c72 7.79%, #4c07df 91.63%);
  }
`;

export const getButtonAdditionalColors = (
  additionalColor: ButtonAdditionalColors
): string => `
  background-color: ${additionalColor.backgroundColor};
  color: ${additionalColor.color}; 
  
  &:hover {
    background-color: ${rgba(additionalColor.backgroundColor, 0.92)};
  }
}
`;

const getButtonSize = (
  theme: ITheme,
  size?: string,
  borderRadius?: number
): string => {
  switch (size) {
    case ButtonSize.small:
      return buttonSmallSizeStyled(theme.FONT, borderRadius);
    default:
      return buttonNormalSizeStyled(theme.FONT, borderRadius);
  }
};

const getButtonColor = (
  theme: ITheme,
  color?: string,
  bordered?: boolean,
  borderRadius?: number,
  borderColor?: string,
  textColor?: string
): string => {
  switch (color) {
    case ButtonColor.purple:
      return buttonPurpleColorStyled(theme.COLOR);

    case ButtonColor.mediumPurple:
      return buttonMediumPurpleColorStyled(theme.COLOR);

    case ButtonColor.brightPurple:
      return buttonBrightPurpleColorStyled(theme.COLOR);

    case ButtonColor.transparent:
      return buttonTransparentColorStyled(theme.COLOR, borderColor, textColor);

    case ButtonColor.transparentFull:
      return buttonTransparentFullColorStyled(theme.COLOR, textColor);

    case ButtonColor.white:
      return buttonWhiteColorStyled(theme.COLOR, bordered, textColor);

    case ButtonColor.red:
      return buttonRedColorStyled(theme.COLOR);

    case ButtonColor.gradient:
      return buttonGradientColorStyled(theme.COLOR, borderRadius);

    case ButtonColor.coloredGradient:
      return buttonColoredGradientColorStyled(theme.COLOR, borderRadius);

    case ButtonColor.accent:
      return buttonAccentColorStyled(theme.COLOR);

    default:
      return buttonNormalColorStyled(theme.COLOR);
  }
};

export type ButtonIconProps = {
  light?: boolean;
  theme: ITheme;
};

export const getIconColor = ({ light, theme }: ButtonIconProps): string =>
  light ? theme.COLOR.TEXT_LIGHT_COLOR : theme.COLOR.TEXT_DARK_COLOR;

type ButtonDefaultStylesProps = IButton & {
  theme: ITheme;
};

export const buttonDefaultStyles = (
  props: ButtonDefaultStylesProps
): string => `
  box-sizing: border-box;
  font-weight: 500;
  cursor: pointer; 
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  outline: none;
  touch-action: manipulation;
  border: ${
    props.borderWidth ? props.borderWidth : defaultBorderWidth
  }px solid transparent;
  width: ${props.fullWidth ? '100%' : 'initial'};
  box-shadow: ${props.boxShadow ? props.boxShadow : 'none'};
  ${getButtonSize(props.theme, props.size, props.borderRadius)};
  ${getButtonColor(
    props.theme,
    props.color,
    props.bordered,
    props.borderRadius,
    props.borderColor,
    props.textColor
  )};
  border-radius: ${
    props.borderRadius ? props.borderRadius : defaultBorderRadius
  }px;
  transition: all 0.3s ease;
  
  ${
    props.additionalColors
      ? getButtonAdditionalColors(props.additionalColors)
      : ''
  }
  
  svg {
    margin: -1px 0;
    display: inline-flex;
    
    &:last-child {
      margin-left: 8px;
    }
    
    &:first-child {
      margin-right: 8px;
    }
    
    &:last-child:first-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
  
  &:disabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }
  
  sup {
    margin-left: 2px;
  }
`;
