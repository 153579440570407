import React, { ReactElement } from 'react';

import { SelectionHeaderContainer } from './selectionHeader.container';
import { HeaderProps, SelectionHeader } from './selectionHeader.model';

export const getSelectionHeader = (
  handleCheckAll?: () => void
): SelectionHeader => {
  return {
    Header({
      getToggleAllRowsSelectedProps,
      toggleAllRowsSelected,
    }: HeaderProps): ReactElement {
      return (
        <SelectionHeaderContainer
          handleCheckAll={handleCheckAll}
          toggleAllRowsSelected={toggleAllRowsSelected}
          getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
        />
      );
    },
  };
};
