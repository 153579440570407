import styled from 'styled-components';

import { defaultGradient, textGradient, fontSettings } from '../../../styles';
import { UserAvatarStyled } from '../userAvatar';
import { UserBarStyledProps } from './userBar.model';
import { rgba } from 'polished';

const borderWidth = 2;

export const UserBarContainerStyled = styled.div<UserBarStyledProps>`
  position: relative;
  display: inline-flex;
  border-radius: ${({ borderRadius }): number => borderRadius}px;
  background-color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.5)};
`;

export const UserBarLabelStyle = styled.span`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  font-weight: 600;
  margin-right: 10px;
  margin-left: 5px;
  white-space: nowrap;
  text-transform: lowercase;
  ${({ theme }): string =>
    textGradient(
      theme.COLOR.DEFAULT_GRADIENT_START,
      theme.COLOR.DEFAULT_GRADIENT_END
    )}
`;

export const UserBarTargetStyled = styled.div<UserBarStyledProps>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 11px;
  border-radius: ${({ borderRadius }): number => borderRadius}px;

  ${UserAvatarStyled} {
    cursor: pointer;
  }
`;

export const UserBarGradientBorderStyled = styled.div<UserBarStyledProps>`
  position: absolute;
  padding: ${borderWidth}px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0) padding-box;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  border-radius: ${({ borderRadius }): number => borderRadius}px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    ${({ theme }): string => defaultGradient(theme.COLOR)}
  }
`;
