import { publicConfig } from '../../../services';
import { RegionsEnum } from '../../../models';

export const hasAgreementCheckbox = (): boolean => {
  switch (publicConfig?.REGION) {
    case RegionsEnum.ru:
      return false;
    default:
      return true;
  }
};
