import {
  isPhoneValid,
  Nullable,
  phoneRegex,
  TranslationModel,
  Translations,
  UserAggregateModel,
} from '@lerna-core';
import * as Yup from 'yup';
import {
  ContactUsFormInitialValues,
  ContactUsFormValidationSchema,
} from './contactUsForm.model';

const getTextMaxLengthValidationMessage = (
  length: number,
  translations: TranslationModel
): string =>
  `${
    translations?.max_length_is
  } ${length} ${translations?.symbols_many?.toLowerCase()}`;

export const getContactUsFormInitialValues = (
  user: Nullable<UserAggregateModel>,
  withCheckbox: boolean
): ContactUsFormInitialValues => ({
  name: user?.personal?.firstName || '',
  email: user?.personal?.email || '',
  phone: user?.personal?.phone || '',
  message: '',
  privacy: withCheckbox,
});

export function getContactUsFormValidationSchema(
  translations: Translations
): ContactUsFormValidationSchema {
  return Yup.object().shape({
    name: Yup.string()
      .required(translations?.input_required_error_label)
      .max(255, getTextMaxLengthValidationMessage(255, translations))
      .trim(),
    email: Yup.string()
      .email(translations?.input_email_format_error_label)
      .required(translations?.input_required_error_label),
    phone: Yup.string()
      .required(translations?.input_required_error_label)
      .matches(phoneRegex, translations?.input_phone_format_error_label)
      .test(
        'phone',
        translations?.input_phone_format_error_label,
        isPhoneValid
      ),
    message: Yup.string()
      .optional()
      .max(1000, getTextMaxLengthValidationMessage(1000, translations))
      .trim(),
    privacy: Yup.boolean()
      .required()
      .oneOf([true], translations?.input_required_error_label),
  });
}
