import React, { ReactElement } from 'react';
import {
  ContentInfoStyled,
  SwitcherStyled,
  TextStyled,
} from './contentInfo.styled';
import { useCompensationFormContext } from '../../compensationForm.context';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { CommonEventTypes } from '@lerna-core';

export const ContentInfoContainer = (): ReactElement => {
  const {
    course,
    translations,
    isCourseWithCompensationContent,
    handleChangeFormContent,
    eventPosition,
  } = useCompensationFormContext();

  const infoText = isCourseWithCompensationContent
    ? `${translations?.compensation_b2b_form_info_1}.`
    : `${translations?.compensation_b2b_form_info_2}.`;
  const switcherText = isCourseWithCompensationContent
    ? translations?.compensation_b2b_form_switcher_text_1
    : translations?.compensation_b2b_form_switcher_text_2;

  const handleClickSwitcher = (): void => {
    if (isCourseWithCompensationContent) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollSelectSelfPay,
        CommonEventTypes.coursesEvents,
        eventPosition,
        course
      );
      handleChangeFormContent('courseWithoutCompensation');
    } else {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollSelectCompanyPay,
        CommonEventTypes.coursesEvents,
        eventPosition,
        course
      );
      handleChangeFormContent('courseWithCompensation');
    }
  };

  return (
    <ContentInfoStyled>
      <TextStyled>{infoText}</TextStyled>
      <SwitcherStyled onClick={handleClickSwitcher}>
        <span>{switcherText}</span>
      </SwitcherStyled>
    </ContentInfoStyled>
  );
};
