import {
  CommonEventTypes,
  CourseModel,
  Nullable,
  PromoCodeErrorModel,
  PromoCodeWithErrorModel,
  Translations,
} from '@lerna-core';
import * as Yup from 'yup';
import {
  getTextMaxLengthValidationMessage,
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { FormB2CInitialValues, FormB2CValidationSchema } from './formB2C.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';

export const getFormB2CInitialValues = (): FormB2CInitialValues => ({
  withPromoCode: false,
  promoCode: '',
  promoCodeDraft: '',
});

export function getFormB2CValidationSchema(
  translations: Translations,
  promoCode: Nullable<PromoCodeWithErrorModel>,
  discountProgramQuery?: string
): FormB2CValidationSchema {
  const validationObject = {
    withPromoCode: Yup.boolean().optional(),
  };

  if (!!discountProgramQuery) {
    Object.assign(validationObject, {
      promoCodeDraft: Yup.string()
        .when('withPromoCode', {
          is: true,
          then: (code) =>
            code
              .required(
                translations?.promo_code_is_not_applied ||
                  'Промокод не применён'
              )
              .max(255, getTextMaxLengthValidationMessage(255, translations))
              .trim(),
        })
        .test(
          'promoCode status 404',
          'Промокод не подходит',
          () => !((promoCode as PromoCodeErrorModel)?.status === 404)
        )
        .test(
          'promoCode status 410',
          'Истек срок действия промокода',
          () => !((promoCode as PromoCodeErrorModel)?.status === 410)
        ),
    });
  } else {
    Object.assign(validationObject, {
      promoCode: Yup.string()
        .when('withPromoCode', {
          is: true,
          then: (code) =>
            code
              .required(translations?.input_required_error_label)
              .max(255, getTextMaxLengthValidationMessage(255, translations))
              .trim(),
        })
        .test(
          'promoCode status 404',
          'Промокод не подходит',
          () => !((promoCode as PromoCodeErrorModel)?.status === 404)
        )
        .test(
          'promoCode status 410',
          'Истек срок действия промокода',
          () => !((promoCode as PromoCodeErrorModel)?.status === 410)
        ),
    });
  }

  return Yup.object().shape(validationObject);
}

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  isFreeCourse: boolean,
  orderId?: string,
  requestType?: string
): void => {
  let event = `${OrderFormEvents.formEnrollStatus.replace('{{type}}', type)}`;

  if (
    paymentContent === UserOrderFormPaymentContentTypes.consultation &&
    !isFreeCourse
  ) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'consultation')}`;
  }
  if (isFreeCourse) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'free_course')}`;
  }

  orderFormPushDataLayer(
    event,
    CommonEventTypes.orderEvents,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
