import React, { ReactElement, useMemo } from 'react';
import { PricesItemContainer } from '../pricesItem';
import { PricesStyled, WrapperStyled } from './prices.styled';
import { PricesConsultationContainerProps } from './prices.model';
import { PricesInfoContainer } from '../pricesInfo';
import { textGradient, useTheme } from '@lerna-core';
import { getCustomerPrices } from '@features/common/course';

export const PricesContainer = ({
  course,
  translations,
  withInstallment = true,
  isLernaPrime,
}: PricesConsultationContainerProps): ReactElement => {
  const theme = useTheme();
  const {
    startPrice,
    hasCorporateDiscount,
    corporateDiscountValue,
    corporateDiscountAmount,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
  } = useMemo(() => getCustomerPrices(course), []);

  const showStartPrice =
    (withInstallment && hasInstallment) || hasOneOfDiscounts;
  const showTotalPrice =
    withInstallment &&
    hasInstallment &&
    hasOneOfDiscounts &&
    !course.isFreeByDiscount;
  const showBonusPoints = hasBonusPointsDiscount && !course.isFreeByDiscount;

  const corporateDiscountTitle = `${translations?.customer_discount_label} - ${corporateDiscountValue}`;
  const gradientText = textGradient(
    theme.COLOR.DEFAULT_GRADIENT_START,
    theme.COLOR.DEFAULT_GRADIENT_END
  );
  const bonusPointsTitle = isLernaPrime
    ? translations?.company_discount_bonuses_label
    : translations?.discount_bonuses_label;

  return (
    <PricesStyled>
      {!course.isFree && (
        <WrapperStyled>
          {showStartPrice && (
            <PricesItemContainer
              title={translations?.item_price_label}
              value={startPrice}
              itemStyles={
                hasOneOfDiscounts
                  ? {
                      value: `text-decoration: line-through; color: ${theme.COLOR.TEXT_LIGHT_COLOR}`,
                    }
                  : undefined
              }
            />
          )}
          {hasCorporateDiscount && (
            <PricesItemContainer
              title={corporateDiscountTitle}
              value={corporateDiscountAmount}
              isNegative
            />
          )}
          {showBonusPoints && (
            <PricesItemContainer
              title={bonusPointsTitle}
              value={bonusPointsDiscount}
              isNegative
              itemStyles={{
                title: gradientText,
                value: gradientText,
              }}
            />
          )}
          {showTotalPrice && (
            <PricesItemContainer
              title={translations?.total_price_label}
              value={totalPrice}
              itemStyles={{
                item: 'font-weight: 700;',
              }}
            />
          )}
        </WrapperStyled>
      )}
      <PricesInfoContainer
        course={course}
        translations={translations}
        withInstallment={withInstallment}
      />
    </PricesStyled>
  );
};
