import { dateService } from '../../services';
import { TariffApiModel, TariffModel } from './tariff.model';

export const tariffAdapter = (tariff: TariffApiModel): TariffModel => ({
  uuid: tariff.id,
  title: tariff.title,
  slug: tariff.slug,
  additionalCustomerBonusPoints: tariff.additional_customer_bonus_points,
  additionalManagerBonusPoints: tariff.additional_manager_bonus_points,
  spendingBonusPointsRatio: tariff.spending_bonus_points_ratio,
  isAddingJoiningCustomers: tariff.is_adding_joining_customers,
  withoutMiniCourses: tariff.without_courses,
  isDefault: tariff.is_default,
  createdAt: dateService.formatDate(tariff.created_at),
  expiredAt: dateService.formatDate(tariff.expired_at),
  customersCount: tariff.customers_count,
  customersCountLimit: tariff.customers_count_limit,
  customersRemovalLimit: tariff.customers_removal_limit,
  availableRemovalCount: tariff.available_removal_count,
  coursesCount: tariff.courses_count,
});
