import { getCourseEventData } from '@features/course';
import { getCourseHref } from '@helpers';
import {
  clearAcademikaCoursesVendor,
  CommonEventPositions,
  CommonEvents,
  CommonEventTypes,
  containUrlFilter,
  CourseModel,
  getSimpleEvent,
  handleOpenPopup,
  IframeInitiator,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  QueryParams,
  usePageRouter,
  useUser,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCoursesPageContext } from '../coursesPage';
import { CoursesPaginationContainer } from '../coursesPagination';
import { handleCoursesListDataLayer } from './coursesList.helpers';
import { CoursesListContainerProps } from './coursesList.model';
import {
  CoursesListContainerStyled,
  CoursesListStyled,
} from './coursesList.styled';
import { ListEmptyContainer } from './listEmpty';
import { ListFormsContainer } from './listForms';
import {
  GoToCourseEvent,
  ListItemButtonsEvent,
  ListItemContainer,
} from './listItem';

export const CoursesListContainer = ({
  useCourseHref = true,
  courseBasePath,
  hrefTarget,
}: CoursesListContainerProps): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const dispatch = useDispatch();
  const { user } = useUser();
  const router = useRouter();
  const isRouting = usePageRouter();

  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeCourseIndex, setActiveCourseIndex] =
    useState<Nullable<number>>(null);
  const [courses, setCourses] = useState<CourseModel[]>(
    clearAcademikaCoursesVendor(coursesContext?.courses || [])
  );

  const translations = coursesContext.translations;
  const discountProgramColors = iframeInitiator
    ? undefined
    : coursesContext?.discountProgram?.colors;
  const isListingWithBenefitsOnly = user?.career?.isListingWithBenefitsOnly;
  const isPricesHiddenOnListingCards =
    user?.career?.isPricesHiddenOnListingCards;
  const initiator = router.query[QueryParams.initiator] as IframeInitiator;
  const isEmptyList =
    courses.length === 0 && (!!router.query.title || containUrlFilter(router));
  const [activeCourse, setActiveCourse] = useState<Nullable<CourseModel>>(
    activeCourseIndex !== null && activeCourseIndex >= 0
      ? courses[activeCourseIndex]
      : null
  );

  const handleSuccessShowMore = (nextCourses: CourseModel[]): void => {
    setCourses(courses.concat(clearAcademikaCoursesVendor(nextCourses)));
    setIsLoading(false);
  };

  const handleClickShowMore = (): void => {
    pushDataLayer(
      getSimpleEvent(
        CommonEvents.clickButtonShowMoreCourses,
        CommonEventTypes.overallEvents,
        CommonEventPositions.coursesPageList
      )
    );
    setIsLoading(true);
  };

  const handleOpenSingUpCoursePopup = (courseUuid: string): void => {
    const activeCourseIndex = courses.findIndex(
      (course) => course.uuid === courseUuid
    );

    if (activeCourseIndex >= 0) {
      const course = courses[activeCourseIndex];
      setActiveCourse(course);
      setActiveCourseIndex(activeCourseIndex);

      if (!user) {
        handleCoursesListDataLayer(course, activeCourseIndex);

        dispatch(
          handleOpenPopup(
            PopupsEnum.guestOrderFormPopup,
            CommonEventPositions.coursesPageList
          )
        );

        return;
      }

      if (user) {
        handleCoursesListDataLayer(course, activeCourseIndex);

        dispatch(
          handleOpenPopup(
            PopupsEnum.userOrderFormPopup,
            CommonEventPositions.coursesPageList
          )
        );

        return;
      }
    }
  };

  useEffect(() => {
    setCourses(clearAcademikaCoursesVendor(coursesContext?.courses || []));
    if (
      coursesContext?.courses.length &&
      typeof activeCourseIndex === 'number'
    ) {
      setActiveCourse(coursesContext.courses[activeCourseIndex]);
    }
  }, [coursesContext?.courses]);

  if (isEmptyList) {
    return <ListEmptyContainer />;
  }

  return (
    <CoursesListContainerStyled>
      <CoursesListStyled isRouting={isRouting}>
        {courses.map((course, index) => {
          const courseHref = getCourseHref({
            course,
            router,
            basePath: courseBasePath,
            initiator,
          });

          const handleGoToCourse = (
            eventName: GoToCourseEvent | ListItemButtonsEvent
          ): void => {
            if (courseHref) {
              pushDataLayer(
                getCourseEventData(
                  eventName,
                  CommonEventTypes.coursesEvents,
                  CommonEventPositions.coursesPageList,
                  course,
                  index
                )
              );
            }

            if (
              !courseHref &&
              typeof handleOpenSingUpCoursePopup === 'function'
            ) {
              handleOpenSingUpCoursePopup(course.uuid);
            }
          };

          return (
            <ListItemContainer
              key={course.uuid}
              useCourseHref={useCourseHref}
              courseHref={courseHref}
              handleGoToCourse={handleGoToCourse}
              course={course}
              courseIndex={index}
              hrefTarget={hrefTarget}
              translations={translations}
              isListingWithBenefitsOnly={isListingWithBenefitsOnly}
              isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
              handleOpenSingUpCoursePopup={(): void =>
                handleOpenSingUpCoursePopup(course.uuid)
              }
              discountProgramColors={discountProgramColors}
            />
          );
        })}
      </CoursesListStyled>
      <CoursesPaginationContainer
        isLoading={isLoading}
        handleSuccess={handleSuccessShowMore}
        handleClick={handleClickShowMore}
      />
      <ListFormsContainer
        course={activeCourse}
        user={user}
        courseIndex={activeCourseIndex}
        translations={translations}
        discountProgramColors={discountProgramColors}
      />
    </CoursesListContainerStyled>
  );
};
