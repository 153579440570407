import React, { ReactElement, useMemo, useRef } from 'react';

import { UserAvatarContainer } from '../userAvatar';
import { UserBarContainerProps } from './userBar.model';
import {
  UserBarContainerStyled,
  UserBarGradientBorderStyled,
  UserBarLabelStyle,
  UserBarTargetStyled,
} from './userBar.styled';
import { BonusPointsContainer, hasBonusPoints } from '../../bonusPoints';
import { stringService } from '../../../services';
import { MenuDesktop } from '../userMenu';
import { useToggle } from '../../../hooks';
import { Nullable } from '../../../models';

export const UserBarContainer = ({
  avatarSize,
  user,
  isRound,
  handleClick,
  translations,
  borderRadius = 100,
  menu,
  projectPageName,
}: UserBarContainerProps): Nullable<ReactElement> => {
  const [isMenuOpened, toggleMenuOpened] = useToggle(false);
  const userBarRef = useRef<Nullable<HTMLDivElement>>(null);
  const withPoints = useMemo(
    () => hasBonusPoints(user?.career),
    [user?.career]
  );

  const bonusLabel = useMemo(() => {
    if (user?.bonuses?.points !== null) {
      return stringService.pluralTranslations(user?.bonuses?.points || 0, [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ]);
    }

    return '';
  }, [user?.bonuses]);

  if (!user?.personal?.isFulfilled) {
    return null;
  }

  const handleClickUserBar = (): void => {
    toggleMenuOpened();

    if (typeof handleClick === 'function') {
      handleClick();
    }
  };

  return (
    <UserBarContainerStyled ref={userBarRef} borderRadius={borderRadius}>
      <UserBarTargetStyled
        borderRadius={borderRadius}
        onClick={handleClickUserBar}
      >
        <>
          <UserBarGradientBorderStyled borderRadius={borderRadius} />
          {withPoints && user?.bonuses?.points !== null && (
            <UserBarLabelStyle>
              <BonusPointsContainer
                points={user?.bonuses?.points || 0}
                label={bonusLabel}
              />
            </UserBarLabelStyle>
          )}
        </>
        <UserAvatarContainer
          size={avatarSize}
          personal={user?.personal}
          isRound={isRound}
        />
      </UserBarTargetStyled>

      <MenuDesktop
        ref={userBarRef}
        menu={menu}
        user={user}
        isOpened={isMenuOpened}
        handleClose={toggleMenuOpened}
        translations={translations}
        projectPageName={projectPageName}
      />
    </UserBarContainerStyled>
  );
};
