import React, { ReactElement } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
  TitleStyled,
} from './contentRight.styled';
import { useGuestOrderFormContext } from '../../guestOrderForm.context';
import {
  ButtonAdditionalColors,
  ButtonColor,
  ButtonContainer,
  CheckboxControlComponent,
  FormAgreementLabelComponent,
  PhoneControlComponent,
  TextControlComponent,
  useTheme,
} from '@lerna-core';
import {
  FormPromoCode,
} from '@features/common/forms';

export const ContentRightContainer = (): ReactElement => {
  const theme = useTheme();
  const {
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    translations,
    discountProgramQuery,
    promoCodeQuery,
    handleFormSubmit,
    isRuRegion,
    discountProgramColors,
    isFreeCourse,
    setIsApplyingPromoCode,
  } = useGuestOrderFormContext();

  const hasPrivacyError = Boolean(errors.privacy) && touched.privacy;
  const showCompanyInputs = !discountProgramQuery && !isFreeCourse;
  const additionalButtonColors = discountProgramColors
    ? ({
        color: discountProgramColors.text,
        backgroundColor: discountProgramColors.background,
      } as ButtonAdditionalColors)
    : undefined;

  const privacyLabel = (
    <FormAgreementLabelComponent
      text={translations?.form_agreement_text}
      textColor={theme.COLOR.TEXT_DARK_COLOR}
      hasError={hasPrivacyError}
    />
  );

  return (
    <ContentRightStyled>
      <TitleStyled>{translations?.fill_contacts_data}</TitleStyled>
      <TextControlComponent
        type="text"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        name="name"
        placeholder={translations?.input_name_label}
        disabled={isSubmitting}
        touched={touched.name}
        errorMessage={errors.name}
        showResetButton
        setFieldValue={setFieldValue}
        floatingPlaceholder={false}
        margin="0 0 24px"
      />
      <PhoneControlComponent
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        name="phone"
        disabled={isSubmitting}
        touched={touched.phone}
        errorMessage={errors.phone}
        setFieldValue={setFieldValue}
        margin="0 0 24px"
      />
      <TextControlComponent
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        name="email"
        placeholder={translations?.input_email_label}
        disabled={isSubmitting}
        touched={touched.email}
        errorMessage={errors.email}
        showResetButton
        setFieldValue={setFieldValue}
        floatingPlaceholder={false}
        margin="0 0 24px"
      />
      {!isFreeCourse && (
        <FormPromoCode
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          disabled={isSubmitting || !!promoCodeQuery}
          onChange={handleChange}
          translations={translations}
          setFieldValue={setFieldValue}
          setIsApplyingPromoCode={setIsApplyingPromoCode}
        />
      )}
      {showCompanyInputs && (
        <>
          <CheckboxControlComponent
            name="withCompany"
            checked={values.withCompany}
            onChange={handleChange}
            children={translations?.i_am_legal_entity}
            disabled={isSubmitting}
            margin="0 0 20px"
          />
          {values.withCompany && (
            <TextControlComponent
              type="text"
              name="company"
              value={values.company}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={translations?.company_name}
              floatingPlaceholder={false}
              disabled={isSubmitting}
              touched={touched.company}
              errorMessage={errors.company}
              showResetButton
              setFieldValue={setFieldValue}
              margin="0 0 24px"
            />
          )}
        </>
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleFormSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={translations?.send_request}
          disabled={isSubmitting}
          additionalColors={additionalButtonColors}
        />
      </ButtonStyled>
      {!isRuRegion && (
        <CheckboxControlComponent
          name="privacy"
          checked={values.privacy}
          onChange={handleChange}
          children={privacyLabel}
          disabled={isSubmitting}
          margin="12px 0 0 !important"
          hasError={hasPrivacyError}
        />
      )}
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
