import { setSecureHeadersProps } from './auth.service';
import {
  AppService,
  PersonalModel,
  personalPath,
  projectSubDomains,
  urlService,
} from '@lerna-core';
import { pageUrls } from '../constants';
import { GetServerSidePropsContext, NextPageContext } from 'next';

class RedirectService {
  private static instance: RedirectService;
  private readonly isClientSide = AppService.isClientSide;

  public static getInstance(): RedirectService {
    if (!RedirectService.instance) {
      RedirectService.instance = new RedirectService();
    }

    return RedirectService.instance;
  }

  private readonly coursesUrl = urlService.getFullUrlToPageWithQuery(
    pageUrls.courses
  );
  private readonly personalUrl = urlService.getFullUrlToSubDomain(
    projectSubDomains.personal,
    personalPath.register
  );

  public redirectToPage(
    path: string,
    ctx?: NextPageContext | GetServerSidePropsContext
  ): void {
    if (this.isClientSide) {
      window.location.href = path;
    } else if (ctx) {
      this.serverRedirectToPath(ctx, path);
    }
  }

  public redirectToUserAccessRights(
    personal?: PersonalModel,
    redirectUrl?: string,
    ctx?: NextPageContext | GetServerSidePropsContext
  ): void {
    debugger;
    if (!!personal) {
      if (!personal.isFulfilled) {
        if (redirectUrl) {
          const url = `${this.personalUrl}?redirectUrl=${encodeURIComponent(
            redirectUrl
          )}`;
          return this.redirectToPage(url, ctx);
        } else {
          return this.redirectToPage(this.personalUrl, ctx);
        }
      }

      if (redirectUrl) {
        return this.redirectToPage(decodeURIComponent(redirectUrl), ctx);
      }

      return this.redirectToPage(this.coursesUrl, ctx);
    }
  }

  public serverRedirectToPath(
    ctx: NextPageContext | GetServerSidePropsContext,
    path: string
  ): void {
    ctx?.res?.writeHead(301, {
      Location: path,
      ...setSecureHeadersProps(),
    });

    ctx?.res?.end();
  }
}

export const redirectService = RedirectService.getInstance();
