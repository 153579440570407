import { ITheme } from '../../features';
import { rgba } from 'polished';

export enum InputColors {
  white = 'white',
  grey = 'grey',
}

export type InputColor = InputColors.white | InputColors.grey;

type InputColorProps = {
  disabled?: boolean;
  bordered?: boolean;
  color?: InputColor;
  theme: ITheme;
  hasError?: boolean;
};

const getWhiteDisabledStyles = (theme: ITheme): string => `
  color: ${rgba(theme.COLOR.TEXT_LIGHT_COLOR, 0.8)};
  pointer-events: none;
`;

const getWhiteBorderedStyles = (theme: ITheme, hasError?: boolean): string => {
  const borderColor = hasError
    ? theme.COLOR.INFO_RED_COLOR
    : rgba(theme.COLOR.BLACK, 0.12);

  const borderHoverColor = hasError
    ? theme.COLOR.INFO_RED_COLOR
    : theme.COLOR.TEXT_LIGHT_COLOR;

  return `
    border-color: ${borderColor};

    :hover,
    :focus,
    :focus-within {
      border-color: ${borderHoverColor};
    }
  `;
};

export const getInputColor = ({
  bordered,
  disabled,
  color,
  theme,
  hasError,
}: InputColorProps): string => {
  switch (color) {
    case InputColors.white:
      return `
          background-color: ${theme.COLOR.WHITE};
          ${disabled ? getWhiteDisabledStyles(theme) : ''}
          ${bordered ? getWhiteBorderedStyles(theme, hasError) : ''}
      `;
    default:
    case InputColors.grey:
      if (disabled) {
        return `
          background-color: rgba(0,0,0, .12); 
          ${bordered ? 'border-color: rgba(0,0,0, .04);' : ''}
      `;
      }

      return `
        background-color: rgba(0,0,0, .04); 
        ${bordered ? 'border-color: rgba(0,0,0, .08);' : ''}
      `;
  }
};

export const input = ({
  bordered,
  disabled,
  theme,
  color,
  hasError,
}: InputColorProps): string => `
  -webkit-appearance: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  border: 1px solid transparent;
  color: ${theme.COLOR.TEXT_DARK_COLOR};
  transition: border-color 0.15s ease-in-out;
  ${getInputColor({ bordered, disabled, color, theme, hasError })}

  ::placeholder {
     color: ${theme.COLOR.TEXT_LIGHT_COLOR};
  }
`;
