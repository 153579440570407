import * as constants from './popups.constants';

export type HandleOpenPopup = {
  type: string;
  popupSlug: string;
  target?: string;
};

export type HandleClosePopup = {
  type: string;
  popupSlug: string;
};

export type PopupsActions = HandleOpenPopup & HandleClosePopup;

/**
 * Redux event for handling open/close filters
 * @return {HandleOpenPopup} - Redux event
 * @param popupSlug
 * @param target
 */
export const handleOpenPopup = (
  popupSlug: string,
  target?: string
): HandleOpenPopup => {
  return {
    type: constants.TRIGGER_OPEN_POPUP,
    popupSlug,
    target,
  };
};

/**
 * Redux event for handling open/close filters
 * @return {HandleOpenPopup} - Redux event
 * @param popupSlug
 */
export const handleClosePopup = (popupSlug: string): HandleClosePopup => {
  return {
    type: constants.TRIGGER_CLOSE_POPUP,
    popupSlug,
  };
};
