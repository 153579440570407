import React, { ReactElement, useMemo } from 'react';
import { PriceTitleContainerStyled } from './priceTitle.styled';
import { PriceTitleContainerProps } from './priceTitle.model';
import { useShowLegalEntitiesPriceHook } from '@hooks';

export const PriceTitleContainer = ({
  price,
  hasDiscount,
  translations,
  isFreeCourse,
  isFreeCourseByDiscount,
}: PriceTitleContainerProps): ReactElement => {
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const lineThrough =
    (hasDiscount && !showLegalEntitiesPrice && !isFreeCourse) ||
    (isFreeCourseByDiscount && !showLegalEntitiesPrice);

  const title = useMemo(() => {
    if (showLegalEntitiesPrice) {
      return translations.legal_entities_price_label;
    }

    if (!hasDiscount || isFreeCourse) {
      return translations.item_price_label;
    }

    return price?.startPrice?.amount || '';
  }, [showLegalEntitiesPrice]);

  return (
    <PriceTitleContainerStyled lineThrough={lineThrough}>
      {title}
    </PriceTitleContainerStyled>
  );
};
