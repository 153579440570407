import { ParsedUrlQuery } from 'querystring';
import { UTM, UTMMedium, UTMModel, UTMNone, UTMSource } from './utm.model';
import { objectService } from '../objectService';
import { cookieService } from '../cookie';
import { logger } from '../logger/logger.service';
import { Nullable } from '../../models';
import { publicConfig } from '../config/config.model';

export const UTM_COOKIE_NAME = 'utm';

class UTMService {
  private static instance: UTMService;
  private readonly utmPrefix = 'utm_';

  public static getInstance(): UTMService {
    if (!UTMService.instance) {
      UTMService.instance = new UTMService();
    }

    return UTMService.instance;
  }

  protected getUTMMedium(utm: Nullable<UTMModel>, referrer?: string): string {
    switch (true) {
      case !!utm?.[UTM.medium]:
        return utm?.[UTM.medium] as string;
      case !referrer:
      case referrer?.indexOf(publicConfig.BASE_DOMAIN) !== -1:
        return UTMNone;
      case referrer?.indexOf(UTMSource.google) !== -1:
        return UTMMedium.organic;
      case !!referrer:
        return UTMMedium.referral;
      default:
        return UTMNone;
    }
  }

  protected getUTMSource(utm: Nullable<UTMModel>, referrer?: string): string {
    if (utm) {
      if (utm?.[UTM.source]) {
        return utm[UTM.source] as string;
      } else {
        return UTMNone;
      }
    } else {
      switch (true) {
        case !referrer:
        case referrer?.indexOf(publicConfig.BASE_DOMAIN) !== -1:
          return UTMSource.direct;
        case referrer?.indexOf(UTMSource.google) !== -1:
          return UTMSource.google;
        case referrer?.indexOf(UTMSource.yandex) !== -1:
          return UTMSource.yandex;
        default:
          return (
            referrer?.replace(/^https?:\/\//, '')?.replace('/', '') || UTMNone
          );
      }
    }
  }

  public mapUTM(utm: Nullable<UTMModel>, referrer?: string): UTMModel {
    return {
      [UTM.source]: this.getUTMSource(utm, referrer),
      [UTM.medium]: this.getUTMMedium(utm, referrer),
      [UTM.campaign]: utm?.[UTM.campaign] || UTMNone,
      [UTM.content]: utm?.[UTM.content] || UTMNone,
      [UTM.term]: utm?.[UTM.term] || UTMNone,
    };
  }

  /**
   * Get utm-data from query
   * @param utmData
   * @param withUtmPrefix
   * */
  public getUTMFromQuery(
    query: ParsedUrlQuery,
    withUtmPrefix = true
  ): Nullable<UTMModel> {
    const utmData: UTMModel = {};

    for (const key of Object.keys(query)) {
      if (key.includes(this.utmPrefix) && !!query[key]) {
        const newKey = withUtmPrefix ? key : key.replace(this.utmPrefix, '');
        utmData[newKey] = query[key];
      }
    }

    return Object.keys(utmData).length > 0 ? utmData : null;
  }

  public getUTMFromCookie(cookie?: string): Nullable<UTMModel> {
    const utmCookie = cookieService.getCookie(UTM_COOKIE_NAME, cookie);

    if (utmCookie) {
      return JSON.parse(decodeURIComponent(utmCookie)) as UTMModel;
    }

    return null;
  }

  /**
   * Comparison of two objects with utm-data
   * @return {boolean}
   * @param utmData1 - old utm-data
   * @param utmData2 - new utm-data
   * */
  public compareUTM(utmData1: UTMModel, utmData2: UTMModel): boolean {
    return objectService.deepEqual(utmData1, utmData2);
  }

  /**
   * Get url query string from cookie
   * @return {string}
   * */
  public getQueryStringFromCookie(cookie?: string): string | undefined {
    const utmCookie = this.getUTMFromCookie(cookie);
    if (!!utmCookie) {
      try {
        return new URLSearchParams(
          utmCookie as unknown as URLSearchParams
        ).toString();
      } catch (e) {
        logger.error('[ERROR]: invalid utm cookie value');
      }
    }

    return;
  }

  /**
   * Get url utm-query string from object
   * @return {string}
   * */
  public getUtmQueryStringFromObject(
    obj: ParsedUrlQuery,
    withUtmPrefix = true
  ): string {
    const objWithUtmPrefix = Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [`${withUtmPrefix ? this.utmPrefix : ''}${key}`]: obj[key],
      };
    }, {});

    return objectService.getQueryStringFromObject(objWithUtmPrefix);
  }
}

export const utmService = UTMService.getInstance();
