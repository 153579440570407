import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import {
  ButtonColor,
  ButtonContainer,
  CommonEventPositions,
  CommonEventTypes,
  handleOpenPopup,
  Nullable,
  PopupsEnum,
} from '@lerna-core';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { useCoursePageContext } from '../course.context';
import { CourseFormButtonContainerStyled } from './courseFormButton.styled';

export const CourseFormButtonContainer = (): Nullable<ReactElement> => {
  const { translations, user, course } = useCoursePageContext();

  const dispatch = useDispatch();

  const handleOpenForm = (): void => {
    if (user) {
      dispatch(handleOpenPopup(PopupsEnum.userOrderFormPopup));
    } else {
      dispatch(handleOpenPopup(PopupsEnum.guestOrderFormPopup));
    }

    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClick,
      CommonEventTypes.coursesEvents,
      CommonEventPositions.coursePagePrice,
      course
    );
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollOpen,
      CommonEventTypes.coursesEvents,
      CommonEventPositions.coursePagePrice,
      course
    );
  };

  return (
    <CourseFormButtonContainerStyled>
      <ButtonContainer
        onClick={handleOpenForm}
        color={ButtonColor.purple}
        borderRadius={8}
        title={translations?.sign_up_course_button_title}
      />
    </CourseFormButtonContainerStyled>
  );
};
