import React, { ReactElement } from 'react';
import {
  FilterTypes,
  FilterRangeModel,
  FilterTypeEnum,
  FilterTypeItemProps,
  Nullable,
} from '@lerna-core';
import { FilterRangeContainer } from './filterRange';
import { FilterOptionsContainer } from './filterOptions';
import { FilterTabsContainer } from './filterTabs';

export const FilterTypeItem = ({
  filter,
  withTitle = false,
  withSearch = true,
  isCollapsed = false,
  withTooltip = true,
  translations,
  defaultPathname,
  searchColors,
  rangeInputSize,
  withDescription,
  icon,
  filterColor,
  analyticsType = FilterTypes.main,
}: FilterTypeItemProps): Nullable<ReactElement> => {
  switch (filter.type) {
    case FilterTypeEnum.range: {
      return (
        <FilterRangeContainer
          filter={filter as FilterRangeModel}
          translations={translations}
          defaultPathname={defaultPathname}
          withTitle={withTitle}
          size={rangeInputSize}
          analyticsType={analyticsType}
        />
      );
    }
    case FilterTypeEnum.toggle:
    case FilterTypeEnum.checkbox: {
      return (
        <FilterOptionsContainer
          filter={filter}
          withTitle={withTitle}
          withSearch={withSearch}
          withDescription={withDescription}
          isCollapsed={isCollapsed}
          withTooltip={withTooltip}
          translations={translations}
          defaultPathname={defaultPathname}
          searchColors={searchColors}
          icon={icon}
          filterColor={filterColor}
          analyticsType={analyticsType}
        />
      );
    }
    case FilterTypeEnum.tab: {
      return (
        <FilterTabsContainer
          filter={filter}
          withTitle={withTitle}
          translations={translations}
          defaultPathname={defaultPathname}
        />
      );
    }

    default:
      return null;
  }
};
