import {
  IconAnalyticsDatascienceComponent,
  IconAnotherComponent,
  IconBusinessManagementComponent,
  IconCodeComponent,
  IconDigitalDesignComponent,
  IconEnglishComponent,
  IconEnvironmentDesignComponent,
  IconFashionPhotographyComponent,
  IconFinanceAccountingComponent,
  IconForChildrenComponent,
  IconGamedevComponent,
  IconMarketingComponent,
  IconMediaComponent,
  IconProjectProductComponent,
  IconSelfDevelopmentComponent,
  IconSoftSkillsComponent,
  IconSportComponent,
  IconStaffManagementComponent,
  IconTeamManagementComponent,
} from './tabsIcons';
import { FilterTabsIconsModel } from './filtersTabs.model';

export const getFilterTabsIcons = (
  iconColor?: string
): FilterTabsIconsModel => ({
  english: <IconEnglishComponent fillColor={iconColor} />,
  another: <IconAnotherComponent fillColor={iconColor} />,
  gamedev: <IconGamedevComponent fillColor={iconColor} />,
  marketing: <IconMarketingComponent fillColor={iconColor} />,
  media: <IconMediaComponent fillColor={iconColor} />,
  code: <IconCodeComponent fillColor={iconColor} />,
  sport: <IconSportComponent fillColor={iconColor} />,
  'soft-skills': <IconSoftSkillsComponent fillColor={iconColor} />,
  'analytics-datascience': (
    <IconAnalyticsDatascienceComponent fillColor={iconColor} />
  ),
  'environment-design': (
    <IconEnvironmentDesignComponent fillColor={iconColor} />
  ),
  'for-children': <IconForChildrenComponent fillColor={iconColor} />,
  'fashion-photography': (
    <IconFashionPhotographyComponent fillColor={iconColor} />
  ),
  'project-product': <IconProjectProductComponent fillColor={iconColor} />,
  'self-development': <IconSelfDevelopmentComponent fillColor={iconColor} />,
  'team-management': <IconTeamManagementComponent fillColor={iconColor} />,
  'staff-management': <IconStaffManagementComponent fillColor={iconColor} />,
  'finance-accounting': (
    <IconFinanceAccountingComponent fillColor={iconColor} />
  ),
  'digital-design': <IconDigitalDesignComponent fillColor={iconColor} />,
  'business-management': (
    <IconBusinessManagementComponent fillColor={iconColor} />
  ),
});
