import {
  Company,
  DiscountProgramColorsModel,
  Nullable,
  TranslationModel,
} from '@lerna-core';
import { ReactElement } from 'react';

export enum HeaderLinkTypes {
  switcher = 'switcher',
  b2bLanding = 'b2bLanding',
  catalog = 'catalog',
  none = 'none',
}

export enum HeaderColorTypes {
  white = 'white',
  default = 'default',
}

export type HeaderContainerProps = {
  showSearch: boolean;
  showFilters: boolean;
  hasSticky: boolean;
  translations: TranslationModel;
  company?: Nullable<Company>;
  handleSticky?: (stickyStatus: boolean) => void;
  handleOpenContactUs?: () => void;
  handleErrorContactUs?: () => void;
  handleSuccessContactUs?: () => void;
  handleCloseContactUs?: () => void;
  alwaysShowSearch?: boolean;
  headerLinkType?: HeaderLinkTypes;
  customerCoursesCount?: number;
  withMobileMenu?: boolean;
  withLogin?: boolean;
  useLoginText?: boolean;
  discountProgramLogo?: Nullable<ReactElement>;
  discountProgramColors?: DiscountProgramColorsModel;
};
