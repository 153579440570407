import React, { ReactElement } from 'react';
import {
  CourseItemButtonStyled,
  CourseItemComponentProps,
  CourseItemDescriptionStyled,
  CourseItemMobileContainerStyled,
  CourseItemMobileContentStyled,
  CourseItemMobileInfoStyled,
  CourseItemMobileLogoStyled,
  CourseItemMobileSubTitleStyled,
  CourseItemProgressComponent,
  CourseItemTitleStyled,
} from '../index';
import { ImageContainer } from '../../../image';
import { VendorEnum } from '../../../vendors';

export const CourseItemMobileComponent = ({
  course,
  button,
  className,
}: CourseItemComponentProps): ReactElement => {
  const courseItemImageResource =
    course?.logo || course?.vendor?.coursePlug?.imageResource;

  return (
    <CourseItemMobileContainerStyled className={className}>
      <CourseItemMobileContentStyled>
        <CourseItemMobileInfoStyled>
          <CourseItemTitleStyled>{course.title}</CourseItemTitleStyled>
          <CourseItemDescriptionStyled>
            {course?.vendor && (
              <CourseItemMobileSubTitleStyled>
                {course.vendor.title}
              </CourseItemMobileSubTitleStyled>
            )}
            {course?.studyType ? (
              <CourseItemMobileSubTitleStyled>
                {course.studyType.toLowerCase()}
              </CourseItemMobileSubTitleStyled>
            ) : null}
          </CourseItemDescriptionStyled>
        </CourseItemMobileInfoStyled>

        {courseItemImageResource && (
          <CourseItemMobileLogoStyled>
            <ImageContainer
              imageResource={courseItemImageResource}
              //TODO: think about naming presets
              preset={'logoCourseSmall'}
            />
          </CourseItemMobileLogoStyled>
        )}
      </CourseItemMobileContentStyled>

      <CourseItemProgressComponent
        vendorSlug={course?.vendor?.slug as VendorEnum}
        progress={course?.progress}
      />
      {!!button && (
        <CourseItemButtonStyled isMobile={true}>
          {button}
        </CourseItemButtonStyled>
      )}
    </CourseItemMobileContainerStyled>
  );
};
