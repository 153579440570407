import {
  mapEducationStreamsAdapter,
  mapImageResource,
  PersonalApiModel,
  PersonalModel,
} from '../features';
import { dateService } from '../services';

export const mapPersonal = (personalData: PersonalApiModel): PersonalModel => ({
  lastName: personalData.last_name || '',
  firstName: personalData.first_name || '',
  patronymic: personalData.patronymic,
  avatar: personalData.avatar ? mapImageResource(personalData.avatar) : null,
  email: personalData.email,
  anonymousEmail: personalData.anonymous_email,
  uuid: personalData.uuid,
  phone: personalData.phone,
  isFulfilled: Boolean(personalData.is_fulfilled),
  isDisabled: personalData.is_disabled,
  lastActivityAt: personalData.last_activity_at
    ? dateService.formatDate(personalData.last_activity_at)
    : '',
  registeredAt: personalData.registered_at
    ? dateService.formatDate(personalData.registered_at)
    : null,
  educationStreams: personalData.education_streams
    ? mapEducationStreamsAdapter(personalData.education_streams)
    : null,
});
