import { ApiImage } from '../image';
import { ImageModel, Nullable } from '../../models';
import { AxiosError } from 'axios';

export type ApiVendorModel = {
  slug: string;
  title: string;
  uuid: string;
  logo: Nullable<ApiImage>;
  course_plug?: Nullable<ApiImage>;
};

export type VendorModel = {
  slug: string;
  title: string;
  uuid: string;
  logo: Nullable<ImageModel>;
  coursePlug?: Nullable<ImageModel>;
};

export enum VendorEnum {
  skillbox = 'skillbox',
  skillboxEng = 'skillbox-eng',
  geekbrains = 'geekbrains',
  skillfactory = 'skillfactory',
  contented = 'contented',
  uup = 'uup',
}

export enum VendorTitleEnum {
  skillbox = 'Skillbox',
  geekbrains = 'GeekBrains',
  skillfactory = 'SkillFactory',
  contented = 'Contented',
}

export type VendorApiResponseModel = {
  data: {
    data: ApiVendorModel[];
  };
};

export type VendorsActions = {
  type: string;
  payload?: VendorModel[] | AxiosError;
};

export type VendorsStatusModel = {
  isLoading: boolean;
  isLoaded: boolean;
};

export type VendorsState = {
  vendors: VendorModel[];
  status: VendorsStatusModel;
};
