import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  ColumnInstance,
  useFlexLayout,
  useRowSelect,
  UseRowSelectRowProps,
  useTable,
} from 'react-table';
import { CustomersTableProps } from './transferTable.model';
import { getOriginalSelectedRows } from './transferTable.service';
import {
  CustomersTableBoxStyled,
  CustomersTableEmptyCheckAll,
} from './transferTable.styled';
import {
  getSelectionCell,
  getSelectionColumnOptions,
  getSelectionHeader,
  TableContainer,
  TableEndlessLoaderContainer,
  TableLoaderContainer,
  useDisabledRowsHook,
} from '../../../table';
import { getIsRowDisabled } from './transferTable.helper';
import { useSelector } from 'react-redux';
import { getCustomersTransferSelector } from '../customersTransfer.selectors';
import { LocationType } from '../../../transfer';

export const TransferTableContainer = ({
  selectionColWidth,
  selectionColMinWidth,
  defaultColMinWidth = 100,
  defaultColWidth = 200,
  tableData,
  handleCheckAll,
  handleCheck,
  isRadioButton,
  getSelectedRows,
  handlePagination,
  isLoading,
  isPaginating,
  isSelectedAll,
  meta,
  headerComponent,
  emptyContent,
  locationType,
}: CustomersTableProps): ReactElement => {
  const {
    added,
    removed,
    unallocated: { filters: unallocatedFilters },
    allocated: { filters: allocatedFilters },
  } = useSelector(getCustomersTransferSelector);

  const hasNextPage = meta.currentPage < meta.totalPages;
  const defaultColumn = {
    minWidth: defaultColMinWidth,
    width: defaultColWidth,
  };
  const autoResetSelectedRows = React.useRef<boolean>(false);
  const columns = useMemo(() => tableData.columns || [], []);
  const data = useMemo(() => {
    autoResetSelectedRows.current = false;

    return tableData.rows;
  }, [tableData.rows]);

  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetSelectedRows: autoResetSelectedRows.current,
    },
    useFlexLayout,
    useRowSelect,
    useDisabledRowsHook(getIsRowDisabled),
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const selectionColumn = {
          id: 'selection',
          ...getSelectionCell(handleCheck, isRadioButton),
          ...getSelectionColumnOptions({
            selectionColWidth,
            selectionColMinWidth,
          }),
        } as unknown as ColumnInstance<Record<string, unknown>>;

        const cols = [selectionColumn, ...columns];

        if (typeof handleCheckAll === 'function' && !isRadioButton) {
          cols[0] = { ...cols[0], ...getSelectionHeader(handleCheckAll) };
        } else {
          cols[0] = {
            ...cols[0],
            Header: <CustomersTableEmptyCheckAll></CustomersTableEmptyCheckAll>,
          };
        }

        return cols;
      });
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  const handleRowClick = (
    row: UseRowSelectRowProps<Record<string, unknown>>
  ): void => {
    if (isRadioButton) {
      table.toggleAllRowsSelected(false);
    }
    row.toggleRowSelected();
  };

  useEffect(() => {
    autoResetSelectedRows.current = true;
  });

  useEffect(() => {
    if (isSelectedAll) {
      table.toggleAllRowsSelected(true);
    }
  }, [isSelectedAll]);

  useEffect(() => {
    if (locationType === LocationType.unallocated) {
      table.toggleAllRowsSelected(false);
    }
  }, [added, removed, unallocatedFilters]);

  useEffect(() => {
    if (locationType === LocationType.allocated) {
      table.toggleAllRowsSelected(false);
    }
  }, [added, removed, allocatedFilters]);

  useEffect(() => {
    if (getSelectedRows) {
      getSelectedRows(getOriginalSelectedRows(table.selectedFlatRows));
    }
  }, [table.selectedFlatRows]);

  return (
    <CustomersTableBoxStyled>
      <TableContainer
        isLoading={isLoading}
        isPaginating={isPaginating}
        hasNextPage={hasNextPage}
        handleRowClick={handleRowClick}
        hasEndlessPagination
        handlePagination={handlePagination}
        headerComponent={headerComponent}
        isStickyHeader
        table={table}
        height={594}
        paginationLoader={<TableEndlessLoaderContainer />}
        loader={<TableLoaderContainer />}
        emptyContent={emptyContent}
      />
    </CustomersTableBoxStyled>
  );
};
