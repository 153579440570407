import { CoursePricesModel } from './coursePrices';
import { stringService } from '../../services';
import { vendorAdapter, VendorModel } from '../vendors';
import {
  ApiCourseModel,
  CourseModel,
  CourseStatus,
  CourseTypes,
} from './course.model';
import { Nullable, TranslationModel } from '../../models';
import { CourseDurationModel } from './courseDurations';

export const isFreeCourse = (price: CoursePricesModel): boolean =>
  !Boolean(parseInt(price.startPrice.amount));

export const isFreeCourseByDiscount = (price: CoursePricesModel): boolean => {
  const startPrice = parseInt(price.startPrice.amount);
  const finalPrice = parseInt(price.price.amount);

  if (price?.priceCustomer) {
    const B2BCompanyCompensation =
      price?.priceCustomer?.compensation?.discount?.value;
    const B2BFinalPrice = parseInt(price.priceCustomer.amount);

    if (B2BCompanyCompensation && parseInt(B2BCompanyCompensation) >= 100) {
      return false;
    }

    return Boolean(startPrice) && !Boolean(B2BFinalPrice);
  }

  return Boolean(startPrice) && !Boolean(finalPrice);
};

export const isFreeCourseByVendor = (price: CoursePricesModel): boolean => {
  if (!price?.priceWithVendorDiscount) return false;

  const startPrice = parseInt(price.startPrice.amount);
  const priceWithVendorDiscount = parseInt(
    price.priceWithVendorDiscount.amount
  );

  return !Boolean(startPrice) && !Boolean(priceWithVendorDiscount);
};

export const isFreeCourseByVendorDiscount = (
  price: CoursePricesModel
): boolean => {
  if (!price?.priceWithVendorDiscount) return false;

  const startPrice = parseInt(price.startPrice.amount);
  const priceWithVendorDiscount = parseInt(
    price.priceWithVendorDiscount.amount
  );

  return Boolean(startPrice) && !Boolean(priceWithVendorDiscount);
};

export const hasDiscount = (price: CoursePricesModel): boolean => {
  if (!!price.priceCustomer) {
    return (
      (!!price.priceCustomer?.discount?.value &&
        parseInt(price.priceCustomer?.discount?.value) > 0) ||
      (!!price.priceCustomer?.discountBonuses &&
        parseInt(price.priceCustomer?.discountBonuses.value) > 0)
    );
  }

  return (
    (!!price.price?.discount?.value &&
      parseInt(price.price?.discount?.value) > 0) ||
    (!!price.priceWithBonuses?.discount &&
      parseInt(price?.priceWithBonuses?.discount?.value) > 0)
  );
};

export const getCourseVendorById = (
  vendors: VendorModel[],
  vendorId: string
): Nullable<VendorModel> =>
  vendors.find((vendor) => vendor.uuid === vendorId) || null;

export const getCourseVendor = (
  apiCourse: ApiCourseModel,
  vendors: VendorModel[]
): VendorModel | null => {
  if (apiCourse.vendor?.data) {
    return vendorAdapter(apiCourse.vendor.data);
  } else if (apiCourse.vendor_uuid) {
    return getCourseVendorById(vendors, apiCourse.vendor_uuid);
  } else {
    return null;
  }
};

/**
 * Get duration string from course durations array
 * @return {string}
 * @param durations[]
 * */
export const getDurationsString = (
  durations: CourseDurationModel[] = []
): string => {
  const durationsArr: string[] = [];

  durations.forEach((duration) => {
    durationsArr.push(duration.amount);
    durationsArr.push(duration.unit);
  });

  return durationsArr.join(' ');
};

/**
 * Get course type string from course type
 * @return {string}
 * @param type
 * @param translations
 * */
export const getCourseTypeString = (
  type: Nullable<CourseTypes>,
  translations: TranslationModel
): string => {
  switch (type) {
    case CourseTypes.profession:
      return translations.profession;
    case CourseTypes.faculty:
      return translations.faculty;
    case CourseTypes.geekschool:
      return translations.geekschool_course_type;
    case CourseTypes.granule:
      return translations.mini_course;
    case CourseTypes.specialized:
      return translations.specialized_course;
    case CourseTypes.advanced:
      return translations.advanced_course;
    case CourseTypes.client:
      return `${translations.course} 3.0`;
    case CourseTypes.package:
      return translations.package_course;
    case CourseTypes.subscription:
      return translations.subscription;
    default:
      return translations.course;
  }
};

export const getCoursesCount = (
  count: number,
  translations: TranslationModel
): string =>
  stringService.pluralTranslations(count, [
    translations?.courses_count_label_1,
    translations?.courses_count_label_2,
    translations?.courses_count_label_many,
  ]);

export const isCourseHasNeededStatus = (
  status: CourseStatus,
  neededStatus: CourseStatus = CourseStatus.active
): boolean => Boolean(status === neededStatus);

export const clearVendor = <Course>(course: Course): Course => ({
  ...course,
  vendor: null,
});

export const clearAcademikaCourseVendor = (course: CourseModel): CourseModel =>
  course.type === CourseTypes.academika ? clearVendor(course) : course;

export const clearAcademikaCoursesVendor = (
  courses: CourseModel[]
): CourseModel[] => courses.map(clearAcademikaCourseVendor);
