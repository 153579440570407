import { CourseItemProgressItemModel } from './courseItemProgressItem';
import { Nullable, TranslationModel } from '../../../../models';
import { CourseItemProgressModel } from '../courseItem.model';
import { VendorEnum } from '../../../vendors';

export const getProgressItems = (
  vendorSlug: Nullable<VendorEnum>,
  progress: CourseItemProgressModel,
  translations: TranslationModel
): CourseItemProgressItemModel[] => {
  switch (vendorSlug) {
    case VendorEnum.skillfactory:
    case VendorEnum.geekbrains:
      return [
        {
          title: translations?.progress_lessons,
          count: progress.totalCount,
          countDone: progress.totalDoneCount,
        },
      ];
    default:
      return [
        {
          title: translations?.progress_modules,
          count: progress.topicsCount,
          countDone: progress.topicsDoneCount,
        },
        {
          title: translations?.progress_videos,
          count: progress.videosCount,
          countDone: progress.videosDoneCount,
        },
        {
          title: translations?.progress_practical_works,
          count: progress.homeworksCount,
          countDone: progress.homeworksDoneCount,
        },
        {
          title: translations?.progress_tests,
          count: progress.testsCount,
          countDone: progress.testsDoneCount,
        },
        {
          title: translations?.progress_final_works,
          count: progress.diplomasCount,
          countDone: progress.diplomasDoneCount,
        },
      ];
  }
};
