import { useCallback, useEffect } from 'react';
import {
  CommonEventPositions,
  CommonEventTypes,
  getSimpleEvent,
  pushDataLayer,
  sessionStorageService,
  useToggle,
  useUser,
} from '@lerna-core';
import { CoursesCompensationHintHookModel } from './coursesCompensationHint.model';
import { COMPENSATION_HINT_SESSION_STORAGE_KEY } from './coursesCompensationHint.constants';
import { CompensationsEvents } from './coursesCompensationHint.analytics';

export const useCoursesCompensationHint =
  (): CoursesCompensationHintHookModel => {
    const { user } = useUser();
    const [isHintHidden, toggleIsHintHidden, setIsHintHidden] = useToggle(true);

    const showHint =
      !isHintHidden &&
      !!user?.career?.companyId &&
      !!user?.career?.isCustomCompensationAvailable;

    const handleHintHide = useCallback((): void => {
      toggleIsHintHidden();
      pushDataLayer(
        getSimpleEvent(
          CompensationsEvents.closeInfoAboutAddCompensation,
          CommonEventTypes.overallEvents,
          CommonEventPositions.coursesPageList
        )
      );
      sessionStorageService.setValue(
        COMPENSATION_HINT_SESSION_STORAGE_KEY,
        true
      );
    }, []);
    useEffect(() => {
      const sessionStorageHintIsHidden = sessionStorageService.getValue(
        COMPENSATION_HINT_SESSION_STORAGE_KEY
      );

      if (
        !sessionStorageHintIsHidden &&
        !!user?.career?.companyId &&
        !!user?.career?.isCustomCompensationAvailable
      ) {
        pushDataLayer(
          getSimpleEvent(
            CompensationsEvents.showInfoAboutAddCompensation,
            CommonEventTypes.overallEvents,
            CommonEventPositions.coursesPageList
          )
        );
      }

      setIsHintHidden(!!sessionStorageHintIsHidden);
    }, []);

    return {
      showHint,
      handleHintHide,
    };
  };
