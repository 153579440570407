import {
  browserService,
  ButtonAdditionalColors,
  ButtonColor,
  ButtonSize,
  DeviceInfoContext,
  IconInfoWithoutBgComponent,
  useTheme,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext } from 'react';
import { useCoursesPageContext } from '../../../coursesPage';
import { GoToCourseEvent } from '../listItem.analytics';
import { ListItemButtonsEvent } from './listItemButtons.analytics';
import { ListItemButtonsContainerProps } from './listItemButtons.model';
import {
  ListItemAboutButtonStyled,
  ListItemButtonsContainerStyled,
  ListItemSignUpButtonStyled,
} from './listItemButtons.styled';

export const ListItemButtonsContainer = ({
  isActive,
  isGranuleCourse,
  handleOpenSingUpCoursePopup,
  handleGoToCourse,
  courseHref,
  useHref = true,
  hrefTarget,
  discountProgramColors,
}: ListItemButtonsContainerProps): ReactElement => {
  const theme = useTheme();
  const router = useRouter();
  const coursesContext = useCoursesPageContext();
  const translations = coursesContext?.translations;
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  const buttonSize = isMobile ? ButtonSize.small : undefined;
  const additionalColors = discountProgramColors
    ? ({
        color: discountProgramColors.text,
        backgroundColor: discountProgramColors.background,
      } as ButtonAdditionalColors)
    : undefined;

  const signUpButtonTitle = isGranuleCourse
    ? translations?.list_item_about_button_title
    : translations?.list_item_sign_up_button_title;

  const showAboutButton = courseHref && isActive;
  const aboutButtonTitle = !isMobile
    ? translations?.list_item_about_button_title
    : undefined;
  const aboutButtonChildren = isMobile ? (
    <IconInfoWithoutBgComponent fillColor={theme.COLOR.TEXT_DARK_COLOR} />
  ) : undefined;

  const handleClickSignUp = (): void => {
    handleOpenSingUpCoursePopup && handleOpenSingUpCoursePopup();
  };
  const handleClickMore = (): void => {
    if (handleGoToCourse) {
      const eventName = courseHref
        ? GoToCourseEvent.goToCourseButton
        : ListItemButtonsEvent.buttonMore;

      handleGoToCourse(eventName);
    }
    if (!useHref && courseHref) {
      if (hrefTarget === '_blank') {
        window.open(courseHref, '_blank');
      } else {
        router.push(courseHref);
      }
    }
  };

  return (
    <ListItemButtonsContainerStyled
      data-onboarding-id="iframe-course-buttons"
      isMobile={isMobile}
    >
      {showAboutButton && (
        <ListItemAboutButtonStyled
          onClick={handleClickMore}
          color={ButtonColor.transparent}
          title={aboutButtonTitle}
          size={buttonSize}
          children={aboutButtonChildren}
          href={useHref ? courseHref : undefined}
        />
      )}
      <ListItemSignUpButtonStyled
        onClick={handleClickSignUp}
        color={ButtonColor.purple}
        title={signUpButtonTitle}
        data-instance="sign-up"
        size={buttonSize}
        additionalColors={additionalColors}
      />
    </ListItemButtonsContainerStyled>
  );
};
