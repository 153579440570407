import { VendorModel, VendorsActions, VendorsState } from './vendors.model';
import {
  REQUEST_VENDORS_ERROR,
  REQUEST_VENDORS_START,
  REQUEST_VENDORS_SUCCESS,
} from './vendors.constants';

const initialState: VendorsState = {
  vendors: [],
  status: {
    isLoading: false,
    isLoaded: false,
  },
};

export const vendorsReducer = (
  state: VendorsState = initialState,
  action: VendorsActions
): VendorsState => {
  switch (action.type) {
    case REQUEST_VENDORS_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case REQUEST_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload as VendorModel[],
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    case REQUEST_VENDORS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
};
