import styled from 'styled-components';
import { fontSettings, transition } from '../../../styles';
import { FormAgreementLabelStyledProps } from './formAgreement.label.model';
import { rgba } from 'polished';

export const FormAgreementLabelStyled = styled.div<FormAgreementLabelStyledProps>`
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  color: ${({ theme, textColor }): string =>
    textColor ? textColor : theme.COLOR.TEXT_LIGHT_COLOR};
  font-weight: 400;

  ${({ hasError, theme }): string =>
    hasError ? `color: ${theme.COLOR.INFO_RED_COLOR};` : ''}
  a {
    color: inherit;
    text-decoration: none;
    ${transition('border-color')}

    ${({ hasError, theme }): string =>
      hasError
        ? `border-bottom: 1px solid ${theme.COLOR.INFO_RED_COLOR};`
        : `border-bottom: 1px solid ${rgba(theme.COLOR.BLACK, 0.2)};

      &:hover {
        border-color: ${rgba(theme.COLOR.BLACK, 0.4)};
      }
    `}
  }
`;
