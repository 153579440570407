import { makeContactUsRequest } from './contactUsForm.client';
import {
  FormPopupContainer,
  FormPopupFooterContainer,
} from '@features/common/popups';
import {
  browserService,
  ButtonAdditionalColors,
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  DeviceInfoContext,
  FormAgreementContainer,
  getCurrentPopupFromOpen,
  handleClosePopup,
  hasAgreementCheckbox,
  logger,
  PhoneControlComponent,
  PopupsEnum,
  TextControlComponent,
  useUser,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContactUsFormContainerProps,
  ContactUsFormInitialValues,
  ContactUsFormValidationSchema,
} from './contactUsForm.model';
import { FormStatusContainer } from './formStatus';
import {
  getContactUsFormInitialValues,
  getContactUsFormValidationSchema,
} from './contactUsForm.service';
import { useFormik } from 'formik';
import { TextAreaStyled } from './contactUsForm.styled';

export const ContactUsFormContainer = ({
  translations,
  handleClose,
  handleError,
  handleSuccess,
  attemptsProps,
  discountProgramColors,
}: ContactUsFormContainerProps): ReactElement => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const router = useRouter();
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const withAgreementCheckbox = hasAgreementCheckbox();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.contactUsFormPopup)
  );

  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);

  const validationSchema: ContactUsFormValidationSchema = useMemo(
    () => getContactUsFormValidationSchema(translations),
    []
  );
  const initialValues: ContactUsFormInitialValues = useMemo(
    () => getContactUsFormInitialValues(user, !withAgreementCheckbox),
    [user, withAgreementCheckbox]
  );

  const onError = (): void => {
    if (typeof handleError === 'function') {
      handleError();
    }

    if (typeof attemptsProps?.handleError === 'function') {
      attemptsProps.handleError();
    }
  };

  const onSuccess = (): void => {
    if (typeof handleSuccess === 'function') {
      handleSuccess();
    }

    if (typeof attemptsProps?.handleSuccess === 'function') {
      attemptsProps.handleSuccess();
    }
  };

  const closePopup = (isAutomatic = false): void => {
    if (!isAutomatic) {
      if (typeof handleClose === 'function') {
        handleClose();
      }
    }

    clearData();
    dispatch(handleClosePopup(PopupsEnum.contactUsFormPopup));
  };

  const closeError = (): void => {
    setIsErrorDisplayed(false);

    if (typeof attemptsProps?.handleError === 'function') {
      attemptsProps.handleError();
    }
  };

  const closeSuccess = (): void => {
    setIsSuccessDisplayed(false);

    if (typeof attemptsProps?.handleSuccess === 'function') {
      attemptsProps.handleSuccess();
    }
  };

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    setFieldValue('message', '');
    setFieldValue('privacy', !withAgreementCheckbox);
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    status,
    setFieldValue,
    setStatus,
    setErrors,
    setTouched,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      actions.setStatus({});

      await makeContactUsRequest(
        values.name,
        values.email,
        values.phone,
        values.message,
        router.locale
      )
        .then((): void => {
          onSuccess();
          closePopup(true);
          setIsSuccessDisplayed(true);
        })
        .catch((error): void => {
          onError();
          closePopup(true);
          setIsErrorDisplayed(true);
          logger.error(
            `[ERROR]: error sending request. ${error.response?.data}`
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const hasPrivacyError = Boolean(errors.privacy) && touched.privacy;
  const submitButtonSize = isMobile ? ButtonSize.small : ButtonSize.normal;
  const additionalButtonColors = discountProgramColors
    ? ({
        color: discountProgramColors.text,
        backgroundColor: discountProgramColors.background,
      } as ButtonAdditionalColors)
    : undefined;

  const submitButton = (
    <ButtonContainer
      color={ButtonColor.purple}
      onClick={handleSubmit}
      borderRadius={100}
      title={translations?.form_send_button_title}
      size={submitButtonSize}
      disabled={isSubmitting}
      additionalColors={additionalButtonColors}
    />
  );

  const privacyCheckbox = (
    <FormAgreementContainer
      name="privacy"
      translations={translations}
      checked={values.privacy}
      onChange={handleChange}
      disabled={isSubmitting}
      hasError={hasPrivacyError}
    />
  );

  const footer = <FormPopupFooterContainer button={submitButton} />;

  return (
    <>
      <FormPopupContainer
        handleClose={closePopup}
        isOpened={!!currentPopup}
        title={translations?.form_need_help_title}
        subtitle={translations?.form_need_help_description}
        footer={footer}
        data-instance="contactUs"
        privacyCheckbox={privacyCheckbox}
      >
        <TextControlComponent
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          name="name"
          label={translations?.input_name_label}
          placeholder={translations?.placeholder_name}
          disabled={isSubmitting}
          touched={touched.name}
          errorMessage={errors.name}
          statusMessage={status?.name}
          showResetButton
          setFieldValue={setFieldValue}
        />
        <TextControlComponent
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          name="email"
          label={translations?.input_email_label}
          placeholder={translations?.placeholder_email}
          disabled={isSubmitting}
          touched={touched.email}
          errorMessage={errors.email}
          statusMessage={status?.email}
          showResetButton
          setFieldValue={setFieldValue}
        />
        <PhoneControlComponent
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          name="phone"
          label={translations?.input_phone_label}
          disabled={isSubmitting}
          touched={touched.phone}
          errorMessage={errors.phone}
          statusMessage={status?.phone}
          setFieldValue={setFieldValue}
        />
        <TextAreaStyled
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          name="message"
          label={translations?.input_message_label}
          placeholder={translations?.placeholder_message_contact_us}
          disabled={isSubmitting}
          touched={touched.message}
          errorMessage={errors.message}
          statusMessage={status?.message}
        />
      </FormPopupContainer>

      <FormStatusContainer
        translations={translations}
        handleRequest={handleSubmit}
        isMobile={isMobile}
        closeError={closeError}
        closeSuccess={closeSuccess}
        isErrorDisplayed={isErrorDisplayed}
        isSuccessDisplayed={isSuccessDisplayed}
        attemptsProps={attemptsProps}
        discountProgramColors={discountProgramColors}
      />
    </>
  );
};
