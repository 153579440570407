import { useContext, useState } from 'react';
import { subscriptionClaimProvider } from '@features/common/subscriptionClaim';
import {
  browserService,
  DeviceInfoContext,
  getCurrentPopupFromOpen,
  handleClosePopup,
  logger,
  PopupsEnum,
  publicConfig,
  RegionsEnum,
} from '@lerna-core';
import { useDispatch, useSelector } from 'react-redux';
import { pushFormSubmitStatusEvent } from '../formGranule.helper';
import {
  FormGranuleContainerProps,
  GranuleFormSubmitStatusTypes,
} from '../formGranule.model';
import { FormGranuleHookModel } from './granuleFreeForm.model';

export const useFormGranule = (
  props: FormGranuleContainerProps
): FormGranuleHookModel => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const dispatch = useDispatch();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const showFormStatus = isSuccessDisplayed || isErrorDisplayed || isExistOrder;
  const isRuRegion = publicConfig?.REGION === RegionsEnum.ru;

  const handleSubmit = (): void => {
    setIsSubmitting(true);

    subscriptionClaimProvider(props.course.uuid)
      .then(onSuccess)
      .catch((error): void => {
        if (error?.response?.status === 410) {
          onExistError();
        } else {
          onError();
        }
        logger.error(`[ERROR]: error sending request. ${error.response?.data}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const clearData = (): void => {
    setIsSubmitting(false);
    setIsErrorDisplayed(false);
    setIsSuccessDisplayed(false);
    setIsExistOrder(false);
  };
  const onSuccess = (): void => {
    pushFormSubmitStatusEvent(
      GranuleFormSubmitStatusTypes.success,
      props.course,
      props.eventPosition
    );

    setIsSuccessDisplayed(true);
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      GranuleFormSubmitStatusTypes.error,
      props.course,
      props.eventPosition
    );

    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      GranuleFormSubmitStatusTypes.exist,
      props.course,
      props.eventPosition
    );

    setIsExistOrder(true);
  };

  const handlePopupClose = (): void => {
    dispatch(handleClosePopup(PopupsEnum.userOrderFormPopup));
    clearData();
  };

  return {
    ...props,
    currentPopup,
    handleSubmit,
    clearData,
    handlePopupClose,
    showFormStatus,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    isSubmitting,
    isRuRegion,
    isMobile,
  };
};
