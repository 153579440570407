import React, { ReactElement } from 'react';
import { CenteredPopupContainer } from '@features/common/popups';
import { FormContentContainer } from './formContent';
import { useFormGranule } from './granuleFreeForm.hook';
import { FormGranuleContainerProps } from '../formGranule.model';
import { useTheme } from '@lerna-core';

export const GranuleFreeFormContainer = (
  props: FormGranuleContainerProps
): ReactElement => {
  const theme = useTheme();
  const formGranuleData = useFormGranule(props);

  return (
    <CenteredPopupContainer
      isOpen={!!formGranuleData.currentPopup}
      handleClose={formGranuleData.handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <FormContentContainer formGranuleData={formGranuleData} />
    </CenteredPopupContainer>
  );
};
