import { CourseModel, TranslationModel, UserAggregateModel } from '@lerna-core';

export type FormGranuleContainerProps = {
  course: CourseModel;
  user: UserAggregateModel;
  translations: TranslationModel;
  eventPosition: string;
};

//todo: вынести в общую енамку для новых форм (как удалятся старые)
export enum GranuleFormSubmitStatusTypes {
  error = 'error',
  success = 'success',
  exist = 'repeat',
}
