import React, { ReactElement } from 'react';
import { AgreementStyled, ButtonStyled } from '../contentRight.styled';
import { ButtonColor, ButtonContainer, Nullable } from '@lerna-core';
import { useFormSubscriptionContext } from '../../../formSubscription.context';
import {
  RightSubscriptionBadgeContainer,
  RightSubscriptionBadgeStatuses,
} from '../rightSubscriptionBadge';
import { getRightSubscriptionSubscribeMeta } from './rightSubscriptionSubscribe.meta';

export const RightSubscriptionSubscribeContainer =
  (): Nullable<ReactElement> => {
    const {
      translations,
      subscriptionAvailability,
      handleFormSubmit,
      isSubscriptionButtonDisabled,
    } = useFormSubscriptionContext();

    if (subscriptionAvailability?.isCourseClaimed) {
      return (
        <RightSubscriptionBadgeContainer
          {...getRightSubscriptionSubscribeMeta(
            RightSubscriptionBadgeStatuses.success,
            translations,
            subscriptionAvailability.coursesClaimed,
            subscriptionAvailability.subscriptionAccess.subscription.title,
            subscriptionAvailability.nextClaimPeriodStart
          )}
        />
      );
    }

    if (subscriptionAvailability?.isClaimAvailable) {
      return (
        <>
          <ButtonStyled>
            <ButtonContainer
              onClick={handleFormSubmit}
              color={ButtonColor.purple}
              borderRadius={8}
              title={translations?.sign_up_course_button_title}
              disabled={isSubscriptionButtonDisabled}
            />
          </ButtonStyled>
          <AgreementStyled
            dangerouslySetInnerHTML={{
              __html: translations?.public_contract_text,
            }}
          />
        </>
      );
    }

    if (subscriptionAvailability) {
      return (
        <RightSubscriptionBadgeContainer
          {...getRightSubscriptionSubscribeMeta(
            RightSubscriptionBadgeStatuses.warn,
            translations,
            subscriptionAvailability.coursesClaimed,
            subscriptionAvailability.subscriptionAccess.subscription.title,
            subscriptionAvailability.nextClaimPeriodStart
          )}
        />
      );
    }

    return null;
  };
