import { useRouter } from 'next/router';
import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { useWindowSize, WindowSize } from '../../../hooks';
import { CSSVariables, Nullable } from '../../../models';
import { browserService, urlService } from '../../../services';
import {
  CloseTypes,
  PopupCloseContainer,
  PopupContainerPropsType,
  PopupContentContainer,
  PopupFooterContainer,
  PopupHeaderContainer,
} from './index';
import {
  BODY_CLASS_NAME,
  popupStyleSettings,
  STICKY_HEADER_DELTA,
} from './popup.meta';
import { getPosition, isStickyHeader, isUnStickyHeader } from './popup.service';
import { PopupStyled, WrapperPopupStyled } from './popup.styled';

export const PopupContainer = ({
  children,
  title,
  footer,
  handleClose,
  handleDestroy,
  isOpened = false,
  hasHeader = false,
  style,
  width,
  darkMode = false,
  hasClose = true,
  customStyle = false,
  isMobile = false,
  withoutContentOverflowY = false,
  closeType = CloseTypes.close,
  closeTimeout,
  redirectUrl,
  footerBorder,
  parent,
  isNoCloseWhenMove = true,
  isTouching,
  contentStyles,
  styleSettings = popupStyleSettings,
  closeOutside = false,
  fullHeightMobile = true,
  cleanDOMOnClose = true,
  className,
}: PopupContainerPropsType): Nullable<ReactElement> => {
  const [isOpenedPopup, setIsOpenedPopup] = useState<boolean>(false);
  const [isHeaderSticky, setIsHeaderSticky] = useState<boolean>(false);
  const [inlineStyles, setInlineStyles] = useState<CSSProperties>(
    getPosition(style)
  );
  const hasSize = useRef<boolean>(false);
  const allowClose = React.useRef<boolean>(true);

  const size: WindowSize = useWindowSize();
  const router = useRouter();

  const handleMouseUp = (): void => {
    allowClose.current = true;
  };

  const handleMouseDown = (): void => {
    allowClose.current = false;
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target: EventTarget = event.target;

    const targetDiv: HTMLDivElement = target as HTMLDivElement;
    if (
      !isHeaderSticky &&
      isStickyHeader(
        targetDiv.scrollTop,
        parseInt(styleSettings.paddingContent.top),
        STICKY_HEADER_DELTA
      )
    ) {
      setIsHeaderSticky(true);
    } else if (
      isHeaderSticky &&
      isUnStickyHeader(
        targetDiv.scrollTop,
        parseInt(styleSettings.paddingContent.top),
        STICKY_HEADER_DELTA
      )
    ) {
      setIsHeaderSticky(false);
    }
  };

  const handleTouchMove = (): void => {
    const activeElement = document?.activeElement as HTMLDivElement;

    if (activeElement) {
      activeElement.blur();
    }
  };

  const onClickPopup = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.stopPropagation();
  };

  const cleaningDOM = (): void => {
    document.documentElement.style.setProperty(
      CSSVariables.scrollbarPlugWidth,
      '0px'
    );
    document.body.classList.remove(BODY_CLASS_NAME);
  };

  const onClose = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.stopPropagation();
    if (allowClose.current) {
      setIsOpenedPopup(false);
      if (handleClose) {
        handleClose();
      }
    }
    if (isNoCloseWhenMove && !allowClose.current) {
      allowClose.current = true;
    }
  };

  useEffect(() => {
    return (): void => {
      cleanDOMOnClose && cleaningDOM();

      if (typeof handleDestroy === 'function') {
        handleDestroy();
      }
    };
  }, []);

  useEffect(() => {
    setIsOpenedPopup(isOpened);
    if (isOpened) {
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarWidth,
        `${browserService.getScrollbarWidth()}px`
      );
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarPlugWidth,
        `${browserService.getScrollbarWidth()}px`
      );
      document.body.classList.add(BODY_CLASS_NAME);
    }

    return (): void => {
      cleanDOMOnClose && cleaningDOM();
    };
  }, [isOpened]);

  useEffect(() => {
    if (size.width !== undefined && isOpened && customStyle) {
      if (hasSize.current) {
        setInlineStyles({
          ...inlineStyles,
          left: 'unset',
          maxWidth: '100%',
        });
      } else {
        hasSize.current = true;
      }
    }
  }, [size]);

  useEffect(() => {
    if (style) {
      setInlineStyles(getPosition(style));
    }
  }, [style]);

  useEffect(() => {
    const timeout =
      closeTimeout && isOpened
        ? setTimeout(() => {
            setIsOpenedPopup(isOpened);
            handleClose && handleClose();
            redirectUrl && router.push(urlService.normalizeUrl(redirectUrl));
          }, closeTimeout * 1000)
        : 0;

    return (): void => {
      clearTimeout(timeout as number);
    };
  }, [isOpened]);

  useEffect(() => {
    if (typeof isTouching === 'boolean') {
      if (isTouching) {
        handleMouseDown();
      } else {
        setTimeout(handleMouseUp, 0);
      }
    }
  }, [isTouching]);

  // TODO: Нужна ли эта переменная? Попап сам контралирует состояние isOpen, не позволяю заблокировать закрытие извне
  if (!isOpenedPopup) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <WrapperPopupStyled
        className={className}
        onClick={onClose}
        isMobile={isMobile}
      >
        <PopupStyled
          onScroll={handleScroll}
          onTouchMove={handleTouchMove}
          onMouseUp={isNoCloseWhenMove ? handleMouseUp : undefined}
          onMouseDown={isNoCloseWhenMove ? handleMouseDown : undefined}
          onTouchEnd={isNoCloseWhenMove ? handleMouseUp : undefined}
          onTouchStart={isNoCloseWhenMove ? handleMouseDown : undefined}
          onClick={onClickPopup}
          width={width}
          style={inlineStyles}
          customStyle={customStyle}
          styleProps={styleSettings}
          isMobile={isMobile}
          darkMode={darkMode}
          fullHeightMobile={fullHeightMobile}
        >
          {hasHeader && (
            <PopupHeaderContainer
              styleProps={styleSettings}
              isSticky={isHeaderSticky}
              hasClose={hasClose}
              darkMode={darkMode}
              title={title}
              onClose={onClose}
              isMobile={isMobile}
              closeType={closeType}
            />
          )}
          <PopupContentContainer
            styleProps={customStyle ? null : styleSettings}
            isMobile={isMobile}
            hasFooter={!!footer}
            contentStyles={contentStyles}
            fullHeightMobile={fullHeightMobile}
            withoutContentOverflowY={withoutContentOverflowY}
          >
            <>
              {!hasHeader && hasClose && (
                <PopupCloseContainer
                  darkMode={darkMode}
                  closeType={closeType}
                  handleClose={onClose}
                  isMobile={isMobile}
                  fullHeightMobile={fullHeightMobile}
                  closeOutside={closeOutside}
                />
              )}
              {children}
            </>
          </PopupContentContainer>
          {!!footer && (
            <PopupFooterContainer
              styleProps={styleSettings}
              isMobile={isMobile}
              border={footerBorder}
              darkMode={darkMode}
            >
              {footer}
            </PopupFooterContainer>
          )}
        </PopupStyled>
      </WrapperPopupStyled>
    </>,
    parent || document.querySelector('#__next') || document.body
  );
};
