import React, { ReactElement } from 'react';
import { Nullable } from '../../../models';
import { FormAgreementLabelComponent } from '../formAgreementLabel';
import { FormAgreementContainerProps } from './formAgreement.model';
import { hasAgreementCheckbox } from './formAgreement.helper';
import { FormAgreementContainerStyled } from './formAgreement.styled';
import { CheckboxControlComponent } from '../formControls';

export const FormAgreementContainer = ({
  translations,
  agreementText,
  name,
  checked,
  onChange,
  disabled,
  hasError,
  margin,
  textColor,
}: FormAgreementContainerProps): Nullable<ReactElement> => {
  const withAgreementCheckbox = hasAgreementCheckbox();

  const checkboxLabel = (
    <FormAgreementContainerStyled
      margin={!withAgreementCheckbox ? margin : undefined}
    >
      <FormAgreementLabelComponent
        hasError={hasError}
        text={agreementText || translations?.form_agreement_text}
        textColor={textColor}
      />
    </FormAgreementContainerStyled>
  );
  const checkbox = name && (
    <FormAgreementContainerStyled margin={margin}>
      <CheckboxControlComponent
        name={name}
        checked={checked}
        onChange={onChange}
        children={checkboxLabel}
        disabled={disabled}
        margin="0"
        hasError={hasError}
      />
    </FormAgreementContainerStyled>
  );

  if (!withAgreementCheckbox) return checkboxLabel;

  if (checkbox) return checkbox;

  return null;
};
