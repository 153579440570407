import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FilterTitleContainer } from '../filterTitle';
import { FilterTab, FilterTabSelectAll } from '../filterTab';
import {
  dropdownSearchStickyDelta,
  FilterTabsContainerTypes,
} from './filterTabs.meta';
import {
  FilterTabsBorderStyled,
  FilterTabsEmptySubTitleStyled,
  FilterTabsEmptyTitleStyled,
  FilterTabsItemsStyled,
  FilterTabsStyled,
} from './filterTabs.styled';
import { Nullable } from '@lerna-core';

export const FilterTabsContainer = ({
  filter,
  withTitle,
  translations,
  defaultPathname,
}: FilterTabsContainerTypes): Nullable<ReactElement> => {
  const optionsRef = useRef<Nullable<HTMLDivElement>>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [hasScroll, setHasScroll] = useState<boolean>(false);

  useEffect(() => {
    if (optionsRef.current) {
      const maxHeight = getComputedStyle(optionsRef.current)['maxHeight'];

      if (!!maxHeight) {
        setHasScroll(parseInt(maxHeight) < optionsRef.current.scrollHeight);
      }
    }
  }, []);

  const hasSelectAll = filter.options.length > 1;

  const handleScroll: React.EventHandler<React.UIEvent<HTMLDivElement>> = (
    event: React.UIEvent<HTMLDivElement>
  ) => {
    const target: EventTarget = event.target;
    const targetDiv: HTMLDivElement = target as HTMLDivElement;
    const isScrolledNow = targetDiv.scrollTop > dropdownSearchStickyDelta;

    if (isScrolledNow !== isScrolled) {
      setIsScrolled(isScrolledNow);
    }
  };

  if (!filter.isVisible) return null;

  return (
    <FilterTabsStyled data-instance="tab">
      {withTitle && <FilterTitleContainer title={filter.title} />}
      {!!filter.options.length && isScrolled && <FilterTabsBorderStyled />}
      <FilterTabsItemsStyled ref={optionsRef} onScroll={handleScroll}>
        {filter.options.length ? (
          <>
            {hasSelectAll && (
              <FilterTabSelectAll
                filter={filter}
                defaultPathname={defaultPathname}
                translations={translations}
              />
            )}
            {filter.options.map((option) => (
              <FilterTab
                key={option.slug}
                option={option}
                filter={filter}
                defaultPathname={defaultPathname}
              />
            ))}
          </>
        ) : (
          <>
            <FilterTabsEmptyTitleStyled>
              {translations?.filter_options_empty_title}
            </FilterTabsEmptyTitleStyled>
            <FilterTabsEmptySubTitleStyled>
              {translations?.filter_options_empty_sub_title}
            </FilterTabsEmptySubTitleStyled>
          </>
        )}
      </FilterTabsItemsStyled>
      {!!filter.options.length && hasScroll && <FilterTabsBorderStyled />}
    </FilterTabsStyled>
  );
};
