import React, { ReactElement } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
} from './contentRight.styled';
import { ButtonColor, ButtonContainer } from '@lerna-core';
import { RightHeadContainer } from './rightHead';
import { useFormArchiveContext } from '../../formArchive.context';

export const ContentRightContainer = (): ReactElement => {
  const {
    translations,
    course,
    handleSubmit,
    isLoading,
    isMobile,
    subscriptionAvailability,
  } = useFormArchiveContext();

  return (
    <ContentRightStyled>
      <RightHeadContainer
        course={course}
        isMobile={isMobile}
        translations={translations}
      />
      <ButtonStyled>
        <ButtonContainer
          onClick={handleSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={translations?.sign_up_course_button_title}
          disabled={isLoading || !subscriptionAvailability?.isClaimAvailable}
        />
      </ButtonStyled>
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
