import React, { ReactElement, useContext, useMemo } from 'react';
import { BannerCoursesContainerProps } from './bannerCourses.model';
import {
  CustomerBannerContainerStyled,
  CustomerBannerLabelStyled,
} from '../advantagesCustomerBanners.styled';
import {
  BannerCoursesButtonsStyled,
  BannerCoursesHeadStyled,
  BannerCoursesTitleStyled,
  BannerCoursesWrapStyled,
  VendorLogoStyled,
} from './bannerCourses.styled';
import {
  browserService,
  ButtonColor,
  ButtonContainer,
  DeviceInfoContext,
  getRedirectLMSHref,
  Nullable,
  personalPath,
  projectSubDomains,
  urlService,
  useTheme,
  VendorEnum,
  VendorsLogoContainer,
} from '@lerna-core';
import { BannerContainer } from '../banner';
import { getBannerCourse } from './bannerCourses.helper';

export const BannerCoursesContainer = ({
  customerCourses,
  companyId,
  translations,
  handleClickGoToLms,
  handleClickGoToCourses,
}: BannerCoursesContainerProps): Nullable<ReactElement> => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const theme = useTheme();
  const myCoursesButtonLabel = isMobile
    ? translations.banner_courses_button_my_education_mobile
    : translations.banner_courses_button_my_education;
  const goToLMSButtonLabel = isMobile
    ? translations.banner_courses_button_go_to_lms_mobile
    : translations.banner_courses_button_go_to_lms;

  const bannerCourse = useMemo(
    () => getBannerCourse(customerCourses, companyId),
    []
  );
  const coursesHref = useMemo(
    () =>
      urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        personalPath.courses
      ),
    []
  );
  const courseLmsHref = useMemo(
    () =>
      urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        getRedirectLMSHref(bannerCourse.uuid)
      ),
    []
  );

  return (
    <BannerContainer>
      <CustomerBannerContainerStyled>
        <BannerCoursesWrapStyled>
          <BannerCoursesHeadStyled>
            <CustomerBannerLabelStyled>
              {translations.banner_courses_label}:
            </CustomerBannerLabelStyled>
            {bannerCourse.vendor && (
              <VendorLogoStyled>
                <VendorsLogoContainer
                  fillColor={theme.COLOR.WHITE}
                  vendor={bannerCourse.vendor.slug as VendorEnum}
                  height={20}
                />
              </VendorLogoStyled>
            )}
          </BannerCoursesHeadStyled>
          <BannerCoursesTitleStyled>
            {bannerCourse.title}
          </BannerCoursesTitleStyled>
        </BannerCoursesWrapStyled>
        <BannerCoursesButtonsStyled>
          <ButtonContainer
            color={ButtonColor.white}
            title={goToLMSButtonLabel}
            href={courseLmsHref}
            isNativeLink={true}
            onClick={handleClickGoToLms}
            {...urlService.setTargetBlankOptions()}
          />
          {customerCourses.length > 1 && (
            <ButtonContainer
              color={ButtonColor.transparent}
              borderColor={theme.COLOR.WHITE_32}
              textColor={theme.COLOR.WHITE}
              href={coursesHref}
              isNativeLink={true}
              onClick={handleClickGoToCourses}
            >
              <>
                {myCoursesButtonLabel} <sup>{customerCourses.length}</sup>
              </>
            </ButtonContainer>
          )}
        </BannerCoursesButtonsStyled>
      </CustomerBannerContainerStyled>
    </BannerContainer>
  );
};
