import React, { ReactElement } from 'react';
import { CommonEventPositions, Nullable } from '@lerna-core';
import {
  GuestOrderFormContainer,
  UserOrderFormContainer,
} from '@features/common/forms';
import { ListFormsContainerProps } from './listForms.model';

export const ListFormsContainer = ({
  course,
  translations,
  user,
  courseIndex,
  discountProgramColors,
}: ListFormsContainerProps): Nullable<ReactElement> => {
  if (!course) {
    return null;
  }

  if (user) {
    return (
      <UserOrderFormContainer
        translations={translations}
        course={course}
        user={user}
        eventPosition={CommonEventPositions.coursesPageList}
        courseIndex={courseIndex}
        discountProgramColors={discountProgramColors}
      />
    );
  }

  return (
    <GuestOrderFormContainer
      course={course}
      translations={translations}
      eventPosition={CommonEventPositions.coursesPageList}
      courseIndex={courseIndex}
      discountProgramColors={discountProgramColors}
    />
  );
};
