import React, { ReactElement, useMemo } from 'react';
import {
  FilterModel,
  FilterTypeEnum,
  IconArrowComponent,
  useTheme,
} from '@lerna-core';
import {
  MobileFilterArrowStyled,
  MobileFilterStyled,
  MobileToggleFilterStyled,
} from './mobileFilter.styled';
import { MobileTypeContainer } from '../mobileType';
import { MobileFilterContainerProps } from './mobileFilter.model';
import { FilterIconContainer } from '../../../filterIcon';
import { isCustomCheckboxFilter } from '../../../filters.helper';
import { FilterToggleContainer } from '../../../filterToggle';

export const MobileFilterContainer = ({
  filter,
  handleClick,
}: MobileFilterContainerProps): ReactElement => {
  const theme = useTheme();

  const isToggle = filter.type === FilterTypeEnum.toggle;
  const filterBackgroundColor = (filter as FilterModel).backgroundColor;
  const filterIconColor = (filter as FilterModel).iconColor;
  const isCustomFilter = useMemo(
    () => isCustomCheckboxFilter(filter.slug),
    [filter.slug]
  );
  const filterIcon = (
    <FilterIconContainer
      filterSlug={filter.slug}
      background={filterIconColor}
    />
  );

  return isToggle ? (
    <MobileToggleFilterStyled isCustomFilter={isCustomFilter}>
      <FilterToggleContainer
        filter={filter as FilterModel}
        withTooltip={false}
        analyticsType="fixed"
      />
    </MobileToggleFilterStyled>
  ) : (
    <MobileFilterStyled
      onClick={handleClick.bind(null, filter)}
      isCustomFilter={isCustomFilter}
      background={filterBackgroundColor}
    >
      <MobileTypeContainer
        filter={filter}
        withDescription={isCustomFilter}
        icon={filterIcon}
      />
      <MobileFilterArrowStyled isCustomFilter={isCustomFilter}>
        <IconArrowComponent
          width={16}
          height={16}
          strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
        />
      </MobileFilterArrowStyled>
    </MobileFilterStyled>
  );
};
