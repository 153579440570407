import { NextRouter } from 'next/router';
import {
  CareerModel,
  objectService,
  publicConfig,
  QueryParams,
  ShowcaseAggregatePathEnum,
} from '@lerna-core';
import { DiscountProgramAggregatePathEnum } from '@features/common/discountProgram';

export const getNextCoursesPageAggregateLink = (
  currentLink: string,
  career?: CareerModel,
  router?: NextRouter
): string => {
  const url = new URL(currentLink);
  const queryObject = objectService.getObjectFromQueryString(url.search);
  delete queryObject[QueryParams.site];
  const queryString = objectService.getQueryStringFromObject(queryObject);
  const discountProgramQuery = router?.query[QueryParams.discountProgram];

  if (discountProgramQuery) {
    if (career) {
      return `${publicConfig.API_GATEWAY_URL}${DiscountProgramAggregatePathEnum.aggregateAuth}${discountProgramQuery}?${queryString}`;
    }

    return `${publicConfig.API_GATEWAY_URL}${DiscountProgramAggregatePathEnum.aggregate}${discountProgramQuery}?${queryString}`;
  }

  if (career?.companyId) {
    return `${publicConfig.API_GATEWAY_URL}${ShowcaseAggregatePathEnum.aggregateAuthWithToken}/${career.companyId}?${queryString}`;
  } else if (career) {
    return `${publicConfig.API_GATEWAY_URL}${ShowcaseAggregatePathEnum.aggregateAuth}?${queryString}`;
  }

  return `${publicConfig.API_GATEWAY_URL}${ShowcaseAggregatePathEnum.aggregate}?${queryString}`;
};
