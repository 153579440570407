import { FilterModel, TranslationModel } from '@lerna-core';

export const dropdownSearchStickyDelta = 12;

export type FilterTabsContainerTypes = {
  filter: FilterModel;
  defaultPathname: string;
  translations: TranslationModel;
  withTitle?: boolean;
};
