import React, { ReactElement } from 'react';
import { ButtonAdditionalColors, PopupContainer } from '@lerna-core';
import {
  MessagePopupComponent,
  MessagePopupStatus,
} from '@features/common/popups';
import { FormStatusContainerProps } from './formStatus.model';

export const FormStatusContainer = ({
  translations,
  attemptsProps,
  isMobile,
  closeError,
  isErrorDisplayed,
  closeSuccess,
  isSuccessDisplayed,
  handleRequest,
  discountProgramColors,
}: FormStatusContainerProps): ReactElement => {
  const buttonAdditionalColors = discountProgramColors
    ? ({
        color: discountProgramColors.text,
        backgroundColor: discountProgramColors.background,
      } as ButtonAdditionalColors)
    : undefined;
  const errorButtonTitle = attemptsProps?.hasAttempts
    ? translations?.button_resend_title
    : undefined;

  const successPopup = isSuccessDisplayed && (
    <PopupContainer
      handleClose={closeSuccess}
      isOpened={isSuccessDisplayed}
      hasHeader={false}
      isMobile={isMobile}
    >
      <MessagePopupComponent
        status={MessagePopupStatus.success}
        title={translations?.popup_success_message_title}
        subtitle={translations?.popup_success_message_description}
        buttonTitle={translations?.button_ok_title}
        buttonCallback={closeSuccess}
        buttonAdditionalColors={buttonAdditionalColors}
      />
    </PopupContainer>
  );
  const errorPopup = isErrorDisplayed && (
    <PopupContainer
      handleClose={closeError}
      isOpened={isErrorDisplayed}
      hasHeader={false}
      isMobile={isMobile}
    >
      <MessagePopupComponent
        status={MessagePopupStatus.error}
        title={translations?.popup_error_message_title}
        subtitle={translations?.popup_error_message_description}
        buttonTitle={errorButtonTitle}
        buttonCallback={handleRequest}
        buttonAdditionalColors={buttonAdditionalColors}
      />
    </PopupContainer>
  );

  return (
    <>
      {successPopup}
      {errorPopup}
    </>
  );
};
