import React, { ReactElement } from 'react';
import { Nullable } from '@lerna-core';
import { FiltersResetStyled } from './filtersReset.styled';
import { ResetItemContainer } from './resetItem';
import { ResetAllItemContainer } from './resetAllItem';
import { FiltersResetContainerProps } from './filtersReset.model';
import { ResetShowMoreItemContainer } from './resetShowMoreItem';
import { useFiltersReset } from './filtersReset.hook';

export const FiltersResetContainer = ({
  translations,
  type = 'desktop',
}: FiltersResetContainerProps): Nullable<ReactElement> => {
  const {
    filtersWithSelectedOptions,
    resetItemsToRender,
    handleClearAllFilters,
    toggleCollapsed,
    isMobileType,
    showResetAllItem,
    showShowMoreItem,
  } = useFiltersReset(type);

  if (!filtersWithSelectedOptions.length) return null;

  return (
    <FiltersResetStyled isMobileType={isMobileType}>
      {resetItemsToRender.map((item) => (
        <ResetItemContainer
          key={item.itemValue}
          {...item}
          isMobileType={isMobileType}
        />
      ))}
      {showShowMoreItem && (
        <ResetShowMoreItemContainer handleClick={toggleCollapsed} />
      )}
      {showResetAllItem && (
        <ResetAllItemContainer
          translations={translations}
          handleClearAllFilters={handleClearAllFilters}
        />
      )}
    </FiltersResetStyled>
  );
};
