import React, { ReactElement } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  IconNextComponent,
  Nullable,
  useTheme,
} from '@lerna-core';
import {
  DividerStyled,
  PaginationContainerStyled,
} from './coursesPagination.styled';
import { CoursesPaginationContainerProps } from './coursesPagination.model';
import { useCoursesPagination } from './coursesPagination.hook';

export const CoursesPaginationContainer = (
  props: CoursesPaginationContainerProps
): Nullable<ReactElement> => {
  const theme = useTheme();
  const { coursesPaginationData, handleLoadMore, isLoading } =
    useCoursesPagination(props);

  if (coursesPaginationData.remainingCourses < 1) {
    return <DividerStyled />;
  }

  return (
    <PaginationContainerStyled>
      <ButtonContainer
        title={coursesPaginationData.buttonTitle}
        onClick={handleLoadMore}
        disabled={isLoading}
        color={ButtonColor.brightPurple}
        borderRadius={100}
      >
        <IconNextComponent strokeColor={theme.COLOR.PRIMARY[500]} />
      </ButtonContainer>
    </PaginationContainerStyled>
  );
};
