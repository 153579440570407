import React, { ReactElement } from 'react';
import { ContentRightStyled } from './contentRight.styled';
import { RightHeadContainer } from './rightHead';
import { useFormSubscriptionContext } from '../../formSubscription.context';
import { RightSubscriptionSubscribeContainer } from '@features/common/forms/userOrderForm/formSubscription/subscriptionContent/contentRight/rightSubscriptionSubscribe';

export const ContentRightContainer = (): ReactElement => {
  const { translations, course, isMobile } = useFormSubscriptionContext();

  return (
    <ContentRightStyled>
      <RightHeadContainer
        course={course}
        isMobile={isMobile}
        translations={translations}
      />
      <RightSubscriptionSubscribeContainer />
    </ContentRightStyled>
  );
};
