import {
  AccordionContentStyled,
  breakpoint,
  ContentWrapperStyled,
  fontSettings,
  positionSticky,
} from '@lerna-core';
import styled, { css } from 'styled-components';
import { defaultCoursePageSpacing } from './coursePaper';

const ASIDE_BOX_WIDTH = '360px';

export const CoursePageStyled = styled.div`
  max-width: 1280px;
  margin: 40px auto;
  width: 100%;

  & > *:last-child {
    margin-bottom: 0;
  }

  ${breakpoint(
    'xlargeDown',
    css`
      max-width: 100%;
      margin-top: 20px;
    `
  )}

  ${breakpoint(
    'smallDown',
    css`
      ${ContentWrapperStyled} {
        padding: 0;
      }
    `
  )}
  
  ${AccordionContentStyled} {
    white-space: pre-wrap;

    color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
    ${(props): string =>
      fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}

    > div:not(:last-child) {
      margin-bottom: 16px;
    }

    *:first-child {
      margin-top: 0;
    }

    p,
    div,
    li {
      white-space: pre-wrap;
    }

    a {
      color: ${({ theme }): string => theme.COLOR.TEXT_MEDIUM_COLOR};
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const CoursePageArticleStyled = styled.div`
  h5 {
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 32px;
    ${(props): string =>
      fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)}

    ${(props): ReturnType<typeof css> | string =>
      breakpoint(
        'mediumDown',
        css`
          ${fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}
        `
      )}
  }

  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    list-style-type: none;
    padding-left: 12px;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: -12px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: ${(props): string =>
        props.theme.COLOR.TEXT_LIGHT_COLOR};
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  strong {
    font-weight: 700;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const CoursePageWrapWithAsideWrapStyled = styled.div`
  padding-top: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${breakpoint(
    'largeDown',
    css`
      padding-top: 24px;
    `
  )}
`;

export const CoursePageAsideContentStyled = styled.div`
  flex: 1 1 auto;
  box-sizing: border-box;

  ${breakpoint(
    'xlarge',
    css`
      padding-right: ${defaultCoursePageSpacing};
      max-width: calc(100% - ${ASIDE_BOX_WIDTH});
    `
  )}
`;

export const CoursePageAsideBoxStyled = styled.div`
  flex: 0 0 ${ASIDE_BOX_WIDTH};
  ${positionSticky()}
  top: 106px;

  ${breakpoint(
    'xlargeDown',
    css`
      display: none;
    `
  )}
`;
