import { CustomerModel } from './customer.model';
import { CareerModel, PersonalModel } from '../user';
import { TranslationModel } from '../../models';
import { stringService } from '../../services';

/**
 * Get CareerModel from CustomerModel
 * @return {object}
 * @params - customer
 * */
export const getCustomerCareer = (
  customer: Pick<
    CustomerModel,
    | 'position'
    | 'companyTitle'
    | 'companyId'
    | 'department'
    | 'isVerified'
    | 'roles'
    | 'tariff'
  >
): CareerModel => ({
  position: customer.position,
  companyTitle: customer.companyTitle,
  companyId: customer.companyId,
  department: customer.department,
  isVerified: customer.isVerified,
  roles: customer.roles,
  tariff: customer.tariff,
});

/**
 * Get PersonalModel from CustomerModel
 * @return {object}
 * @params - customer
 * */
export const getCustomerPersonal = (
  customer: Pick<
    CustomerModel,
    | 'avatar'
    | 'email'
    | 'firstName'
    | 'isDisabled'
    | 'isFulfilled'
    | 'lastName'
    | 'patronymic'
    | 'phone'
    | 'uuid'
    | 'lastActivityAt'
    | 'registeredAt'
    | 'educationStreams'
  >
): PersonalModel => ({
  avatar: customer.avatar,
  email: customer.email,
  firstName: customer.firstName,
  isDisabled: customer.isDisabled,
  isFulfilled: customer.isFulfilled,
  lastName: customer.lastName,
  patronymic: customer.patronymic,
  phone: customer.phone,
  uuid: customer.uuid,
  lastActivityAt: customer.lastActivityAt,
  registeredAt: customer.registeredAt,
  educationStreams: customer.educationStreams,
});

export const getCustomersCount = (
  count: number,
  translations: TranslationModel,
  register: 'lowercase' | 'capitalize' = 'lowercase'
): string => {
  const value = stringService
    .pluralTranslations(count, [
      translations?.customers_count_label_1,
      translations?.customers_count_label_2,
      translations?.customers_count_label_many,
    ])
    .toLowerCase();

  switch (register) {
    case 'capitalize':
      return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    case 'lowercase':
      return value.toLowerCase();
  }
};
