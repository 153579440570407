import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  browserService,
  ContentWrapperStyled,
  DeviceInfoContext,
  useUser,
  useWindowSize,
  WindowSize,
} from '@lerna-core';
import {
  CoursePageAsideBoxStyled,
  CoursePageAsideContentStyled,
  CoursePageStyled,
  CoursePageWrapWithAsideWrapStyled,
} from './course.styled';
import { CoursePaperStyled } from './coursePaper';
import { useCoursePageContext } from './course.context';
import { CoursePropsModel } from './course.model';
import { CourseHeadContainer } from './courseHead';
import { CourseProgramContainer } from './coursePropgram';
import { CourseSimilarContainer } from './courseSimilar';
import { CourseTutorsContainer } from './courseTutors';
import { CourseSkillsContainer } from './courseSkills';
import { CourseDescriptionContainer } from './courseDescription';
import { CourseSignUpContainer } from './courseSignUp';
import { CourseFormButtonContainer } from './courseFormButton';
import { CourseFaqsContainer } from './courseFaqs';
import { CourseBackContainer } from './courseBack';

const HIDE_SIDEBAR_BREAKPOINT = 1280;

export const CourseContainer = ({
  useSimilarCourses = true,
  backHrefPath,
}: CoursePropsModel): ReactElement => {
  const { translations, course } = useCoursePageContext();

  const { user } = useUser();
  const isListingWithBenefitsOnly = user?.career?.isListingWithBenefitsOnly;
  const showSimilarCourses = !isListingWithBenefitsOnly && useSimilarCourses;
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  const [hideSidebar, setHideSidebar] = useState<boolean>(isMobile);

  const { width: windowWidth }: WindowSize = useWindowSize();

  useEffect(() => {
    if (windowWidth) {
      if (hideSidebar && windowWidth >= HIDE_SIDEBAR_BREAKPOINT) {
        setHideSidebar(false);
      } else if (!hideSidebar && windowWidth < HIDE_SIDEBAR_BREAKPOINT) {
        setHideSidebar(true);
      }
    }
  }, [windowWidth]);

  return (
    <CoursePageStyled>
      <ContentWrapperStyled isMobile={isMobile}>
        <CourseBackContainer
          backHrefPath={backHrefPath}
          translations={translations}
        />
        <CoursePageWrapWithAsideWrapStyled>
          <CoursePageAsideContentStyled>
            <CourseHeadContainer hideSidebar={hideSidebar} />
            {!!course?.description && (
              <CoursePaperStyled>
                <CourseDescriptionContainer
                  description={course.description}
                  translations={translations}
                />
              </CoursePaperStyled>
            )}
            {Boolean(course?.skills?.length) && (
              <CoursePaperStyled>
                <CourseSkillsContainer
                  skills={course.skills}
                  translations={translations}
                />
              </CoursePaperStyled>
            )}
            {Boolean(course?.programSections?.length) && (
              <CoursePaperStyled>
                <CourseProgramContainer />
              </CoursePaperStyled>
            )}
            {Boolean(course?.tutors?.length) && (
              <CoursePaperStyled>
                <CourseTutorsContainer />
              </CoursePaperStyled>
            )}
            {Boolean(course?.faqs?.length) && (
              <CoursePaperStyled>
                <CourseFaqsContainer
                  faqs={course.faqs}
                  translations={translations}
                />
              </CoursePaperStyled>
            )}
          </CoursePageAsideContentStyled>
          {!hideSidebar && (
            <CoursePageAsideBoxStyled>
              <CourseFormButtonContainer />
            </CoursePageAsideBoxStyled>
          )}
        </CoursePageWrapWithAsideWrapStyled>
        {showSimilarCourses && <CourseSimilarContainer />}
        <CourseSignUpContainer />
      </ContentWrapperStyled>
    </CoursePageStyled>
  );
};
