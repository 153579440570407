import { CommonEventTypes, CourseModel, Translations } from '@lerna-core';
import * as Yup from 'yup';
import {
  getTextMaxLengthValidationMessage,
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import {
  FormB2BWithoutCompensationInitialValues,
  FormB2BWithoutCompensationValidationSchema,
} from './formB2BWithoutCompensation.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';

export const getFormB2BWithoutCompensationInitialValues =
  (): FormB2BWithoutCompensationInitialValues => ({
    withPromoCode: false,
    promoCode: '',
  });

export function getFormB2BWithoutCompensationValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean
): FormB2BWithoutCompensationValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected
      ? {
          withPromoCode: Yup.boolean().optional(),
          promoCode: Yup.string().when('withPromoCode', {
            is: true,
            then: (promoCode) =>
              promoCode
                .required(translations?.input_required_error_label)
                .max(255, getTextMaxLengthValidationMessage(255, translations))
                .trim(),
          }),
        }
      : {}),
  };

  return Yup.object().shape(validationObject);
}

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  isFreeCourse: boolean,
  orderId?: string,
  requestType?: string
): void => {
  let event = `${OrderFormEvents.formEnrollStatus.replace('{{type}}', type)}`;

  if (
    paymentContent === UserOrderFormPaymentContentTypes.consultation &&
    !isFreeCourse
  ) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'consultation')}`;
  }
  if (isFreeCourse) {
    event = `${OrderFormEvents.formEnrollStatusValue
      .replace('{{type}}', type)
      .replace('{{value}}', 'free_course')}`;
  }

  orderFormPushDataLayer(
    event,
    CommonEventTypes.orderEvents,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
