import React, { ReactElement } from 'react';
import { FormGranuleContainerProps } from './formGranule.model';
import { GranulePayFormContainer } from './granulePayForm';
import { GranuleFreeFormContainer } from './granuleFreeForm';
import { useUserTariff } from '@hooks';

export const FormGranuleContainer = (
  props: FormGranuleContainerProps
): ReactElement => {
  const { isLernaPrime } = useUserTariff();

  const showGranulePayForm = isLernaPrime && !props.course.subscriptionAccess;

  return showGranulePayForm ? (
    <GranulePayFormContainer {...props} />
  ) : (
    <GranuleFreeFormContainer {...props} />
  );
};
