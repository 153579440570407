import styled, { css } from 'styled-components';
import { Nullable } from '../../../../models';

import { breakpoint, customizeScrollBar } from '../../../../styles';
import { FOOTER_MOBILE_HEIGHT } from '../popup.meta';
import { ContentStyles, PopupStyleProps } from '../popup.model';
import { getContentStyles } from './popupContent.mixin';

type PopupContentContainerStyledProps = {
  styleProps: Nullable<PopupStyleProps>;
  isMobile: boolean;
  hasFooter: boolean;
  contentStyles?: ContentStyles;
  withoutContentOverflowY: boolean;
  fullHeightMobile: boolean;
};

export const PopupContentContainerStyled = styled.div<PopupContentContainerStyledProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ isMobile, withoutContentOverflowY }): string =>
    isMobile || withoutContentOverflowY ? '' : 'overflow-y: auto;'}
  padding: ${({ styleProps }): string =>
    `${
      styleProps
        ? `${styleProps.paddingContent.top} ${styleProps.paddingContent.right} ${styleProps.paddingContent.bottom} ${styleProps.paddingContent.left}`
        : '0'
    }`};

  ${({ contentStyles }): ReturnType<typeof css> | string =>
    getContentStyles(contentStyles)}

  ${({ hasFooter }): ReturnType<typeof css> =>
    hasFooter
      ? customizeScrollBar(20, 0, 4, false)
      : customizeScrollBar(20, 20, 4, false)}

  ${({
    isMobile,
    hasFooter,
    fullHeightMobile,
  }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding-left: 20px;
        padding-right: 20px;
        ${hasFooter &&
        `padding-bottom: calc(${
          FOOTER_MOBILE_HEIGHT + 88
        }px + env(safe-area-inset-bottom))`}
        ${!fullHeightMobile && 'padding: 48px 20px 24px 20px'};
      `,
      isMobile
    )}
`;
