import { ApiFormError, FormPaymentInfoModel } from './form.model';
import { CourseModel, errorCode, OrderTypesEnum } from '@lerna-core';

export const isExistError = (error: ApiFormError): boolean =>
  error.response?.status === errorCode.validationError;

export const withPromoCodeError = (error: ApiFormError): boolean => {
  //todo: remove after backend make changes
  const httpError = error.response?.data?.errors?.http;

  return !!httpError && httpError[0] === 'promo_code_error';
};

export const withPhoneError = (error: ApiFormError): boolean =>
  !!error.response?.data?.errors['[phone]'];

export const getFormOrderInfo = (course: CourseModel): FormPaymentInfoModel => {
  const hasConsultation = course.orderTypes.some(
    (orderType) => orderType === OrderTypesEnum.consultation
  );
  const hasOnlinePayment = course.orderTypes.some(
    (orderType) => orderType === OrderTypesEnum.online_payment
  );
  const hasOrder = hasConsultation && hasOnlinePayment;
  const hasOnlyConsultation = hasConsultation && !hasOnlinePayment;
  const hasOnlyOnlinePayment = !hasConsultation && hasOnlinePayment;

  return {
    hasConsultation,
    hasOnlinePayment,
    hasOrder,
    hasOnlyConsultation,
    hasOnlyOnlinePayment,
  };
};
