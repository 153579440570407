import { ReactElement } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { buttonDefaultStyles, lineClamping } from '../../styles';

export enum ButtonSize {
  small = 'small',
  normal = 'normal',
}

export enum ButtonColor {
  purple = 'purple',
  mediumPurple = 'mediumPurple',
  brightPurple = 'brightPurple',
  transparent = 'transparent',
  transparentFull = 'transparentFull',
  white = 'white',
  default = 'default',
  red = 'red',
  gradient = 'gradient',
  coloredGradient = 'coloredGradient',
  accent = 'accent',
}

export enum ButtonChildrenPosition {
  left = 'left',
  right = 'right',
}

export type ButtonAdditionalColors = {
  color: string;
  backgroundColor: string;
};

export interface IButton {
  boxShadow?: string;
  disabled?: boolean;
  onClick?: () => void;
  color?: string;
  size?: ButtonSize;
  borderRadius?: number;
  borderWidth?: number;
  title?: string;
  childrenPosition?: ButtonChildrenPosition;
  href?: string;
  isNativeLink?: boolean;
  children?: ReactElement;
  bordered?: boolean;
  target?: string;
  borderColor?: string;
  textColor?: string;
  type?: string;
  additionalColors?: ButtonAdditionalColors;
  fullWidth?: boolean;
}

export const ButtonStyled = styled.button<IButton>`
  ${(props): string => buttonDefaultStyles(props)}
`;

export const ButtonTitleStyled = styled.span`
  max-width: 100%;
  ${lineClamping(1)}
`;

export const ButtonLinkStyled = styled(Link)<IButton>`
  text-decoration: none;
  ${(props): string => buttonDefaultStyles(props)}
  ${({ disabled }): string =>
    disabled
      ? `cursor: default;
    opacity: 0.4;
    pointer-events: none;`
      : ''}
`;
