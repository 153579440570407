import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const usePageRouter = (): boolean => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect((): (() => void) => {
    router.events.on('routeChangeStart', () => {
      setIsLoading(true);
    });
    router.events.on('routeChangeComplete', () => {
      setIsLoading(false);
    });
    router.events.on('routeChangeError', () => {
      setIsLoading(false);
    });

    return (): void => {
      router.events.off('routeChangeStart', () => {
        setIsLoading(true);
      });
      router.events.off('routeChangeComplete', () => {
        setIsLoading(false);
      });
      router.events.off('routeChangeError', () => {
        setIsLoading(false);
      });
    };
  }, [router]);

  return isLoading;
};
