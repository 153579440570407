import React, { cloneElement, ReactElement, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import {
  handleToggleSearchForm,
  HeaderLoaderContainer as HeaderLoader,
  IconHelpComponent,
  IconMagnifierComponent,
  LogoContainer,
  MenuMobileContainer,
  PRESETS,
  useTheme,
  useUser,
  useUserMenu,
} from '@lerna-core';
import { PageNameContext } from '@contexts';
import { useDiscountProgram } from '@features/common/discountProgram';
import { SearchButtonContainer } from '@features/common/search';
import {
  getHeaderColorType,
  getHeaderMobileStaticLinks,
  getHomePageLink,
  isCollaborateWithLerna,
} from '../header.helpers';
import { HeaderMobileContainerProps } from './headerMobile.model';
import {
  HeaderMobileBoxStyled,
  HeaderMobileContainerStyled,
  HeaderMobileIconStyled,
  HeaderMobileLogoStyled,
  HeaderMobileWrapperStyled,
} from './headerMobile.styled';

const { w: logoWidth, h: logoHeight } = PRESETS.logoCompanySmall.opts;

export const HeaderMobileContainer = ({
  isSticky,
  hasSticky,
  translations,
  company,
  showSearch,
  showFilters,
  handleOpenContactUsPopup,
  isMobile,
  headerLinkType,
  withMobileMenu,
  handleLogoClick,
  discountProgramLogo,
  withLogin,
}: HeaderMobileContainerProps): ReactElement => {
  const pageName = useContext(PageNameContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useUser();
  const { menu } = useUserMenu();
  const { isDiscountProgramPage } = useDiscountProgram();

  const handleClickSearch = (): void => {
    dispatch(handleToggleSearchForm(true, null));
  };

  const homePageLink = useMemo(() => getHomePageLink(router), []);
  const staticLinks = useMemo(
    () => getHeaderMobileStaticLinks(translations, headerLinkType),
    []
  );
  const iconColor = theme.COLOR.TEXT_DARK_COLOR;
  const projectPageName = pageName ? pageName : undefined;
  const withCompany = !!user?.career?.companyId;

  const headerColorType = useMemo(
    () => getHeaderColorType(user, isDiscountProgramPage),
    []
  );
  const logo =
    isDiscountProgramPage && !!discountProgramLogo ? (
      cloneElement(discountProgramLogo, { href: homePageLink })
    ) : (
      <LogoContainer
        href={homePageLink}
        logo={company?.logo}
        width={logoWidth}
        height={logoHeight}
        preset="logoCompanySmall"
        callback={handleLogoClick}
        withCompany={!isDiscountProgramPage && withCompany}
        isCollaborateWithLerna={isCollaborateWithLerna(
          user,
          isDiscountProgramPage
        )}
      />
    );

  return (
    <>
      <HeaderMobileContainerStyled
        isSticky={isSticky}
        colorType={headerColorType}
        withCompany={withCompany}
        hasSticky={hasSticky}
      >
        <HeaderMobileWrapperStyled>
          <HeaderMobileBoxStyled>
            {withMobileMenu && (
              <MenuMobileContainer
                isMobile={isMobile}
                translations={translations}
                menu={menu}
                user={user}
                bottomStaticLinks={staticLinks}
                projectPageName={projectPageName}
                withLogin={withLogin}
              />
            )}
          </HeaderMobileBoxStyled>
          <HeaderMobileLogoStyled>{logo}</HeaderMobileLogoStyled>
          <HeaderMobileBoxStyled>
            {!isSticky && (
              <HeaderMobileIconStyled onClick={handleOpenContactUsPopup}>
                <IconHelpComponent
                  fillColor={iconColor}
                  strokeColor={iconColor}
                />
              </HeaderMobileIconStyled>
            )}
            {showFilters && isSticky && (
              <HeaderMobileIconStyled>
                <SearchButtonContainer
                  isSticky={isSticky}
                  isFullButton={false}
                />
              </HeaderMobileIconStyled>
            )}
            {showSearch && isSticky && (
              <HeaderMobileIconStyled onClick={handleClickSearch}>
                <IconMagnifierComponent
                  fillColor={iconColor}
                  strokeColor={iconColor}
                />
              </HeaderMobileIconStyled>
            )}
          </HeaderMobileBoxStyled>
        </HeaderMobileWrapperStyled>
        <HeaderLoader withCompany={withCompany} />
      </HeaderMobileContainerStyled>
    </>
  );
};
