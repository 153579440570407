import { CourseModel, Nullable, pushDataLayer } from '@lerna-core';
import { getCourseEventData } from '@features/course';

export enum OrderFormEvents {
  formEnrollClick = 'click_button_enroll course',
  formEnrollOpen = 'form_enroll course_open',
  formEnrollClose = 'form_enroll course_close',
  formEnrollSelectSelfPay = 'form_enroll course_select_personal_payment',
  formEnrollSelectCompanyPay = 'form_enroll course_select_company_payment',
  formEnrollPromoCode = 'form_enroll course_add_promocode',
  formEnrollPromoCodeError = 'form_enroll course_add_promocode_error',
  formEnrollStatus = 'form_enroll course_submit_{{type}}',
  formEnrollStatusValue = 'form_enroll course_submit_{{type}}_value: {{value}}',
  formEnrollSelectDefault = 'form_enroll course_select_full_access',
  formEnrollSelectSubscription = 'form_enroll course_select_corp_access',
  formEnrollSelectConsultation = 'form_enroll course_select_request_for_consultation',
  formEnrollSelectOnlinePay = 'form_enroll course_select_request_for_payment',
  formEnrollGoToOnlinePay = 'form_enroll course_go_to_payment',
  clickButtonMyCourses = 'click_button_my_courses',
  formEnrollLegalName = 'form_enroll course_add_legal_name',
  formEnrollMoreDetails = 'form_enroll course_more_details',
  formEnrollCourseClickButtonNext = 'form_enroll course_click_button_next',
  formEnrollCourseAddUnrequiredMotivationLetter = 'form_enroll course_add_unrequired_motivation_letter',
  formEnrollCourseAddRequiredMotivationLetter = 'form_enroll course_add_required_motivation_letter',
  formEnrollCourseSkipUnrequiredMotivationLetter = 'form_enroll course_skip_unrequired_motivation_letter',
  formEnrollCourseCloseMotivationLetter = 'form_enroll course_close_motivation_letter',
  formEnrollCommentError = 'form_enroll course_motivation_letter_error',
  formEnrollCourseClickAddOnCompensation = 'form_enroll course_click_add-on_compensation',
  formEnrollCourseOpenPromptAboutMotivation = 'form_enroll course_open_prompt_about_motivation',
}

export enum OrderFormRequestTypes {
  b2b = 'b2b',
  b2c = 'b2c',
}

export const orderFormPushDataLayer = (
  eventName: string,
  eventType: string,
  eventPosition: string,
  course: Nullable<CourseModel>,
  courseIndex?: Nullable<number>,
  orderId?: string,
  requestType?: string
): void => {
  if (course) {
    pushDataLayer(
      getCourseEventData(
        eventName,
        eventType,
        eventPosition,
        course,
        courseIndex,
        orderId,
        requestType
      )
    );
  }
};
