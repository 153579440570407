import { publicConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { departmentsAdapter } from './departments.adapter';
import { Department, DepartmentsApiModel } from './departments.model';

export class DepartmentsClient extends SecureRequestClient<
  null,
  DepartmentsApiModel,
  Department[]
> {
  protected path = 'v1/departments';
  protected requestParameters: null;
  protected adapter = departmentsAdapter;

  constructor(host = publicConfig.API_GATEWAY_URL) {
    super(host);
    this.requestParameters = null;
  }
}

export class DepartmentsByCompanyClient extends DepartmentsClient {
  constructor(companyId: string, host?: string) {
    super(host);
    this.path = `user-api/v1/companies/${companyId}/departments`;
  }
}
