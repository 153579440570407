import React, { useContext } from 'react';
import { CoursesPageWithErrorModel } from '@features/courses';
import { logger, Nullable } from '@lerna-core';

export const CoursesPageContext =
  React.createContext<Nullable<CoursesPageWithErrorModel>>(null);

export const useCoursesPageContext = (): CoursesPageWithErrorModel => {
  const value = useContext(CoursesPageContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as CoursesPageWithErrorModel;
  }

  return value;
};
