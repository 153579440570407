export enum RightSubscriptionBadgeStatuses {
  warn = 'warn',
  success = 'success',
}

export type RightSubscriptionBadgeModel = {
  status: RightSubscriptionBadgeStatuses;
  label: string;
  link?: string;
  linkLabel?: string;
};
