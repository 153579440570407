import styled, { css } from 'styled-components';
import { breakpoint, LoaderStyledContainer } from '@lerna-core';

export const FormContentStyled = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  ${LoaderStyledContainer} {
    z-index: ${(props): string => props.theme.ZINDEX.TOP_MODULE};
  }

  ${breakpoint(
    'largeDown',
    css`
      flex-direction: column;
      min-height: unset;
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      padding: 28px 20px;
    `
  )}
`;
