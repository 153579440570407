import React, { ReactElement } from 'react';
import { RightHeadStyled, TitleStyled } from './rightHead.styled';
import { useCompensationFormContext } from '../../../compensationForm.context';
import { RightPaymentTabsContainer } from '../rightPaymentTabs';

export const RightHeadContainer = (): ReactElement => {
  const {
    translations,
    isFreeCourse,
    isCourseWithCompensationContent,
    formOrderInfo,
  } = useCompensationFormContext();

  const showPaymentTabs =
    formOrderInfo.hasOrder && !isCourseWithCompensationContent && !isFreeCourse;

  return (
    <RightHeadStyled>
      {showPaymentTabs && <RightPaymentTabsContainer />}
      {!showPaymentTabs && (
        <TitleStyled>{translations?.course_price}</TitleStyled>
      )}
    </RightHeadStyled>
  );
};
