import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const CourseBackContainerStyled = styled.span`
  ${(props): string => fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}
  font-weight: 400;
  display: inline-flex;

  ${breakpoint(
    'smallDown',
    css`
      margin-left: 20px;
    `
  )}

  a {
    display: inline-flex;
    align-items: center;
    color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
    text-decoration: none;
  }

  svg {
    margin-right: 12px;
    transform: rotate(180deg);
  }
`;
