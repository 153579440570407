import { AxiosError } from 'axios';
import { CreateCustomer, CreateCustomerParams } from '../createCustomer';

export enum CreateUserStatusCodes {
  USER_CREATED = 201,
  VALIDATION_ERROR = 422,
}

export type CreateUserParams = {
  email: string;
  password: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
};

export type CreateB2BUserParams = CreateUserParams &
  Pick<CreateCustomerParams, 'company_uuid'>;

export type CreateB2BFullUserParams = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  department_id: string;
  position_id: string;
};

export type CreateUserModel = {
  avatar: null;
  email: string;
  first_name: string;
  is_disabled: boolean;
  is_fulfilled: boolean;
  last_name: string;
  patronymic: null;
  phone: string;
  uuid: string;
};

export type CreateB2BUserModel = Omit<CreateUserModel, 'is_fulfilled'> &
  CreateCustomer & {
    is_verified: boolean;
    companyId: string;
    last_activity_at: null;
  };

export type CreateUserApiModel = {
  data: {
    data: CreateUserModel;
  };
};

export type CreateUserApiError = AxiosError;
export type CreateUserError = {
  errors: CreateUserApiValidationErrors;
  status: CreateUserStatusCodes.VALIDATION_ERROR;
};

export type CreateUserApiValidationErrors = Record<string, string[]>;
export type CreateUserValidationErrors = Record<string, string>;
