import React, { ReactElement } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
} from './contentRight.styled';
import { RightPricesContainer } from './rightPrices';
import { RightHeadContainer } from './rightHead';
import { useFormB2CContext } from '../../formB2C.context';
import {
  ButtonAdditionalColors,
  ButtonColor,
  ButtonContainer,
} from '@lerna-core';
import { FormPromoCode } from '@features/common/forms';

export const ContentRightContainer = (): ReactElement => {
  const {
    handleFormSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    translations,
    submitButtonTitle,
    discountProgramColors,
    discountProgramQuery,
    promoCodeQuery,
    isFreeCourse,
    setIsApplyingPromoCode,
  } = useFormB2CContext();

  const additionalButtonColors = discountProgramColors
    ? ({
        color: discountProgramColors.text,
        backgroundColor: discountProgramColors.background,
      } as ButtonAdditionalColors)
    : undefined;

  return (
    <ContentRightStyled>
      <RightHeadContainer />
      <RightPricesContainer />
      {!isFreeCourse && (
        <FormPromoCode
          values={values}
          errors={errors}
          touched={touched}
          disabled={
            isSubmitting || (!!discountProgramQuery && !!promoCodeQuery)
          }
          onBlur={handleBlur}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          translations={translations}
          setIsApplyingPromoCode={setIsApplyingPromoCode}
        />
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleFormSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={submitButtonTitle}
          disabled={isSubmitting}
          additionalColors={additionalButtonColors}
        />
      </ButtonStyled>
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
