import { TranslationModel } from '@lerna-core';

export const getStatusMessage = (
  isSuccessDisplayed: boolean,
  isErrorDisplayed: boolean,
  isExistOrder: boolean,
  translations: TranslationModel,
  isFreeCourse: boolean,
  withCustomCompensation: boolean
): Record<string, string> => {
  switch (true) {
    case isSuccessDisplayed: {
      const successStatusTitle = withCustomCompensation
        ? translations?.b2b_compensation_form_success_status_title
        : translations?.sign_up_default_success_title_description;
      const successStatusSubtitle = withCustomCompensation
        ? translations?.b2b_compensation_form_success_status_subittle
        : translations?.sign_up_default_success_subtitle_description;

      return {
        title: isFreeCourse
          ? translations?.sign_up_default_success_title_description
          : successStatusTitle,
        subtitle: isFreeCourse
          ? translations?.course_will_be_added_in_one_day
          : successStatusSubtitle,
      };
    }
    case isExistOrder:
      return {
        title: translations?.order_exist_message_title,
        subtitle: isFreeCourse
          ? translations?.course_will_be_added_in_one_day
          : translations?.order_exist_message_subtitle,
      };
    case isErrorDisplayed:
    default:
      return {
        title: translations?.popup_error_message_title,
        subtitle: translations?.popup_error_message_description,
      };
  }
};
