import { coursesSubscriptionAccessAdapter } from '../../adapters';
import { CurrencyModel, TranslationModel } from '../../models';
import { mapImageResource } from '../image';
import { VendorModel } from '../vendors';
import {
  getCourseVendor,
  hasDiscount,
  isFreeCourse,
  isFreeCourseByDiscount,
  isFreeCourseByVendor,
  isFreeCourseByVendorDiscount,
} from './course.helper';
import { ApiCourseModel, CourseModel } from './course.model';
import { courseDurationsAdapter } from './courseDurations';
import { courseFaqsAdapter } from './courseFaqs';
import { courseLabelAdapter } from './courseLabel';
import { courseOptionsAdapter } from './courseOptions';
import { coursePricesAdapter } from './coursePrices';
import { courseProgramSectionsAdapter } from './courseProgram';
import { coursePropsAdapter } from './courseProps';
import { courseSkillsAdapter } from './courseSkills';
import { courseTutorsAdapter } from './courseTutors';
import { courseUrlAdapter } from './courseUrl';
import { courseBenefitAdapter } from './courseBenefit';
import { mapCourseUniqueSellingPropositions } from './courseUniqueSellingPropositions';
import { courseOrderTypesAdapter } from './courseOrderTypes';

const DEFAULT_BG_COLOR_LOGO = '#D6E9FE';

export const courseAdapter = (
  apiCourse: ApiCourseModel,
  currencies: CurrencyModel[],
  translations: TranslationModel,
  vendors: VendorModel[]
): CourseModel => {
  const price = coursePricesAdapter(
    apiCourse.prices.data,
    currencies,
    translations,
    apiCourse?.bonuses?.data
  );

  const options = courseOptionsAdapter(apiCourse.options.data);

  return {
    uuid: apiCourse.uuid,
    price,
    title: apiCourse.title,
    type: apiCourse.type,
    vendor: getCourseVendor(apiCourse, vendors),
    startDate: apiCourse.start_date,
    startDateRaw: apiCourse.start_date_raw,
    aboutLink: apiCourse.link,
    props: coursePropsAdapter(
      options,
      apiCourse.start_date,
      translations,
      apiCourse.durations?.data
    ),
    slug: apiCourse.slug,
    isAllowToRedirect: !!apiCourse.is_allow_to_redirect,
    isFree: isFreeCourse(price),
    isFreeByDiscount: isFreeCourseByDiscount(price),
    isFreeByVendor: isFreeCourseByVendor(price),
    isFreeByVendorDiscount: isFreeCourseByVendorDiscount(price),
    isHit: apiCourse.is_hit,
    status: apiCourse.status,
    isActive: apiCourse.is_active,
    isHelpEmployment: !!apiCourse.is_help_employment,
    isCustomCompensationAvailable: !!apiCourse.is_custom_compensation_available,
    hasDiscount: hasDiscount(price),
    faqs: courseFaqsAdapter(apiCourse?.faqs?.data),
    skills: courseSkillsAdapter(apiCourse?.skills_sections?.data),
    options,
    externalId: apiCourse.external_id,
    numericId: apiCourse.numeric_id,
    programSections: courseProgramSectionsAdapter(
      apiCourse.program_sections?.data
    ),
    orderTypes: courseOrderTypesAdapter(apiCourse.order_types),
    logo: apiCourse.logo
      ? {
          size: apiCourse.logo.data.size,
          imageResource: mapImageResource(apiCourse.logo.data.image_resource),
          backgroundColor:
            apiCourse.logo.data.background_color || DEFAULT_BG_COLOR_LOGO,
        }
      : null,
    imageBackgroundColor:
      apiCourse?.image_background_color || DEFAULT_BG_COLOR_LOGO,
    url: apiCourse?.url ? courseUrlAdapter(apiCourse.url.data) : null,
    durations: apiCourse?.durations
      ? courseDurationsAdapter(apiCourse.durations.data)
      : null,
    tutors: apiCourse.tutors?.data
      ? courseTutorsAdapter(apiCourse.tutors.data)
      : [],
    subscriptionAccess: !!apiCourse?.subscription_access
      ? coursesSubscriptionAccessAdapter(apiCourse.subscription_access)
      : null,
    comment: apiCourse.comment ? apiCourse.comment : null,
    megaDiscount: apiCourse.mega_discount ? apiCourse.mega_discount : null,
    description: !!apiCourse?.description ? apiCourse?.description : null,
    logoLabel: apiCourse.logo_label
      ? courseLabelAdapter(apiCourse.logo_label)
      : null,
    vendorLabel: apiCourse.vendor_label
      ? courseLabelAdapter(apiCourse.vendor_label)
      : null,
    benefit: apiCourse.benefit ? courseBenefitAdapter(apiCourse.benefit) : null,
    lmsId: apiCourse.lms_id ? apiCourse.lms_id : null,
    uniqueSellingPropositions: apiCourse?.unique_selling_propositions?.data
      ?.length
      ? mapCourseUniqueSellingPropositions(
          apiCourse.unique_selling_propositions
        )
      : null,
  };
};
