import {
  CatalogPageTypes,
  CourseModel,
  dateService,
  getCoursePageHref,
  Nullable,
  setAcceptLanguage,
  stringService,
  UserAggregateModel,
  utmService,
} from '@lerna-core';
import {
  B2BOrderRequestParams,
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderRequestParams,
  B2COrderResponse,
  FreeOrderRequestParams,
} from '../form.model';
import { NextRouter } from 'next/router';
import {
  b2bOrderClientProvider,
  b2cOrderClientProvider,
  discountProgramOrderClientProvider,
  freeOrderClientProvider,
} from '../form.clientProvider';

export const sendUserOrderRequest = (
  user: UserAggregateModel,
  course: CourseModel,
  router: NextRouter,
  isB2B2C: boolean,
  isCustomCompensation: boolean,
  pageName: Nullable<string>,
  promoCode?: string,
  comment?: string,
  discountProgramQuery?: string,
  withPayment?: boolean
): Promise<
  Nullable<B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse>
> => {
  const userTimezone = dateService.UTCTimezone();
  const locale = router?.locale;
  const headers = setAcceptLanguage();
  const courseUrl = getCoursePageHref({
    course,
    router,
    urlParams: {},
  });
  const utmQuery = utmService.getQueryStringFromCookie();
  const utmObj = utmService.getUTMFromQuery(router.query, false);
  const isB2BUser = !!user.career?.companyId;

  const requestParams: B2COrderRequestParams &
    Pick<B2BOrderRequestParams, 'comment'> = {
    course_slug: course.slug,
    course_url: courseUrl,
    user_uuid: user.personal.uuid,
    user_name: user.personal.firstName,
    email: user.personal.email,
    phone: user.personal.phone,
    price: {
      amount: String(
        parseInt(stringService.replaceSpaces(course.price.price.amount))
      ),
      currency: course.price.price.currency,
    },
    start_date: course.startDateRaw || null,
    lang: locale,
    accessToken: user?.career?.companyId,
    is_data_agreement_accepted: true,
    is_request_from_catalog: pageName === CatalogPageTypes.courses,
  };
  if (promoCode) {
    requestParams.promo_code = promoCode;
  }
  if (utmQuery) {
    requestParams.utmQuery = utmQuery;
  }

  //send request for course with startPrice=0
  if (course.isFree && !isB2BUser && !discountProgramQuery) {
    const freeOrderRequestParams: FreeOrderRequestParams = {
      course_id: course.uuid,
      customer: {
        name: `${user.personal.firstName} ${user.personal.lastName}`,
        email: user.personal.email,
        phone: user.personal.phone,
      },
      referer_url: `${window?.location?.origin}${window?.location?.pathname}`,
      is_data_agreement_accepted: true,
      ...(utmObj && { utm: utmObj }),
    };

    return freeOrderClientProvider(freeOrderRequestParams, headers);
  }
  if (discountProgramQuery) {
    requestParams.discount_program = discountProgramQuery;

    return discountProgramOrderClientProvider(requestParams, headers);
  }
  if (isB2BUser) {
    const b2bRequestParams: B2BOrderRequestParams = {
      course_slug: course.slug,
      course_url: courseUrl,
      is_b2b2c_order: false,
      is_data_agreement_accepted: true,
      with_payment: !!withPayment,
    };
    if (promoCode) {
      b2bRequestParams.promo_code = promoCode;
    }
    if (comment) {
      b2bRequestParams.comment = comment;
    }
    if (utmQuery) {
      b2bRequestParams.utmQuery = utmQuery;
    }
    if (isB2B2C) {
      b2bRequestParams.is_b2b2c_order = true;
    }
    if (isCustomCompensation) {
      b2bRequestParams.is_custom_compensation = true;
    }

    return b2bOrderClientProvider(b2bRequestParams, headers);
  } else {
    if (comment) {
      requestParams.comment = comment;
    }
    if (userTimezone) {
      requestParams.user_utc_time_zone = userTimezone;
    }

    return b2cOrderClientProvider(requestParams, headers);
  }
};
