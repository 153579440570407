import React, { ReactElement } from 'react';

import { PopupStyleProps } from '../index';
import { PopupCloseContainer } from '../index';
import { PopupHeaderContainerStyled } from '../index';

type PopupHeaderContainerProps = {
  title?: string;
  styleProps: PopupStyleProps;
  isSticky: boolean;
  children?: ReactElement;
  hasClose: boolean;
  isMobile: boolean;
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  closeType?: string;
  darkMode: boolean;
};

export const PopupHeaderContainer = ({
  children,
  styleProps,
  isSticky,
  hasClose,
  title,
  onClose,
  isMobile,
  closeType,
  darkMode,
}: PopupHeaderContainerProps): ReactElement => {
  return (
    <PopupHeaderContainerStyled
      darkMode={darkMode}
      styleProps={styleProps}
      isSticky={isSticky}
    >
      <>
        {hasClose && (
          <PopupCloseContainer
            darkMode={darkMode}
            handleClose={onClose}
            isMobile={isMobile}
            closeType={closeType}
          />
        )}
        {!!title && title}
      </>
      {children}
    </PopupHeaderContainerStyled>
  );
};
