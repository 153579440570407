import React, { ReactElement } from 'react';
import {
  CounterLoaderDotsItemStyled,
  CounterLoaderStyled,
} from './counterLoader.styled';

export const CounterLoaderContainer = (): ReactElement => (
  <CounterLoaderStyled>
    <CounterLoaderDotsItemStyled key={'dots-item-1'} />
    <CounterLoaderDotsItemStyled key={'dots-item-2'} />
    <CounterLoaderDotsItemStyled key={'dots-item-3'} />
  </CounterLoaderStyled>
);
