import { CommonEventTypes, CourseModel } from '@lerna-core';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { GranuleFormSubmitStatusTypes } from './formGranule.model';

export const pushFormSubmitStatusEvent = (
  type: GranuleFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  orderId?: string,
  requestType?: string
): void => {
  const event = `${OrderFormEvents.formEnrollStatusValue
    .replace('{{type}}', type)
    .replace('{{value}}', 'granule')}`;

  orderFormPushDataLayer(
    event,
    CommonEventTypes.orderEvents,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
